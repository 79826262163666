import React from "react";
import { Filter, TextInput } from "react-admin";

export default (props) => (
  <Filter {...props}>
    <TextInput label="Prénom" source="firstName" />
    <TextInput label="Nom" source="lastName" />
    <TextInput label="Email" source="email" />
  </Filter>
);

