import React from "react";
import { Datagrid, DateField, List, TextField } from "react-admin";

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import BulkActionButtons from "../components/BulkActionButtons";
import DomainNameFilter from "./DomainNameFilter";
import { ErrorBoundary } from "../lib/error-report";

export default (props) => (
  <ErrorBoundary>
    <Box mt="1.5em">
      <Typography variant="body2">
        Les noms de domaines listés ci-dessous sont les noms domaines autorisés
        à demander une autorisation d’accès au tableau de bord Fit & Proper.
      </Typography>
      <List
        bulkActionButtons={<BulkActionButtons />}
        {...props}
        title="Noms de domaines"
        exporter={false}
        sort={{ field: "domain", order: "ASC" }}
        filters={<DomainNameFilter />}
      >
        <Datagrid rowClick="edit">
          <TextField label="Nom de domain" source="domain" />
          <DateField
            label="Mis à jour le"
            source="updatedAt"
            showTime
            locales="fr-FR"
          />
        </Datagrid>
      </List>
    </Box>
  </ErrorBoundary>
);
