import { BulkDeleteButton } from "react-admin";
import React from "react";

const BulkActionButtons = (props) => (
  <BulkDeleteButton
    undoable={false}
    {...props}
  />
);

export default BulkActionButtons;