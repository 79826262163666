/**
 * Langue Context to be used by candidatures
 */
import React, { useContext, useCallback } from "react";

import { Langue } from "../../lib/langue";

import En from "./EN";
import Fr from "./FR";

const LABELS = {
  [Langue.Fr]: Fr,
  [Langue.En]: En,
};

const Context = React.createContext({
  langue: Langue.Fr,
  t: (sentence) => sentence,
});

/**
 * TranslateFunction looks up a translation for the input string
 * according to the language currently set in the context.
 * @typedef {(input: string) => string} TranslateFunction
 */

/**
 * @typedef {Object} LangueContext
 * @property {string} langue     The current language the `t` function translates into
 * @property {TranslateFunction} t translates the string passed as input
 */

/**
 * The object returned by `useLangue()` as two properties :
 * - `langue`: the targuet language, and
 * - `t`: a function that looks up a translation for the input string
 * @returns {{ langue: string, t: TranslateFunction }}
 */
export function useLangue() {
  return useContext(Context);
}

export function LangueProvider({ langue = "FR", ...rest }) {
  langue = langue ?? "FR";
  const t = useCallback(
    (sentence) => {
      if (Object.hasOwnProperty.call(LABELS[langue], sentence)) {
        return LABELS[langue][sentence];
      }

      return sentence;
    },
    [langue]
  );

  return <Context.Provider value={{ langue, t }} {...rest} />;
}
