import CandidatureList from "./CandidatureList";
import CandidatureCreate from "./CandidatureCreate";
import CandidatureEdit from "./CandidatureEdit";
import CandidatureShow from "./print/Candidature";

export default {
  list: CandidatureList,
  create: CandidatureCreate,
  edit: CandidatureEdit,
  show: CandidatureShow,
};
