import React, { useCallback } from 'react';
import { useNotify } from "react-admin";
import { useHistory } from "react-router-dom";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Alert, AlertTitle } from "@material-ui/lab";
import orange from "@material-ui/core/colors/orange";
import { makeStyles } from "@material-ui/styles";

import { authedFetch } from "../authProvider";

import { reportError } from "../lib/error-report";

const { REACT_APP_API_URL } = process.env;

const useButtonStyles = makeStyles({
  label: {
    color: orange[700],
    fontWeight: "bold",
  },
  contained: {
    backgroundColor: "white",
    boxShadow: null,
  },
});

export default function StatusAlert({ record, block }) {
  const buttonClasses = useButtonStyles();
  const notify = useNotify();
  const history = useHistory();

  const setNextStatus = useCallback(
    async (nextStatus) => {
      if (!record.current?.id) return;
      if (
        window.confirm(
          "Le lien envoyé ne sera plus valide, le ou la candidate n’aura plus accès au formulaire. Vous devrez lui envoyer un nouveau lien."
        )
      ) {
        try {
          const result = await authedFetch(
            `${REACT_APP_API_URL}/candidatures/${record.current.id}/status`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ statut: nextStatus }),
            }
          );

          if (result.status === 200) {
            notify("Le statut de la candidature a été mis à jour");
            history.go(0);
          } else {
            throw new Error(
              `Candidature Status update Failed (${result.status})`
            );
          }
        } catch (err) {
          reportError(err);
          notify("La mise à jour du statut a échoué", "error");
        }
      }
    },
    [history, notify, record]
  );
  return (
    <>
   {block && (

          <Box mt="1.75em">
            <Alert severity="warning" variant="filled">
              <AlertTitle>
                <strong>Candidature soumise au candidat</strong>
              </AlertTitle>
              <Typography variant="body1">
                Cette candidature a été soumise au candidat ou à la candidate, et
                ne peut plus être modifiée tant qu’il ou elle n’aura pas complété
                le formulaire.
                <br />
                <br />
                Si toutefois des modifications sont impératives, vous pouvez
                passez le statut de la candidature à « Brouillon ».
                <br />
                Le ou la candidate n’aura plus accès au formulaire, et vous devrez
                lui envoyer un nouveau lien.
                <br />
                <Box mt="1em" mb="0.5em">
                  <Button
                    classes={buttonClasses}
                    disableElevation
                    variant="contained"
                    onClick={() => setNextStatus("Brouillon")}
                  >
                    Passer la candidature en Brouillon
                  </Button>
                </Box>
              </Typography>
            </Alert>
          </Box>
        )
      }
    </>  );
}
