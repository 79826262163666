import React from "react";
import { Create } from "react-admin";
import { ErrorBoundary } from "../lib/error-report";
import EntiteForm from "./form/EntiteForm";

export default (props) => (
  <ErrorBoundary>
    <Create {...props} title="Nouvelle Entité">
      <EntiteForm />
    </Create>
  </ErrorBoundary>
);
