import React from "react";
import { Box, Typography } from "@material-ui/core";
import { FormWithRedirect, Toolbar, SaveButton } from "react-admin";
import NumberInputCustom from "../../components/input/NumberInputCustom";

export default (props) => (
  <FormWithRedirect
    {...props}
    render={(formProps) => {
      return (
        <form>
          <Box p="1em" maxWidth="72em">
            <Box>
              <Typography variant="h6" gutterBottom>
                {formProps.record.nom}
              </Typography>
            </Box>
            <Box display="flex">
              <Box>
                <NumberInputCustom
                  label="Président de Conseil"
                  source="plancherPresidenConseil"
                />
              </Box>

              <Box ml="1em">
                <NumberInputCustom
                  label="Membre de Conseil"
                  helperText="Si membre indépendant"
                  source="plancherMembreConseilIndependant"
                />
              </Box>
            </Box>

            <Box display="flex">
              <Box>
                <NumberInputCustom
                  label="Membre du conseil"
                  helperText="Si membre exerçant une fonction de dirigeant exécutif ou non-exécutif"
                  source="plancherMembreConseilDirigeant"
                />
              </Box>
              <Box ml="1em">
                <NumberInputCustom
                  label="Membre de Conseil"
                  helperText="Sans être membre de comité"
                  source="plancherMembreConseilSansComite"
                />
              </Box>
            </Box>
          </Box>

          <Toolbar>
            <SaveButton
              record={formProps.record}
              basePath={formProps.basePath}
              undoable={false}
              invalid={formProps.invalid}
              handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
              saving={formProps.saving}
              pristine={formProps.pristine}
              submitOnEnter={false}
              resource="planchers"
              redirect="list"
            />
          </Toolbar>
        </form>
      );
    }}
  />
);
