import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import HelpIcon from "@material-ui/icons/Help";

const useInfoBulleStyles = makeStyles({
  root: {
    position: "relative",
    width: "1.7em",
    height: "1.7em",
    "&:hover": {
      zIndex: 12999,
    },
    "&:hover $content": {
      position: "absolute",
      display: "block",
    },
    "&:hover $contentRight": {
      position: "absolute",
      display: "block",
    },
    zIndex: 1000,
    marginTop: "-0.5em",
  },
  rootNonHoverable: {
    position: "relative",
    width: "100%",
    marginBottom: "2em",
    "& $content": {
      display: "block",
      paddingLeft: "2.3em",
    },
    "& $contentRight": {
      display: "block",
      paddingLeft: "2.3em",
      marginTop: "1em",
    },
    "& $iconRight": {
      top: "1.3em",
      left: "0.3em",
    },
  },
  icon: {
    // zIndex: 1,
    position: "absolute",
    top: "0.5em",
    left: "0.5em",
    cursor: "pointer",
  },
  iconRight: {
    // zIndex: 1,
    position: "absolute",
    top: "0.5em",
    right: "0,5em",
    cursor: "pointer",
  },
  content: {
    fontSize: "0.9em",
    zIndex: 0,
    // top: "-1.7em",
    minWidth: "36em",
    minHeight: "1.7em",
    display: "none",
    padding: "0.5em",
    paddingLeft: "2.8em",
    paddingRight: "1em",
    backgroundColor: "white",
    borderRadius: "4px",
    boxShadow: "2px 3px 12px rgba(0, 0, 0, 0.1)",
  },
  contentRight: {
    right: 0,
    fontSize: "0.9em",
    zIndex: 0,
    // top: "-1.7em",
    minWidth: "36em",
    minHeight: "1.7em",
    display: "none",
    padding: "0.5em",
    paddingLeft: "1em",
    paddingRight: "2.8em",
    backgroundColor: "white",
    borderRadius: "4px",
    boxShadow: "2px 3px 12px rgba(0, 0, 0, 0.1)",
  },
});

export default function InfoBulle({
  children,
  dangerouslySetInnerHTML,
  nonHoverable,
  position,
  width,
  mt,
  ml,
  mr,
}) {
  if (!children && !dangerouslySetInnerHTML)
    throw new Error("Une infobulle doit avoir un contenu");
  const classes = useInfoBulleStyles();

  const iconClass = position === "right" ? classes.iconRight : classes.icon;
  const contentClass =
    position === "right" ? classes.contentRight : classes.content;

  return (
    <div
      className={nonHoverable ? classes.rootNonHoverable : classes.root}
      style={{ marginTop: mt || "-0.5em", marginLeft: ml, marginRight: mr }}
    >
      {dangerouslySetInnerHTML && (
        <div
          className={contentClass}
          dangerouslySetInnerHTML={dangerouslySetInnerHTML}
        />
      )}
      {children && (
        <div className={contentClass} style={{ minWidth: width }}>
          {children}
        </div>
      )}
      <div className={iconClass}>
        <HelpIcon />
      </div>
    </div>
  );
}
