import { useEffect, useRef } from "react";

export function useLogChange(name, prop) {
  useEffect(() => {
    console.log("change:", name, prop);
  }, [name, prop]);
}

export function useLogRender(name, props = {}) {
  const count = useRef(0);

  count.current = count.current + 1;
  console.debug("Render", name, ":", count.current, props);
}

export function useWhyDidYouUpdate(name, props) {
  // Get a mutable ref object where we can store props ...
  // ... for comparison next time this hook runs.
  const previousProps = useRef();

  useEffect(() => {
    if (previousProps.current) {
      // Get all keys from previous and current props
      const allKeys = Object.keys({ ...previousProps.current, ...props });
      // Use this object to keep track of changed props
      const changesObj = {};
      // Iterate through keys
      allKeys.forEach((key) => {
        // If previous is different from current
        if (previousProps.current[key] !== props[key]) {
          // Add to changesObj
          changesObj[key] = {
            from: previousProps.current[key],
            to: props[key],
          };
        }
      });

      // If changesObj not empty then output to console
      if (Object.keys(changesObj).length) {
        console.log("[why-did-you-update]", name, changesObj);
      }
    }

    // Finally update previousProps with current props for next hook call
    previousProps.current = props;
  });
}

/**
 * @template T
 * @class Enum<T>
 * @extends T
 */
class EnumClass {
  static includes(...args) {
    return Object.values(this).includes(...args);
  }

  static reduce(...args) {
    return Object.values(this).reduce(...args);
  }

  static map(...args) {
    return Object.values(this).map(...args);
  }

  static forEach(...args) {
    return Object.values(this).forEach(...args);
  }

  static filter(...args) {
    return Object.values(this).map(...args);
  }

  static next(val) {
    const values = Object.values(this);
    let index = values.indexOf(val) + 1;

    if (index === values.length) {
      index = 0;
    }

    return values[index];
  }

  static *[Symbol.iterator]() {
    yield* Object.values(this);
  }
}

/**
 * @template T
 * @param {T} obj
 * @returns {Enum<T>}
 */
export function Enum(obj) {
  /** @type {T | Enum<T>} */
  const enumCls = class extends EnumClass {};

  Object.entries(obj).forEach(([key, value]) => {
    Object.defineProperty(enumCls, key, {
      value,
      writable: false,
      enumerable: true,
      configurable: false,
    });
  });

  return enumCls;
}
