import React, { useEffect, useRef, useState } from "react";
import { Alert, AlertTitle } from "@material-ui/lab";
import Box from "@material-ui/core/Box";

import { getExpireTime } from "../authProvider";

const MS = 1;
const S = 1000 * MS;
const MN = 60 * S;

export function DisconnectionAlert() {
  const [disconnectIn, setDisconnectIn] = useState(Infinity);
  const [showAlert, setShowAlert] = useState(false);
  const [t, setT] = useState(new Date());

  const timeout = useRef();

  useEffect(() => {
    if (timeout.current) clearTimeout(timeout.current);

    timeout.current = setTimeout(() => {
      const now = new Date();
      let exp = getExpireTime();

      if (exp) {
        const diff = +exp - +now;
        setDisconnectIn(diff);
      }

      setT(new Date());
    }, 1 * S);

    return () => clearTimeout(timeout.current);
  }, [t]);

  useEffect(() => {
    setShowAlert(disconnectIn < 5 * MN);
  }, [disconnectIn]);

  if (showAlert) {
    return (
      <Box mt="1.75em">
        <Alert variant="filled" severity="error">
          <AlertTitle>
            <strong>Déconnexion imminente</strong>
          </AlertTitle>
          Votre session expire dans moins de {Math.floor(disconnectIn / MN)}{" "}
          minutes. Enregistrez votre saisie et reconnectez-vous.
        </Alert>
      </Box>
    );
  }

  return null;
}
