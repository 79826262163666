import React, { useMemo } from "react";

import { Link, useLocation } from "react-router-dom";
import MuiTab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

import classnames from "classnames";

import { TabContext } from "./TabbedFormCustom";
import { PortalTargetProvider } from "../../candidatures/PortalTarget";
import { useLangue } from "../../candidatures/transalations";

const sanitizeRestProps = ({
  /* eslint-disable-next-line */
  contentClassName,
  /* eslint-disable-next-line */
  label,
  /* eslint-disable-next-line */
  icon,
  /* eslint-disable-next-line */
  value,
  /* eslint-disable-next-line */
  translate,
  ...rest
}) => rest;

const hiddenStyle = { display: "none" };

const useStyles = makeStyles({
  loader: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(255, 255, 255, 0.9)",
  },
});

const FormTabCustom = ({
  basePath,
  className,
  contentClassName,
  children,
  hidden,
  icon,
  intent,
  label,
  margin,
  record,
  resource,
  variant,
  value,
  isPrint,
  ...rest
}) => {
  const location = useLocation();

  const classes = useStyles();
  const { t } = useLangue();

  const content = useMemo(() => {
    if (intent === "header") {
      return (
        <MuiTab
          key={label}
          label={label}
          value={value}
          icon={icon}
          className={classnames("form-tab", className)}
          component={Link}
          to={{ ...location, pathname: value }}
          {...sanitizeRestProps(rest)}
        />
      );
    } else {
      return (
        <Content
          key={`${label}-content`}
          classes={classes}
          hidden={hidden}
          hiddenStyle={isPrint ? {} : hiddenStyle}
          contentClassName={contentClassName}
          contentKey={`${label}-content-view`}
        >
          {isPrint && (
            <Box mt="3em">
              <Typography variant="h4" gutterBottom>
                {t(label)}
              </Typography>
            </Box>
          )}
          {children}
        </Content>
      );
    }
  }, [
    children,
    className,
    classes,
    contentClassName,
    hidden,
    icon,
    intent,
    isPrint,
    label,
    location,
    rest,
    t,
    value,
  ]);

  return content;
};

function Content({
  classes,
  hidden,
  hiddenStyle,
  contentClassName,
  contentKey,
  children,
}) {
  const ctx = React.useContext(TabContext);
  const s = hidden ? hiddenStyle : null;

  return (
    <PortalTargetProvider>
      <div style={s} className={contentClassName} key={contentKey}>
        {children}
        <div
          className={classes.loader}
          style={{ display: ctx.loading ? "block" : "none" }}
        />
      </div>
    </PortalTargetProvider>
  );
}

export default FormTabCustom;
