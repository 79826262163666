import React from "react";

import { TopToolbar, ListButton } from "react-admin";

import ChevronLeft from "@material-ui/icons/ChevronLeft";

function listWrap(candidate) {
  if (!candidate) return [];
  if (Array.isArray(candidate)) return candidate;

  return [candidate];
}

const PostCreateActions = ({ basePath, data, children }) => {
  const justifyContent =
    listWrap(children).length === 0 ? "flex-start" : "space-between";

  return (
    <TopToolbar style={{ justifyContent }}>
      <ListButton basePath={basePath} label="Retour" icon={<ChevronLeft />} />
      {children}
    </TopToolbar>
  );
};

export default PostCreateActions;
