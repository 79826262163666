import React from "react";
import { Box, Typography } from "@material-ui/core";
import { FormWithRedirect, FormDataConsumer } from "react-admin";

import PonderationForm from "./PonderationForm";

export default (props) => (
  <FormWithRedirect
    {...props}
    render={(formProps) => {
      return (
        <form>
          <Box p="1em">
            <FormDataConsumer subscription={{ values: true }}>
              {({ formData: { nom } }) => (
                <Typography variant="h6">{nom}</Typography>
              )}
            </FormDataConsumer>
          </Box>

          <PonderationForm {...formProps} />
        </form>
      );
    }}
  />
);
