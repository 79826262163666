import React from "react";
import { Create } from "react-admin";
import { ErrorBoundary } from "../lib/error-report";
import DomainNameForm from "./form/DomainNameForm";

export default (props) => (
  <ErrorBoundary>
    <Create {...props} title="Nouveau nom de domaine">
      <DomainNameForm />
    </Create>
  </ErrorBoundary>
);
