import React from "react";

import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";

export default function MentionsLegales() {
  return (
    <Box justifyContent="center" py="1.5em">
      <Paper>
        <Box maxWidth="64em" p="2em" py="3em">
          <Typography variant="h4">Mentions Légales</Typography>

          <Typography variant="body1">
            Ce site est édité par BPCE ci-après « l’Éditeur ».
          </Typography>
          <Box mb="2em" />

          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Éditeur&nbsp;:
                  </TableCell>
                  <TableCell>BPCE</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Forme Sociale&nbsp;:</TableCell>
                  <TableCell>
                    Société anonyme à directoire et conseil de surveillance au
                    capital de 173&nbsp;613&nbsp;700&nbsp;€
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Siège Social&nbsp;:</TableCell>
                  <TableCell>
                    50&nbsp;avenue Pierre Mendès France – 75201&nbsp;Paris
                    Cedex&nbsp;13
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Nº SIREN&nbsp;:</TableCell>
                  <TableCell>493 455 042 RCS Paris</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    N° ind d’ident. Intracommunautaire&nbsp;:
                  </TableCell>
                  <TableCell>FR 26 493 455 042</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Contact&nbsp;:</TableCell>
                  <TableCell>FR 26 493 455 042</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Directeur de publication&nbsp;:</TableCell>
                  <TableCell>
                    M. Laurent Mignon, Président du Directoire
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Hébergeur&nbsp;:</TableCell>
                  <TableCell>
                    Dénomination&nbsp;: Audience Pro
                    <br />
                    <br />
                    Adresse&nbsp;: 30 rue Étienne Dolet, 94230&nbsp;Cachan
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <Box mb="2em" />

          <Typography variant="h5">
            Conditions d'accès et d'utilisation
          </Typography>

          <Box mb="0.75em" />

          <Typography variant="body1">
            L'utilisateur du site reconnaît avoir pris connaissance des
            présentes informations légales et s'engage à les respecter.
          </Typography>
          <Box mb="0.5em" />

          <Typography variant="body1">
            Pour des raisons de maintenance mais également en cas de force
            majeure, difficultés informatiques, difficultés liées aux réseaux de
            télécommunications ou autres difficultés techniques, l’Éditeur
            pourra interrompre l'accès au site et ne saurait être tenu
            responsable de l'impossibilité d'accéder au présent site.
          </Typography>
          <Box mb="0.5em" />
          <Typography variant="body1">
            L'accès à tout ou partie des produits et services décrits sur ce
            site peut faire l'objet de restrictions à l'égard de certaines
            personnes ou de certains pays. Aucun des produits ou services
            présentés ici ne sera fourni à une personne si la loi de son pays
            d'origine, ou de tout autre pays qui la concernerait, l'interdit.
          </Typography>

          <Box mb="2em" />

          <Typography variant="h5">
            Informations mises à disposition sur le site ou sur les sites tiers
            référencés
          </Typography>
          <Box mb="0.75em" />

          <Typography variant="body1">
            L’Éditeur s'efforce d'assurer l'exactitude et la mise à jour des
            informations à caractère général, notamment financières, diffusées
            sur ce site, dont il se réserve le droit de modifier et corriger le
            contenu à tout moment et sans préavis. En conséquence, l’Éditeur ne
            garantit en aucune façon la complétude, la précision, l’exactitude,
            l’exhaustivité ou l’adéquation, des informations mises à disposition
            sur ce site, y compris l’ensemble des liens hypertextes ou toute
            autre liaison informatique utilisée, directement ou indirectement, à
            partir de ce site.
          </Typography>
          <Box mb="0.5em" />

          <Typography variant="body1">
            L’Éditeur décline en conséquence toute responsabilité&nbsp;:
          </Typography>

          <Typography variant="body1" component="div">
            <ul>
              <li>
                en cas d'imprécision, inexactitude, erreur ou omission portant
                sur des informations disponibles sur le site ou sur les sites de
                tiers référencés à partir des liens hypertextes&#x202F;;
              </li>
              <li>d'une absence de disponibilité des informations&#x202F;;</li>
              <li>
                pour tous dommages, directs et/ou indirects, quelles qu'en
                soient les causes, origines, nature ou conséquences, provoqués à
                raison de l'accès de quiconque au site ou de l'impossibilité d'y
                accéder&#x202F;;
              </li>
              <li>
                de l'utilisation du site et/ou du crédit accordé à une
                quelconque information provenant directement ou indirectement du
                site&#x202F;;
              </li>
              <li>
                de décisions prises sur la base d'une information contenue sur
                le site ou sur le site de tiers référencés à partir des liens
                hypertextes, et de l'utilisation qui pourrait en être faite par
                des tiers.
              </li>
            </ul>
          </Typography>

          <Typography variant="body1">
            Il est strictement interdit de mettre en place un lien hypertexte en
            direction du site sans l'autorisation écrite et préalable de
            l’Éditeur. Toute demande tendant à cette fin doit être adressée à{" "}
            <a href="fitnproper@bpce.fr">fitnproper@bpce.fr</a>.
          </Typography>

          <Box mb="2em" />
          <Typography variant="h6">Données à caractère personnel</Typography>
          <Box mb="0.75em" />

          <Typography variant="body1">
            Les données à caractère personnel que vous nous communiquez par le
            biais des formulaires disponibles sur ce site (par exemple une
            demande de contact ou de renseignement, une simulation, un
            formulaire de souscription…) sont traitées par l’Éditeur,
            responsable de traitement, à des fins de gestion interne et pour
            répondre à votre demande.
          </Typography>
          <Box mb="0.5em" />

          <Typography variant="body1">
            Les informations vous expliquant de quelle manière vos données sont
            obtenues, pourquoi elles sont traitées, avec qui elles sont
            susceptibles d’être partagées, les mesures mises en œuvre pour
            assurer leur confidentialité et leur sécurité figurent dans la
            Notice d’information sur les traitements des données à caractère
            personnel de l’Éditeur. Elle rappelle également les droits dont vous
            disposez et comment les exercer.
          </Typography>
          <Box mb="0.5em" />

          <Typography variant="body1">
            Pour en savoir plus sur notre notice d’information sur le traitement
            des données personnelles&nbsp;:
            <br />
            <a href="/notice-rgpd-fit-and-proper.pdf">
              Notice RGPD Fit & Proper
            </a>
          </Typography>

          <Box mb="2em" />
          <Typography variant="h6">Cookies – traceurs</Typography>
          <Box mb="0.75em" />

          <Typography variant="body1">
            Un cookie est un mini fichier contenant quelques lignes de
            caractères alphanumériques placé sur votre disque dur par le serveur
            du site ou de l’application mobile que vous visitez, ou par un
            serveur tiers (par exemple un service de web analytique),
          </Typography>

          <Typography variant="body1">
            Il contient plusieurs données&nbsp;:
          </Typography>

          <Typography variant="body1" component="div">
            <ul>
              <li>le nom du serveur qui l’a déposé,</li>
              <li>un identifiant sous forme de numéro unique,</li>
              <li>éventuellement une date d’expiration.</li>
            </ul>
          </Typography>

          <Typography variant="body1">
            Les cookies servent à faciliter la navigation et à rationaliser les
            procédures d’enregistrement ou de mesure d’audience.
          </Typography>
          <Box mb="0.5em" />

          <Typography variant="body1">
            Le site Fit & Proper n’utilise ni cookies, ni traceurs.
          </Typography>

          <Box mb="2em" />

          <Typography variant="h6">Propriété intellectuelle</Typography>
          <Box mb="0.75em" />

          <Typography variant="body1">
            L'ensemble des contenus (textes, sons, marques, logos,
            photographies, etc.), figurant sur les pages de ce site sont la
            propriété exclusive de l’Éditeur. Toute reproduction,
            représentation, diffusion ou extraction non autorisée, en tout ou
            partie, du contenu du site constituerait une contrefaçon des droits
            de propriété intellectuelle de l’Éditeur susceptible d'engager la
            responsabilité civile et pénale du contrefacteur.
          </Typography>

          <Box mb="2em" />
          <Typography variant="h6">Droit applicable</Typography>
          <Box mb="0.75em" />

          <Typography variant="body1">
            Ce site est soumis à la loi française.
          </Typography>
          <Box mb="2em" />

          <Typography variant="h6">Crédits</Typography>
          <Box mb="0.75em" />

          <Typography variant="body1">Sans objet</Typography>
        </Box>
      </Paper>
    </Box>
  );
}
