import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { useDispatch } from "react-redux";
import {
  Authenticated,
  useNotify,
  Link,
  fetchStart,
  fetchEnd,
} from "react-admin";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import CheckIcon from "@material-ui/icons/Check";
import CrossIcon from "@material-ui/icons/Clear";

import useAsync from "react-use/esm/useAsync";
import { authedFetch } from "./authProvider";
import { useAsyncFn } from "react-use";

const { REACT_APP_API_URL } = process.env;

function useGlobalLoader() {
  const dispatch = useDispatch();

  return useCallback(
    (load) => {
      if (load) {
        dispatch(fetchStart());
      } else {
        dispatch(fetchEnd());
      }
    },
    [dispatch]
  );
}

function AcceptDemandeForm({ demande, entites, action, onClick }) {
  const { askingUser, askedCandidat, askedEntite } = demande;
  const location = useLocation();
  const { pathname } = location;
  const uriParams = new URLSearchParams({
    backto: pathname,
  }).toString();

  const word = action === "accept" ? "autorisez" : "refusez";
  const buttonLabel = action === "accept" ? "Autoriser" : "Refuser";
  const buttonIcon = action === "accept" ? <CheckIcon /> : <CrossIcon />;

  return (
    <Box width="100%">
      <Typography variant="body1">
        L’utilisateur{" "}
        <Link to={`/users/${askingUser.id}?${uriParams}`}>
          {askingUser.lastName} {askingUser.firstName}
        </Link>
        , pour le compte des entités&nbsp;:
      </Typography>
      <ul>
        {entites.map(({ id, denominationSociale }) => (
          <li key={`${id}-entite`}>
            <Link to={`/entites/${id}?${uriParams}`}>
              {denominationSociale}
            </Link>
          </li>
        ))}
      </ul>

      <Typography variant="body1">
        souhaite obtenir l’accès au dossier candidat de{" "}
        <Link to={`/candidats/${askedCandidat.id}?${uriParams}`}>
          {askedCandidat.nom} {askedCandidat.prenom},
        </Link>
      </Typography>
      <Typography variant="body1">
        initialement créé par l’entité{" "}
        <Link to={`/entites/${askedEntite.id}?${uriParams}`}>
          {askedEntite.denominationSociale}
        </Link>
        .
      </Typography>

      <Box mb="1em" />

      <Typography variant="body1">
        En cliquant sur le bouton ci-dessous vous {word} l’accès à ces
        informations aux utilisateurs des entités&nbsp;:
      </Typography>
      <ul>
        {entites.map(({ id, denominationSociale }) => (
          <li key={`${id}-entite-2`}>
            <Link to={`/entites/${id}`}>{denominationSociale}</Link>
          </li>
        ))}
      </ul>
      <Box mb="1em" />
      <Typography variant="body1">
        Cette opération ne peut être annulée.
      </Typography>

      <Box display="flex" mt="2em" width="100%">
        <Button
          variant="contained"
          component={Link}
          startIcon={buttonIcon}
          onClick={onClick}
        >
          {buttonLabel} l’accès au dossier
        </Button>
      </Box>
    </Box>
  );
}

function useDemandeAccess(token) {
  const [demande, setDemande] = useState(null);
  const [entites, setEntites] = useState([]);
  const notify = useNotify();
  const setLoading = useGlobalLoader();
  const [unauthorized, setUnauthorized] = useState(false);

  const { loading, error } = useAsync(async () => {
    const response = await authedFetch(
      `${REACT_APP_API_URL}/candidats/demande-access/${token}`
    );

    if (response.status === 200) {
      const body = await response.text();

      const { demande, entites } = JSON.parse(body);

      setDemande(demande);
      setEntites(entites);
    } else if (response.status === 401) {
      setUnauthorized(true);
    } else {
      const body = await response.json();
      throw new Error(body.message);
    }
  }, [token]);

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  useEffect(() => {
    if (error) {
      notify(error.message, "error");
    }
  }, [error, notify]);

  return { loading, error, unauthorized, demande, entites };
}

function useUpdateDemande(demande, action) {
  const notify = useNotify();
  const setLoading = useGlobalLoader();
  const [done, setDone] = useState(false);

  const [state, fetch] = useAsyncFn(async () => {
    const status = action === "accept" ? "accepted" : "refused";

    const response = await authedFetch(
      `${REACT_APP_API_URL}/candidats/demande-access/${demande.id}`,
      {
        method: "PATCH",
        body: JSON.stringify({ status }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const body = await response.text();
    const { message } = JSON.parse(body);
    let severity = "info";

    if (response.status !== 202) {
      severity = "error";
    } else {
      setDone(true);
    }

    notify(message, severity);
  }, [demande, action]);

  const { loading, error } = state;

  useEffect(() => {
    setLoading(loading);
  }, [setLoading, loading]);

  useEffect(() => {
    if (error) {
      notify(error.message, "error");
    }
  }, [error, notify]);

  return [state, fetch, done];
}

function AcceptFinish({ demande }) {
  const { askingUser, askedCandidat } = demande;

  return (
    <Typography variant="body1">
      Vous avez accepté la demande de {askingUser.lastName}{" "}
      {askingUser.firstName}
      <br />
      concernant l’accès au dossier candidat de {askedCandidat.nom}{" "}
      {askedCandidat.prenom}.
      <br />
      <br />
      Une notification lui a été envoyée.
    </Typography>
  );
}

function RefuseFinish({ demande }) {
  const { askingUser, askedCandidat } = demande;

  return (
    <Typography variant="body1">
      Vous avez refusé la demande de {askingUser.lastName}{" "}
      {askingUser.firstName}
      <br />
      concernant l’accès au dossier candidat de {askedCandidat.nom}{" "}
      {askedCandidat.prenom}.
      <br />
      <br />
      Une notification lui a été envoyée.
    </Typography>
  );
}

function Finish({ demande, action }) {
  if (action === "accept") {
    return <AcceptFinish demande={demande} />;
  } else {
    return <RefuseFinish demande={demande} />;
  }
}

export default function DemandeAccesCandidat(props) {
  const { location, match } = props;
  const { token, action } = match.params;

  const { loading, error, demande, entites, unauthorized } = useDemandeAccess(
    token
  );
  const [updateState, updateDemande, updateDone] = useUpdateDemande(
    demande,
    action
  );

  return (
    <Authenticated location={location}>
      <Box>
        <Paper>
          {unauthorized ? (
            <Box maxWidth="64em" p="2em" pt="3em">
              <Typography variant="h4" gutterBottom>
                Accès refusé
              </Typography>

              <Typography variant="body1">
                Vous n’avez pas les droits suffisants pour accéder à cette
                ressource.
                <br />
                Veuillez contacter un administrateur.
              </Typography>
            </Box>
          ) : (
            <Box maxWidth="64em" p="2em" pt="3em">
              <Typography variant="h4" gutterBottom>
                Demande d’accès aux informations d’un candidat
              </Typography>

              {updateDone ? (
                <Finish demande={demande} action={action} />
              ) : (
                <>
                  {!loading && demande ? (
                    <AcceptDemandeForm
                      demande={demande}
                      entites={entites}
                      action={action}
                      onClick={updateDemande}
                      loading={updateState.loading}
                    />
                  ) : !error ? (
                    <span>Chargement…</span>
                  ) : (
                    <span>Erreur&nbsp;: {error.message}</span>
                  )}
                </>
              )}
            </Box>
          )}{" "}
        </Paper>
      </Box>
    </Authenticated>
  );
}
