import React from "react";
import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  TextField,
  FunctionField,
} from "react-admin";

import format from "date-fns/format";
import fr from "date-fns/locale/fr";

import BulkActionButtons from "./BulkActionButtons";
import CandidatureFilter from "./CandidatureFilter";
import CandidatureListToolbar from "./CandidatureListToolbar";
import DuplicationMenuButton from "./DuplicationMenuButton";

import CandidatureStatusField from "../components/custom/CandidatureStatusField";
import { ErrorBoundary } from "../lib/error-report";

import { FONCTIONS } from "./fonctionNames";
import { Langue } from "../lib/langue";

const CandidatFullNameField = ({ record = {} }) => {
  return (
    <span>
      {record.nom} {record.prenom}
    </span>
  );
};

export default (props) => (
  <ErrorBoundary>
    <List
      bulkActionButtons={<BulkActionButtons />}
      {...props}
      exporter={false}
      actions={<CandidatureListToolbar />}
      filters={<CandidatureFilter />}
    >
      <Datagrid rowClick="edit">
        <ReferenceField
          label="Candidat"
          source="candidatId"
          reference="candidats"
          link={false}
        >
          <CandidatFullNameField source="email" />
        </ReferenceField>
        <ReferenceField
          label="Entité"
          source="entiteId"
          reference="entites"
          link={false}
        >
          <TextField source="denominationSociale" />
        </ReferenceField>
        <FunctionField
          label="Fonction"
          render={(record) => {
            const mandatFonctions = record.mandatFonctions || [];
            if (mandatFonctions[0]?.fonctionId === "autre") {
              return mandatFonctions[0]?.intituleFonction;
            } else {
              const f = FONCTIONS[mandatFonctions[0]?.fonctionId];
              const lg = record.langue || Langue.Fr;

              if (f?.name && f.name[lg]) {
                return f.name[lg];
              }

              return "Fonction non spécifiée";
            }
          }}
        />
        <FunctionField
          label="Date de nomination ou d’élection"
          render={(record) => {
            const mandatFonction = record.mandatFonctions[0];
            let dateNomination = mandatFonction?.dateNomination;

            if (!dateNomination) return "Non Spécifiée";
            if (!dateNomination instanceof Date)
              dateNomination = new Date(dateNomination);

            return format(dateNomination, "DD/MM/YYYY", { locale: fr });
          }}
        />

        <DateField
          label="Mis à jour le"
          source="updatedAt"
          showTime
          locales="fr-FR"
        />
        <CandidatureStatusField label="Statut" source="statut" />
        <FunctionField
          label=""
          render={(record) => {
            return <DuplicationMenuButton record={record} />;
          }}
        />
      </Datagrid>
    </List>
  </ErrorBoundary>
);
