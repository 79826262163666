import { makeStyles } from '@material-ui/core/styles';

export const arrayStyles = makeStyles({
  arrayLabel: {
    '& > label': {
      fontSize: '1.5rem',
      color: 'rgba(0, 0, 0, 0.87)',
      position: 'relative',
      width: '100%'
    }
  }
});

export const buttonStyles = makeStyles({
  leftIcon: {
    marginRight: '0.3em',
  },
})