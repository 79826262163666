import React from "react";
import Edit from "../components/Edit";
import EditAside from "../components/EditAside";
import DomainNameForm from "./form/DomainNameForm";

import PostEditActions from "../components/custom/PostEditActions";
import { ErrorBoundary } from "../lib/error-report";

const EditTitle = ({ record }) => (
  <span>
    Nom de domaine {record ? `#${record.id || ""} - ${record.nom || ""}` : ""}
  </span>
);

export default (props) => (
  <ErrorBoundary>
    <Edit
      title={<EditTitle />}
      actions={<PostEditActions />}
      aside={<EditAside />}
      {...props}
    >
      <DomainNameForm />
    </Edit>
  </ErrorBoundary>
);
