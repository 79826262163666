import React from "react";
import { Filter, TextInput, SelectInput } from "react-admin";

export default (props) => (
  <Filter {...props}>
    <TextInput source="nom" />
    <TextInput label="Prénom" source="prenom" />
    <TextInput source="email" />
    <SelectInput
      label="Type de candidat"
      source="typeCandidat"
      choices={[
        { id: "interne", name: "Interne" },
        { id: "externe", name: "Externe" },
        { id: "groupe", name: "Groupe" },
      ]}
    />
  </Filter>
);

