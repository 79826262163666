import React from "react";
import { Toolbar, SaveButton } from "react-admin";
import { Box, Typography } from "@material-ui/core";

import NumberInputCustom from "../../components/input/NumberInputCustom.js";

PonderationForm.shouldRender = function PonderationForm_shouldRender(nom) {
  return nom === "Pondérations";
};

export default function PonderationForm(formProps) {
  return (
    <>
      <Box p="1em">
        <Typography variant="subtitle1">
          Pondérations à appliquer pour le calcul des temps consacré à la
          fonction concernée par la candidature.
        </Typography>
        <Box mt="1em" />
        <Typography variant="subtitle1">
          Indiquez dans les champs «&nbsp;Membre indépendant&nbsp;» la
          pondération à appliquer dans le cas où le candidat sera un membre
          indépendant. <br />
          Indiquez dans les champs «&nbsp;Par défaut&nbsp;» la pondération à
          appliuer dans les autres cas (membre exerçant des fonctions exécutives
          ou non-exécutives)
          <br />
          Si cette distinction ne s’applique pas, indiquez la même valeur dans
          les deux champs
        </Typography>

        <Box mt="1em" />
        <Typography variant="h6">Comités dont il/elle est membre</Typography>
        <Box display="flex" width="100%">
          <NumberInputCustom
            label="Par défaut"
            source="value.comitesMembreDirigeant"
            helperText="jours par réunion"
            min={0}
            step={0.5}
          />
          <Box ml="1em">
            <NumberInputCustom
              label="Membre indépendant·e"
              source="value.comitesMembreIndep"
              helperText="jours par réunion"
              min={0}
              step={0.5}
            />
          </Box>
        </Box>

        <Box mt="1em" />
        <Typography variant="h6">
          Comités dont il/elle est président·e
        </Typography>
        <Box display="flex" width="100%">
          <NumberInputCustom
            label="Par défaut"
            source="value.comitesPresidentDirigeant"
            helperText="jours par réunion"
            min={0}
            step={0.5}
          />
          <Box ml="1em">
            <NumberInputCustom
              label="Membre indépendant"
              source="value.comitesPresidentIndep"
              helperText="jours par réunion"
              min={0}
              step={0.5}
            />
          </Box>
        </Box>

        <Box mt="1em" />
        <Typography variant="h6">Conseils dont il/elle est membre</Typography>
        <Box display="flex" width="100%">
          <NumberInputCustom
            label="Par défaut"
            source="value.conseilsMembreDirigeant"
            helperText="jours par réunion"
            min={0}
            step={0.5}
          />
          <Box ml="1em">
            <NumberInputCustom
              label="Membre indépendant"
              helperText="jours par réunion"
              source="value.conseilsMembreIndep"
              min={0}
              step={0.5}
            />
          </Box>
        </Box>

        <Box mt="1em" />
        <Typography variant="h6">
          Conseils dont il/elle est président·e
        </Typography>
        <Box display="flex" width="100%">
          <NumberInputCustom
            label="Par défaut"
            source="value.conseilsPresident"
            helperText="jours par réunion"
            min={0}
            step={0.5}
          />
        </Box>

        <Box mt="1em" />
        <Typography variant="h6">Réunions des dirigeants</Typography>
        <Box display="flex" width="100%">
          <NumberInputCustom
            label="Par défaut"
            source="value.reunionsDirigeants"
            helperText="jours par réunion"
            min={0}
            step={0.5}
          />
          <Box ml="1em">
            <NumberInputCustom
              label="Membre indépendant"
              source="value.reunionsDirigeantsIndep"
              helperText="jours par réunion"
              min={0}
              step={0.5}
            />
          </Box>
        </Box>
      </Box>

      <Toolbar>
        <SaveButton
          record={formProps.record}
          basePath={formProps.basePath}
          undoable={false}
          invalid={formProps.invalid}
          handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
          saving={formProps.saving}
          pristine={formProps.pristine}
          submitOnEnter={false}
          resource="configs"
          redirect="list"
        />
      </Toolbar>
    </>
  );
}
