import React from "react";
import { Typography, Box } from "@material-ui/core";
import { ArrayInput, FormDataConsumer } from "react-admin";

import ConditionalInput from "../../components/input/ConditionalInput";
import DateInputCustom from "../../components/input/DateInputCustom";
import TextInputCustom from "../../components/input/TextInputCustom";
import SelectInputCustom from "../../components/input/SelectInputCustom";
import NullableBooleanInputCustom from "../../components/input/NullableBooleanInputCustom";
import FormIteratorCustom from "../../components/custom/FormIteratorCustom";
import NumberInputCustom from "../../components/input/NumberInputCustom";

import FileDl from "../../components/File";
import InfoBulle from "../../components/InfoBulle";

import { useGlobalDisabled } from "./helper";
import { useLangue } from "../transalations";

const AssocieAutreEntreprise = ({ name, tabName, disabled }) => {
  const { t } = useLangue();

  return (
    <Box>
      <TextInputCustom
        label={t("Nom de l'entreprise")}
        source={`${name}.nomEntreprise`}
        id={`${tabName}#${name}.nomEntreprise`}
        disabled={disabled}
      />
      <TextInputCustom
        multiline
        label={t("Nature de ses activités")}
        source={`${name}.natureActivitesEntreprise`}
        id={`${tabName}#${name}.natureActivitesEntreprise`}
        fullWidth
        disabled={disabled}
      />
      <Typography variant="subtitle1">
        {t("Niveau de participation (en % du capital et des droits de vote) :")}
      </Typography>
      <TextInputCustom
        source={`${name}.niveauParticipation`}
        id={`${tabName}#${name}.niveauParticipation`}
        disabled={disabled}
      />
      <TextInputCustom
        multiline
        label={t("Période concernée")}
        source={`${name}.periodeConcernee`}
        id={`${tabName}#${name}.periodeConcernee`}
        fullWidth
        disabled={disabled}
      />
      <Typography variant="subtitle1">
        {t(
          "Liens entre ces entreprises et l’entité soumise à la surveillance prudentielle, sa société mère et ses filiales :"
        )}
      </Typography>
      <TextInputCustom
        multiline
        source={`${name}.liens`}
        id={`${tabName}#${name}.liens`}
        fullWidth
        disabled={disabled}
      />
    </Box>
  );
};

const ObligationFinanciere = ({ name, tabName, disabled }) => {
  const { t } = useLangue();

  return (
    <Box>
      <TextInputCustom
        label={t("Nom du débiteur")}
        source={`${name}.nomDebiteur`}
        id={`${tabName}#${name}.nomDebiteur`}
        disabled={disabled}
        portalTargets="imas"
        helperText="s’il ne s’agit pas de la personne nommée / personne à nommer, précisez la relation entretenue avec celle-ci"
        fullWidth
      />
      <TextInputCustom
        label={t("Nom de l'entité")}
        source={`${name}.nomEntite`}
        id={`${tabName}#${name}.nomEntite`}
        disabled={disabled}
        fullWidth
      />
      <TextInputCustom
        label={t("Nature de l’obligation")}
        source={`${name}.type`}
        id={`${tabName}#${name}.type`}
        disabled={disabled}
        fullWidth
      />
      <TextInputCustom
        label={t("Montant de l’obligation")}
        source={`${name}.amount`}
        id={`${tabName}#${name}.amount`}
        disabled={disabled}
        fullWidth
      />
      <Typography variant="subtitle1">
        {t("Garantie, le cas échéant")}
      </Typography>
      <TextInputCustom
        disabled={disabled}
        multiline
        source={`${name}.guarantee`}
        portalTargets="imas"
        id={`${tabName}#${name}.guarantee`}
        fullWidth
        helperText="type et montant"
      />
      <Typography variant="subtitle1">{t("Statut des obligations")}</Typography>
      <SelectInputCustom
        disabled={disabled}
        multiline
        source={`${name}.statut`}
        portalTargets="imas"
        id={`${tabName}#${name}.statut`}
        fullWidth
        choices={[
          { id: "performing", name: "Performant(e)" },
          { id: "non-performing", name: "Non Performant(e)" },
        ]}
      />
      <Typography variant="subtitle1">
        {t("Conditions des obligations")}
      </Typography>
      <SelectInputCustom
        disabled={disabled}
        multiline
        source={`${name}.conditions`}
        portalTargets="imas"
        id={`${tabName}#${name}.conditions`}
        fullWidth
        choices={[
          { id: "market-conditions", name: "Conditions du marché" },
          {
            id: "negotiated-at-arms-length",
            name: "Conditions normales du marché",
          },
          { id: "special-conditions", name: "Conditions spéciales" },
          {
            id: "supported-under-the-collective-bargaining-agreements",
            name: "Couvert(e) par les conventions collectives",
          },
          {
            id: "contracts-with-standardised-terms-applied-together-and-on-a-regular-basis-to-a-large-number-of-customers",
            name: "Contrats assortis de clauses normalisées appliquées ensemble et de manière régulière à un grand nombre de clients",
          },
          { id: "other", name: "Autre" },
        ]}
      />
      <DateInputCustom
        disabled={disabled}
        label={t("Date de début")}
        id={`${tabName}#${name}.dateDebut`}
        name={`${name}.dateDebut`}
      />
      <Typography variant="subtitle1">
        {t(
          "Valeur de l’obligation en pourcentage du total des prêts du débiteur"
        )}
      </Typography>
      <NumberInputCustom
        disabled={disabled}
        name={`${name}.valueToLoans`}
        id={`${tabName}#${name}.valueToLoans`}
      />
      <Typography variant="subtitle1">
        {t(
          "Valeur de l’obligation en pourcentage du total des fonds propres éligibles de l’entité soumise à la surveillance prudentielle"
        )}
      </Typography>
      <NumberInputCustom
        disabled={disabled}
        name={`${name}.valueToCapital`}
        id={`${tabName}#${name}.valueToCapital`}
      />
    </Box>
  );
};

const LienFinancier = ({ name, tabName, disabled }) => {
  const { t } = useLangue();

  return (
    <Box>
      <TextInputCustom
        label={t("Nom de l'entreprise")}
        source={`${name}.nomEntreprise`}
        id={`${tabName}#${name}.nomEntreprise`}
        disabled={disabled}
      />
      <TextInputCustom
        label={t("Activités de l’entreprise")}
        source={`${name}.activitesEntreprise`}
        id={`${tabName}#${name}.activitesEntreprise`}
        disabled={disabled}
      />
      <Typography variant="subtitle1">
        {t(
          "Type de relation entre l’entité et l’entité soumise à la surveillance prudentielle"
        )}
      </Typography>
      <TextInputCustom
        disabled={disabled}
        multiline
        source={`${name}.lien`}
        id={`${tabName}#${name}.lien`}
        fullWidth
      />
      <TextInputCustom
        disabled={disabled}
        portalTarget="acpr"
        label={t("Période d’existence du lien")}
        source={`${name}.periodeLien`}
        id={`${tabName}#${name}.periodeLien`}
      />
      <Typography variant="subtitle1">
        {t(
          "Taille de la participation financière (% du capital et des droits de vote; ou valeur d’investissement)"
        )}
      </Typography>
      <TextInputCustom
        disabled={disabled}
        source={`${name}.interetFinancier`}
        id={`${tabName}#${name}.interetFinancier`}
      />
    </Box>
  );
};

function LienProfessionnel({ disabled, tabName, name }) {
  const { t } = useLangue();

  return (
    <Box>
      <Typography variant="subtitle1">{t("Nom de l’entité")}</Typography>
      <TextInputCustom
        disabled={disabled}
        source={`${name}.nomEntite`}
        portalTargets="imas"
        id={`${tabName}#nomEntite`}
      />
      <Typography variant="subtitle1">
        {t("Principales activités de l’entité")}
      </Typography>
      <TextInputCustom
        disabled={disabled}
        source={`${name}.activiteEntite`}
        portalTargets="imas"
        id={`${tabName}#activiteEntite`}
        fullWidth
        multiline
      />
      <Typography variant="subtitle1">
        {t("Type de relatition avec l’entité")}
      </Typography>
      <TextInputCustom
        disabled={disabled}
        source={`${name}.lienAvecEntite`}
        portalTargets="imas"
        id={`${tabName}#lienAvecEntite`}
        fullWidth
        multiline
      />
      <Typography variant="subtitle1">
        {t("Date(s) de début et (le cas échéant) de fin de la relation")}
      </Typography>
      <TextInputCustom
        disabled={disabled}
        source={`${name}.dateDebutFin`}
        portalTargets="imas"
        id={`${tabName}#dateDebutFin`}
      />
      <Typography variant="subtitle1">
        {t("Versements annuels effectués par le payeur")}
      </Typography>
      <TextInputCustom
        disabled={disabled}
        source={`${name}.valeurPaimentsAnnuels`}
        portalTargets="imas"
        id={`${tabName}#valeurPaimentsAnnuels`}
      />
      <Typography variant="subtitle1">
        {t(
          "Versements annuels effectués par le payeur (en pourcentage de son revenu brut (consolidé))"
        )}
      </Typography>
      <TextInputCustom
        disabled={disabled}
        source={`${name}.paiementsAnnuelsPayeur`}
        portalTargets="imas"
        id={`${tabName}#paiementsAnnuelsPayeur`}
      />
      <Typography variant="subtitle1">
        {t(
          "Versements annuels reçus par le bénéficiaire (en pourcentage de son revenu brut (consolidé))"
        )}
      </Typography>
      <TextInputCustom
        disabled={disabled}
        source={`${name}.paimentsAnnuelsRecus`}
        portalTargets="imas"
        id={`${tabName}#paimentsAnnuelsRecus`}
      />
    </Box>
  );
}

function Actionnaire({ tabName, disabled, ...rest }) {
  const { t } = useLangue();
  return (
    <Box>
      <Typography variant="subtitle1">
        {t("Veuillez fournir les informations suivantes :")}
      </Typography>
      <Box display="flex">
        <Box>
          <TextInputCustom
            disabled={disabled}
            label={t("Nom de l'actionnaire")}
            source="representeActionnaireNom"
            id={`${tabName}#representeActionnaireNom`}
            {...rest}
          />
        </Box>
        <Box ml="1em">
          <NumberInputCustom
            disabled={disabled}
            label={t("% de participation")}
            source="representeActionnaireParticipation"
            id={`${tabName}#representeActionnaireParticipation`}
            {...rest}
          />
        </Box>
      </Box>
      <TextInputCustom
        disabled={disabled}
        multiline
        label={t("Nature de la représentation")}
        source="representeActionnaireNature"
        id={`${tabName}#representeActionnaireNature`}
        {...rest}
        fullWidth
      />
    </Box>
  );
}

Actionnaire.clear = function (formData) {
  formData.representeActionnaireNom = null;
  formData.representeActionnaireParticipation = null;
  formData.representeActionnaireNature = null;
};

function ParticipationFinanciere({ disabled, tabName, ...rest }) {
  const { t } = useLangue();

  return (
    <Box>
      <Typography variant="subtitle1">
        {t("Veuillez fournir les informations suivantes :")}
      </Typography>
      <Box display="flex">
        <Box>
          <TextInputCustom
            disabled={disabled}
            label={t("Nom de l’entité")}
            source="participationFinanciereEntiteNom"
            id={`${tabName}#participationFinanciereEntiteNom`}
            {...rest}
          />
        </Box>
        <Box ml="1em">
          <TextInputCustom
            disabled={disabled}
            label={t("Activités principales de l’entité")}
            source="participationFinanciereEntiteActivitePrincipale"
            id={`${tabName}#participationFinanciereEntiteActivitePrincipale`}
            {...rest}
          />
        </Box>
      </Box>
      <Box display="flex">
        <Box>
          <TextInputCustom
            disabled={disabled}
            label={t("Type de relation")}
            helpText="entre l’entitl et l’entité soumise à la surveillance prudentielle"
            source="participationFinanciereTypeRelation"
            id={`${tabName}#participationFinanciereTypeRelation`}
            {...rest}
          />
        </Box>
        <Box ml="1em">
          <DateInputCustom
            disabled={disabled}
            label={t("Date de début de la participation financière")}
            source="participationFinanciereDateDebut"
            id={`${tabName}#participationFinanciereDateDebut`}
            {...rest}
          />
        </Box>
        <Box ml="1em">
          <TextInputCustom
            disabled={disabled}
            label={t("Taille de la participation")}
            helpText="en pourcentage des droits de vote de l’entité ou de la valeur de l’investissement"
            source="participationFinanciereTaille"
            id={`${tabName}#participationFinanciereTaille`}
            {...rest}
          />
        </Box>
      </Box>
    </Box>
  );
}

ParticipationFinanciere.clear = function (formData) {
  formData.participationFinanciereEntiteNom = null;
  formData.participationFinanciereEntiteActivitePrincipale = null;
  formData.participationFinanciereTypeRelation = null;
  formData.participationFinanciereDateDebut = null;
  formData.participationFinanciereTaille = null;
};

function RelationNuisible({ disabled, tabName, ...rest }) {
  const { t } = useLangue();

  return (
    <Box>
      <Box display="flex">
        <TextInputCustom
          disabled={disabled}
          label={t(
            "Nature et teneur de la relation, du poste, ou de la participation"
          )}
          source={"relationNuisibleNature"}
          id={`${tabName}#relationNuisibleNature`}
          multiline
          fullWidth
          {...rest}
        />
      </Box>
      <Box display="flex">
        <Box>
          <TextInputCustom
            disabled={disabled}
            label={t("Date de début de la relation")}
            helperText="ou de la participation ou de l’entrée en fonction"
            source={"relationNuisibleDateDebut"}
            id={`${tabName}#relationNuisibleDateDebut`}
            multiline
            fullWidth
            {...rest}
          />
        </Box>
        <Box ml="1em">
          <TextInputCustom
            disabled={disabled}
            label={t("Influence éventuelle de la relation")}
            helperText="ou du poste, ou de la participation sur votre nomination"
            source={"relationNuisibleInfluence"}
            id={`${tabName}#relationNuisibleInfluence`}
            multiline
            fullWidth
            {...rest}
          />
        </Box>
      </Box>
    </Box>
  );
}

RelationNuisible.clear = function (formData) {
  formData.relationNuisible = null;
  formData.relationNuisibleNature = null;
  formData.relationNuisibleDateDebut = null;
  formData.relationNuisibleInfluence = null;
};

export default (props) => {
  const { tabName } = props;

  const disabled = useGlobalDisabled();

  const { t } = useLangue();

  return (
    <Box maxWidth="72em">
      <Box display="flex" justifyContent="flex-end">
        <InfoBulle position="right" nonHoverable>
          Les questions s’adressent à la personne nommée (renouvelée), ainsi
          qu’à ses proches (conjoint, partenaire, concubin, enfant, parent ou
          tout autre personne qui partage son logement) et à toute personne
          morale dont la personne nommée (renouvelée) est ou a été un
          actionnaire significatif (défini comme un actionnaire détenant au
          moins 10% du capital ou des droits de vote d’une entreprise), un
          associé (associé en nom ou associé commandité), ou un membre de
          l’organe de direction.
        </InfoBulle>
      </Box>
      <ConditionalInput
        disabled={disabled}
        property="estAssocieAutreEntreprise"
        label={`${t(
          "Avez-vous été au cours des dix dernières années ou êtes-vous un actionnaire significatif, un associé en nom ou un associé commandité d’une autre entreprise ?"
        )}`}
      >
        <ArrayInput
          source="associeAutreEntreprises"
          label={t("Veuillez apporter des précisions :")}
        >
          <FormIteratorCustom
            disabled={disabled}
            component={
              <AssocieAutreEntreprise disabled={disabled} tabName={tabName} />
            }
          />
        </ArrayInput>
      </ConditionalInput>

      <Typography variant="subtitle1" gutterBottom>
        Politique conflits d'intérêts
        <FileDl
          disabled={disabled}
          isArray
          source={"politiqueConflitsInterets"}
        />
      </Typography>

      <Typography variant="subtitle1">
        {t("▸ Un lien personnel existe-t-il entre vous et :")}
        <ul>
          <li>
            <Box display="flex">
              <span>
                {t(
                  "d’autres membres de l’organe de direction et/ou des titulaires de postes clés au sein de l’entité soumise à la surveillance prudentielle, sa société mère ou ses filiales ?"
                )}
              </span>
              <InfoBulle position="right">
                "Un lien personnel vise des relations qui ne sont pas d’ordre
                strictement professionnel et inclut des relations d’ordre amical
                ou familial. Suivant le guide de la BCE relatif à l’évaluation
                de la compétence et de l’honorabilité, un lien personnel étroit 
                comprend le conjoint, le partenaire enregistré, le concubin,
                l’enfant, le parent ou tout autre personne proche avec laquelle
                la personne nommée partage son logement."
                <br />
                –
                <br />
                Les opérations avec l’établissement régulé qui relèvent de la
                gestion courante des avoirs et sont conclues à des conditions
                normales du marché, n'ont pas à être déclarées.
              </InfoBulle>
            </Box>
          </li>
          <li>
            <Box display="flex">
              <span>
                {t(
                  "une personne détenant une participation qualifiée au sein de l’entité soumise à la surveillance prudentielle, sa société mère ou ses filiales ?"
                )}
              </span>
            </Box>
          </li>
          <li>
            <Box display="flex">
              <span>
                des clients, des fournisseurs ou des concurrents de l’entité
                soumise à la surveillance prudentielle, de la société mère ou
                des filiales de l’entité soumise à la surveillance
                prudentielle&nbsp;?
              </span>
            </Box>
          </li>
        </ul>
      </Typography>
      <ConditionalInput disabled={disabled} property="lienPersonnelAvecEntite">
        {({ disabled, ...rest }) => (
          <Box>
            <Typography variant="subtitle1">
              {t("Veuillez apporter des précisions :")}
            </Typography>
            <TextInputCustom
              disabled={disabled}
              multiline
              source="lienPersonnelAvecEntiteText"
              id={`${tabName}#lienPersonnelAvecEntiteText`}
              fullWidth
              {...rest}
            />
          </Box>
        )}
      </ConditionalInput>

      <ConditionalInput
        disabled={disabled}
        portalTargets="acpr"
        property="lienAffaireAvecEntite"
        label={t(
          "Êtes-vous en relations d’affaires ou comptez-vous l’être (à titre privé ou professionnel ou par l’intermédiaire d’une société) avec l’entité soumise à la surveillance prudentielle, sa société mère ou ses filiales ?"
        )}
        infoBulle={`La relation d’affaires escomptée implique que les opérations entre le
          candidat et l’entité soient sérieusement envisagées sans
          nécessairement donner lieu à la signature d’actes contractuels
          engageant les parties.
          <br />
          Les opérations avec l’établissement régulé qui relèvent de la gestion
          courante des avoirs et sont conclues à des conditions normales du
          marché, n'ont pas à être déclarées.`}
      >
        {({ disabled, ...rest }) => (
          <Box>
            <Typography variant="subtitle1">
              {t("Veuillez fournir les informations suivantes :")}
              <ul>
                <li>
                  {t(
                    "une description du type et de la teneur des opérations concernées et des obligations des deux parties;"
                  )}
                </li>
                <li>{t("le cas échéant, le nom de l’entreprise;")}</li>
                <li>{t("la période durant laquelle ce lien a existé")}</li>
              </ul>
            </Typography>
            <TextInputCustom
              disabled={disabled}
              multiline
              source="lienAffaireAvecEntiteText"
              id={`${tabName}#lienAffaireAvecEntiteText`}
              fullWidth
              {...rest}
            />
          </Box>
        )}
      </ConditionalInput>

      <ConditionalInput
        disabled={disabled}
        property="procedureJudiciaireAvecEntite"
        label={`${t(
          "Êtes-vous actuellement impliqué(e) dans des procédures judiciaires engagées contre l’entité soumise à la surveillance prudentielle, sa société mère ou ses filiales, que ce soit directement ou indirectement ?"
        )}`}
        infoBulle={`Le terme indirectement vise les procédures judiciaires qui peuvent
          être diligentées à l’encontre d’un établissement dans lequel la
          personne concernée exerçait un rôle exécutif ou non exécutif. Le
          superviseur évalue ensuite si la procédure engagée porte atteinte ou
          non à l’honorabilité du candidat.`}
      >
        {({ disabled, ...rest }) => (
          <Box>
            <Typography variant="subtitle1">
              {t(
                "Veuillez fournir des informations sur la teneur et l’état d’avancement des procédures judiciaires ainsi que sur l’entité concernée :"
              )}
            </Typography>
            <TextInputCustom
              disabled={disabled}
              multiline
              source="procedureJudiciaireAvecEntiteText"
              id={`${tabName}#procedureJudiciaireAvecEntiteText`}
              fullWidth
              {...rest}
            />
          </Box>
        )}
      </ConditionalInput>

      <Box display="flex">
        <Typography variant="subtitle1">
          {t(
            "▸ Entretenez-vous ou avez-vous entretenu au cours des deux dernières années un lien professionnel ou commercial avec :"
          )}
          <ul>
            <li>
              {t(
                "l’entité soumise à la surveillance prudentielle, sa société mère ou ses filiales ?"
              )}
            </li>
            <li>
              {t(
                "des concurrents de l’entité soumise à la surveillance prudentielle, de sa société mère ou de ses filiales ?"
              )}
            </li>
          </ul>
          {t(
            "Ce lien peut être direct ou au travers d’une structure juridique distincte."
          )}
        </Typography>
        <InfoBulle position="right">
          Lien professionnel&nbsp;: en tant que titulaire d’un contrat de
          travail.
          <br />
          <br />
          Lien commercial&nbsp;: à titre d'exemple, la société a-t-elle parmi
          ses clients ou fournisseurs une société au sein de laquelle vous
          exercez des fonctions de dirigeant effectif, d’actionnaire
          significatif, d’associé en nom ou d’associé commandité&#x202F;?
          <br />
          Dans un autre ordre d’idées, avez-vous l’intention d’ouvrir un (ou
          des) compte(s) dans les livres de l’établissement et, dans
          l’affirmative, envisagez-vous d’effectuer des opérations ne relevant
          pas d’une gestion courante de vos avoirs&#x202F;?
        </InfoBulle>
      </Box>
      <ConditionalInput
        disabled={disabled}
        portalTargets="imas"
        property="lienProAvecEntite"
      >
        <ArrayInput
          source="lienProfessionnels"
          label="Veuillez fournir des détails complémentaires (en cas de lien commercial, indiquez la valeur (financière) que celui-ci représente pour l’activité de la personne nommée (renouvelée) ou de ses relations personnelles ou professionnelles proches)&nbsp;:"
        >
          <FormIteratorCustom
            disabled={disabled}
            component={
              <LienProfessionnel disabled={disabled} tabName={tabName} />
            }
          />
        </ArrayInput>
      </ConditionalInput>

      <Typography variant="subtitle1">
        {t(
          "▸ Avez-vous une participation financière (en tant que propriétaire ou investisseur, par exemple) dans :"
        )}
        <ul>
          <li>
            {t(
              "l’entité soumise à la surveillance prudentielle, la société mère ou leurs filiales ?"
            )}
          </li>
          <li>
            {t(
              "des clients, des fournisseurs ou des concurrents de l’entité soumise à la surveillance prudentielle, de la société mère ou des filiales de l’entité soumise à la surveillance prudentielle ?"
            )}
          </li>
        </ul>
      </Typography>
      <ConditionalInput
        disabled={disabled}
        property="interetFinancierDansEntite"
        infoBulle={`N'ont pas à être déclarés : l’ensemble des prêts personnels garantis
          (tels que les hypothèques privées) accordés à un taux non préférentiel
          (c’est-à-dire aux conditions normales de marché) ; tous les autres
          prêts à taux non préférentiel de moins de 200 000 euros, garantis ou
          non ; les participations actuelles ≤ 1 % ou les autres investissements
          d’une valeur équivalente.`}
      >
        <ArrayInput
          source="lienFinanciers"
          id={`${tabName}#lienFinanciers`}
          label={t(
            "Veuillez fournir toutes les informations suivantes relatives à ce lien financier :"
          )}
        >
          <FormIteratorCustom
            disabled={disabled}
            component={<LienFinancier disabled={disabled} tabName={tabName} />}
          />
        </ArrayInput>
      </ConditionalInput>

      <Box display="flex">
        <Typography variant="subtitle1">
          {t(
            "▸ Êtes-vous soumis(e) à une période de carence (au titre d’un accord ou conformément à la législation) ?"
          )}
        </Typography>
      </Box>
      <NullableBooleanInputCustom
        disabled={disabled}
        portalTargets="imas"
        source="periodeCarence"
        id={`${tabName}#periodeCarence`}
      />

      <ConditionalInput
        disabled={disabled}
        property="representeActionnaire"
        label={`${t(
          "Représentez-vous, de quelque façon que ce soit, un actionnaire de l’entité soumise à la surveillance prudentielle, de sa société mère ou de ses filiales ?"
        )}`}
        infoBulle={`Le candidat doit indiquer s’il représente un actionnaire de l’entité,
          de sa société mère ou de ses filiales.`}
        onNo={Actionnaire.clear}
      >
        <Actionnaire tabName={tabName} />
      </ConditionalInput>

      <Box display="flex">
        <Typography variant="subtitle1">
          {t(
            "▸ Avez-vous actuellement des obligations financières significatives envers l’entité soumise à la surveillance prudentielle, sa société mère ou ses filiales ?"
          )}
          <br />
          {t(
            "NB : par principe, les prêts consentis aux conditions normales du marché d’un montant inférieur à 200 000 euros et les prêts immobiliers garantis consentis aux conditions normales du marché, ne sont pas considérés comme significatifs."
          )}
        </Typography>
        <InfoBulle position="right">
          Les opérations avec l’établissement régulé qui relèvent de la gestion
          courante des avoirs et sont conclues à des conditions normales du
          marché, n’ont pas à être déclarées.
        </InfoBulle>
      </Box>
      <ConditionalInput disabled={disabled} property="obligationsFinancieres">
        <ArrayInput
          source="obligations"
          id={`${tabName}#obligations`}
          label={t(
            "Veuillez fournir toutes les informations suivantes relatives à ces obligations :"
          )}
        >
          <FormIteratorCustom
            disabled={disabled}
            component={
              <ObligationFinanciere disabled={disabled} tabName={tabName} />
            }
          />
        </ArrayInput>
      </ConditionalInput>

      <Typography variant="subtitle1">
        {t(
          "▸ Avez-vous une participation financière (en tant que propriétaire ou investisseur, par exemple) dans :"
        )}
        <ul>
          <li>
            l’entité soumise à la surveillance prudentielle, la société mère ou
            leurs filiales ?
          </li>
          <li>
            des clients, des fournisseurs ou des concurrents de l’entité soumise
            à la surveillance prudentielle, de la société mère ou des filiales
            de l’entité soumise à la surveillance prudentielle ?
          </li>
        </ul>
      </Typography>
      <InfoBulle position="right">
        Il n’est pas nécessaire de déclarer les participations actuelles
        inférieures à 1 % ou tout autre investissement d’une valeur équivalente.
      </InfoBulle>
      <ConditionalInput
        disabled={disabled}
        property="participationFinanciere"
        onNo={ParticipationFinanciere.clear}
      >
        <ParticipationFinanciere tabName={tabName} />
      </ConditionalInput>

      <ConditionalInput
        disabled={disabled}
        property="relationNuisible"
        label={t(
          "Avez-vous des relations et participations ou occupez-vous des postes qui n’ont pas été couvert(e)s dans les questions ci- dessus et qui pourraient nuire aux intérêts de l’entité soumise à la surveillance prudentielle ?"
        )}
        onNo={RelationNuisible.clear}
      >
        <RelationNuisible tabName={tabName} />
      </ConditionalInput>

      <Typography variant="subtitle1">
        {t(
          "▸ Occupez-vous ou avez-vous occupé au cours des deux dernières années un poste à forte influence politique (au plan national ou local) ?"
        )}
      </Typography>
      <NullableBooleanInputCustom
        disabled={disabled}
        source="posteForteInfluence"
        id={`${tabName}#posteForteInfluence`}
      />
      <FormDataConsumer subscription={{ values: true }}>
        {({ formData, ...rest }) => {
          if (formData.posteForteInfluence) {
            return (
              <Box>
                <Typography variant="subtitle1">
                  {t("Veuillez fournir les informations suivantes :")}
                </Typography>
                <TextInputCustom
                  disabled={disabled}
                  multiline
                  label={t("Nature du poste")}
                  source="posteForteInfluenceNature"
                  id={`${tabName}#posteForteInfluenceNature`}
                  {...rest}
                  fullWidth
                />
                <TextInputCustom
                  disabled={disabled}
                  multiline
                  label={t(
                    "Pouvoirs spécifiques ou obligations liés à ce poste"
                  )}
                  source="posteForteInfluencePouvoirs"
                  id={`${tabName}#posteForteInfluencePouvoirs`}
                  {...rest}
                  fullWidth
                />
                <TextInputCustom
                  disabled={disabled}
                  multiline
                  label={t(
                    "Lien entre ce poste (ou l’entité où ce poste est occupé) et l’entité soumise à la surveillance prudentielle, sa société mère ou ses filiales"
                  )}
                  source="posteForteInfluenceLien"
                  id={`${tabName}#posteForteInfluenceLien`}
                  {...rest}
                  fullWidth
                />
              </Box>
            );
          } else {
            formData.posteForteInfluenceNature = null;
            formData.posteForteInfluencePouvoirs = null;
            formData.posteForteInfluenceLien = null;
          }
        }}
      </FormDataConsumer>

      <Typography variant="subtitle1">
        {t(
          "▸ Êtes-vous lié par certains engagements pris au titre de fonctions précédemment exercées en France ou à l’étranger (clause de non concurrence, par exemple) ?"
        )}
      </Typography>
      <NullableBooleanInputCustom
        disabled={disabled}
        source="engagementsPrecedentesFonctions"
      />
      <FormDataConsumer subscription={{ values: true }}>
        {({ formData, ...rest }) => {
          if (formData.engagementsPrecedentesFonctions) {
            return (
              <Box>
                <Typography variant="subtitle1">
                  {t("Veuillez fournir des détails complémentaires :")}
                </Typography>
                <TextInputCustom
                  disabled={disabled}
                  multiline
                  source="engagementsPrecedentesFonctionsText"
                  id={`${tabName}#engagementsPrecedentesFonctionsText`}
                  {...rest}
                  fullWidth
                />
              </Box>
            );
          } else {
            formData.engagementsPrecedentesFonctionsText = null;
          }
        }}
      </FormDataConsumer>

      <Typography variant="subtitle1">
        {t(
          "▸ Avez-vous d’autres liens, engagements ou occupez-vous d’autres postes que ceux couverts dans les questions ci-dessus et qui pourraient nuire aux intérêts de l’entité soumise à la surveillance prudentielle ?"
        )}
      </Typography>
      <NullableBooleanInputCustom
        disabled={disabled}
        source="engagementsNuisiblesEntite"
      />
      <FormDataConsumer subscription={{ values: true }}>
        {({ formData, ...rest }) => {
          if (formData.engagementsNuisiblesEntite) {
            return (
              <Box>
                <Typography variant="subtitle1">
                  {t(
                    "Veuillez fournir toutes les informations nécessaires (notamment relatives à la nature, la teneur, la période et, le cas échéant, au lien avec l’entité soumise à la surveillance prudentielle, sa société mère ou ses filiales) :"
                  )}
                </Typography>
                <TextInputCustom
                  disabled={disabled}
                  multiline
                  source="engagementsNuisiblesEntiteText"
                  id={`${tabName}#engagementsNuisiblesEntiteText`}
                  {...rest}
                  fullWidth
                />
              </Box>
            );
          } else {
            formData.engagementsNuisiblesEntiteText = null;
          }
        }}
      </FormDataConsumer>

      <Box display="flex">
        <Typography variant="subtitle1">
          {t(
            "▸ Si besoin, indiquez comment prévenir et/ou encadrer un éventuel conflit d’intérêts tel qu’il ressort des déclarations ci-dessus de la personne nommée (renouvelée), que celui-ci soit considéré comme significatif ou non. À cet égard, veuillez joindre tout document utile (statuts, règlement intérieur, procédures, etc.)."
          )}
        </Typography>
        <InfoBulle position="right">
          Exemple de documents attendus&nbsp;:
          <br />
          Les Statuts (partie conventions réglementées notamment),
          <br />
          la Charte des conflits d’intérêt (ou équivalent) signée par le
          mandataire,
          <br />
          la Politique de prévention et de gestion des conflits d’intérêt des
          dirigeants effectifs et administrateur (ou équivalent),
          <br />
          le Règlement intérieur…
        </InfoBulle>
      </Box>
      <TextInputCustom
        disabled={disabled}
        multiline
        source="solutionConflitInteret"
        id={`${tabName}#solutionConflitInteret`}
        fullWidth
      />
    </Box>
  );
};
