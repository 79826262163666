import React from "react";

import { Datagrid, List, TextField, DateField } from "react-admin";
import { ErrorBoundary } from "../lib/error-report.js";
import ConfigFilter from "./ConfigFilter.js";

export default (props) => (
  <ErrorBoundary>
    <List
      bulkActionButtons={false}
      {...props}
      title="Pondérations"
      sort={{ field: "nom", order: "ASC" }}
      exporters={false}
      filters={<ConfigFilter />}
      actions={null}
    >
      <Datagrid rowClick="edit">
        <TextField label="Nom" source="nom" />
        <DateField
          label="Mis à jour le"
          source="updatedAt"
          showTime
          locales="fr-FR"
        />
      </Datagrid>
    </List>
  </ErrorBoundary>
);
