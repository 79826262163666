import React from "react";

import { Typography, Box } from "@material-ui/core";

import TranslatableTextInputCustom from "../../../components/input/TranslatableTextInputCustom";
import DateInputCustom from "../../../components/input/DateInputCustom";

function Subtitle(props) {
  return (
    <Box maxWidth="72em">
      <Typography {...props} />
    </Box>
  );
}

export default function Procedure({ disabled, tabName, ...rest }) {
  return (
    <Box ml="2em" mr="2em">
      <Subtitle variant="subtitle1">Motif de la mesure disciplinaire</Subtitle>
      <TranslatableTextInputCustom
        disabled={disabled}
        multiline
        source="infraction.procedureEnCoursText"
        id={`${tabName}#infraction.procedureEnCoursText`}
        fullWidth
        {...rest}
      />

      <Box display="flex">
        <DateInputCustom
          label="Date ou période de déroulement des actes répréhensibles présumés"
          disabled={disabled}
          source="infraction.procedureEnCoursDate"
          id={`${tabName}#infraction.procedureEnCoursDate`}
          portalTargets="imas"
        />
        <Box ml="0.5em">
          <TranslatableTextInputCustom
            label="Avez-vous déposé un recours contre la mesure disciplinaire ?"
            disabled={disabled}
            source="infraction.procedureEnCoursAppeal"
            id={`${tabName}#infraction.procedureEnCoursAppeal`}
            portalTargets="imas"
          />
        </Box>
      </Box>

      <Subtitle variant="subtitle1">
        If applicable, provide details of the entity involved in the measures or
        actions
      </Subtitle>
      <TranslatableTextInputCustom
        disabled={disabled}
        source="infraction.procedureEnCoursDetailsEntite"
        id={`${tabName}#infraction.procedureEnCoursDetailsEntite`}
        portalTargets="imas"
      />

      <Subtitle variant="subtitle1">
        Any mitigating or aggravating factors
      </Subtitle>
      <TranslatableTextInputCustom
        disabled={disabled}
        source="infraction.procedureEnCoursCirconstancesAggravantes"
        id={`${tabName}#infraction.procedureEnCoursCirconstancesAggravantes`}
        portalTargets="imas"
      />
    </Box>
  );
}

Procedure.clear = function (formData) {
  formData.infraction.porecdureEnCoursText = null;
  formData.infraction.porecdureEnCoursDate = null;
  formData.infraction.porecdureEnCoursAppeal = null;
  formData.infraction.porecdureEnCoursDetailsEntite = null;
  formData.infraction.porecdureEnCoursCirconstancesAggravante = null;
};
