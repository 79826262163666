import React from "react";
import Edit from "../components/Edit";
import EditAside from "../components/EditAside";
import PlanchersForm from "./form/PlanchersForm";

import PostEditActions from "../components/custom/PostEditActions";
import { ErrorBoundary } from "../lib/error-report";

const EditTitle = ({ record }) => (
  <span>Plancher {record ? `${record.nom || ""}` : ""}</span>
);

export default (props) => (
  <ErrorBoundary>
    <Edit
      title={<EditTitle />}
      actions={<PostEditActions />}
      aside={<EditAside />}
      {...props}
    >
      <PlanchersForm />
    </Edit>
  </ErrorBoundary>
);
