import React from "react";
import { Typography, Box } from "@material-ui/core";
import Files from "../../components/File";

export default ({ disabled }) => {
  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        CV
        <Files isArray={false} source={"cv"} disabled={disabled} />
      </Typography>
      <Typography variant="h6" gutterBottom>
        Pièces d'identité
        <Files isArray source={"pieceIdentite"} disabled={disabled} />
      </Typography>
    </Box>
  );
};
