import React from "react";
import { useField } from "react-final-form";
import TextField from "@material-ui/core/TextField";

/**
 * Input for the login and forgot password forms
 * Which, inspite of my best efforts, seem to must
 * live outside of the react-admin form management system.
 * -- sigh --
 */
const Input = React.forwardRef((props, ref) => {
  const { input, meta } = useField(props.name);

  const style = {
    width: "100%",
    ...props.style,
  };

  return (
    <TextField
      InputLabelProps={{
        shrink: true,
      }}
      {...props}
      {...input}
      style={style}
      ref={ref}
      error={!!meta.error}
      helperText={meta.error || props.helperText}
    />
  );
});

export default Input;
