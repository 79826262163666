export default {
  Candidat: "Candidate",
  "Type de candidat": "Candidate type",
  Interne: "Internal",
  Externe: "External",
  Identité: "Identity",
  Groupe: "Group",
  Civilité: "Title",
  Prénom: "First name",
  Général: "General",
  Nom: "Name",
  "Nom d’usage": "Usual name",
  "Autres prénoms": "Other first names",
  Monsieur: "M",
  Madame: "Ms",
  Mix: "Mx",
  "Non Spécifié": "Not Specified",
  Nationalités: "Nationality",
  "Père (nom et prénom)": "Father (last and first name)",
  "Mère (nom et prénom)": "Mother (last and first name)",
  "Date et Lieu de naissance": "Date and place of birth",
  "Date de naissance": "Birth date",
  "Pays de naissance": "Birth country",
  "Ville de naissance": "Birth city",
  "Code postal": "Postal code",
  "Carte d'identité / passeport": "ID / Passport",
  "Date d’expiration": "Expiration date",
  "Pays d’émission": "Issuing country",
  Numéro: "Number",
  Autres: "Others",
  Téléphone: "Phone number",
  "Lieu de résidence actuel": "Current Residence",
  "Lieu de résidence principal": "Permanent Residence",
  "Adresse électronique": "Email address",
  "Niveau de connaissance du français": "Level of knowledge in French language",
  Adresse: "Address",
  "Code postal et ville": "Postal code and city",
  Pays: "Country",
  "Date d’arrivée à cette adresse": "Start date of residence at this address",
  Oui: "Yes",
  Non: "No",
  Aucune: "None",
  Aucun: "None",
  // ——————————————————————————
  // INFRACTION
  // ——————————————————————————
  Infractions: "Reputation",
  "▸ Avez-vous déjà fait l’objet d’une condamnation, y compris une condamnation en appel, dans le cadre d’une procédure pénale, ou bien d’une procédure civile ou administrative (ayant eu une incidence sur votre honorabilité ou une incidence significative sur votre situation financière) ?":
    "▸ Do you have any previous convictions in criminal proceedings or relevant (i.e. where there is an impact on the reputation or significant impact on the financial soundness of the appointee) civil or administrative proceedings, including convictions under appeal?",
  "▸ Faites-vous l’objet d’une procédure pénale ou d’une procédure civile ou administrative en cours (y compris tout avis officiel d’enquête ou de mise en accusation) ?":
    "▸ Do you have any pending criminal proceedings or relevant civil or administrative proceedings (including any formal notification of investigation or committal for trial)",
  "▸ Des mesures disciplinaires ont-elles déjà été prises contre vous ou sont-elles en instance vous concernant (y compris la révocation en tant que membre de l’organe de direction d’une société, le licenciement pour faute professionnelle, l’interdiction de gérer...) ?":
    "▸ Do you have any previous disciplinary measures or pending disciplinary actions (including disqualification as a company director, discharge from a position or trust)?",
  "▸ Avez-vous fait ou faites-vous l’objet d’une procédure de faillite ou d’insolvabilité (mesure de redressement ou de liquidation judiciaire) ou d’une procédure similaire ?":
    "▸ Do you have any previous or pending bankruptcy, insolvency or similar procedures?",
  "▸ Avez-vous déjà figuré sur une liste de débiteurs insolvables, faites-vous l’objet d’une inscription négative dans une liste établie par une agence de crédit reconnue ou avez-vous fait l’objet d’une mesure d’exécution en lien avec une dette ?":
    "▸ Have you ever been included in a list of unreliable debtors or do you have a negative record on a list established by a recognised credit bureau or have you received an enforcement measure for any such debt?",
  "▸ Avez-vous déjà fait l’objet d’un refus, d’un retrait, d’une révocation, d’une interdiction de gérer ou d’une annulation d’enregistrement, d’autorisation, d’adhésion ou de licence concernant l’exercice d’une activité commerciale ou professionnelle ? Une telle procédure est-elle en cours ?":
    "▸ Have you ever been the subject of a refusal of registration, authorisation, membership or licence to carry out a trade, business or profession, or have you had such withdrawn, revoked or terminated?",
  "▸ Avez-vous jamais été sanctionné(e) par des autorités publiques ou des organisations professionnelles, ou des enquêtes ou procédures exécutoires sont-elles en cours ou ont-elles déjà été engagées contre vous ? En particulier, avez-vous fait l’objet d’une mesure de suspension ou d’exclusion d’une organisation professionnelle en France ou à l’étranger, ou d’une sanction prononcée par un organe central ?":
    "▸ Have you ever been sanctioned by any public authorities or professional bodies or are you the subject of any pending investigations or past investigations or enforcement proceedings?",
  "▸ L’organe de direction de l’entité soumise à la surveillance prudentielle a-t-il débattu de points particuliers en ce qui concerne des aspects de votre honorabilité ?":
    "▸ Has the management body of the supervised entity engaged in any specific deliberations regarding any aspects of your reputation?",
  "Veuillez apporter des précisions :": "Please clarify:",
  "Veuillez fournir les informations suivantes :":
    "Please provide the following informations:",
  "Veuillez fournir des détails complémentaires :":
    "Please provide complementary details",
  "Toute autre information jugée utile par la personne nommée (renouvelée) ou l’entité soumise à la surveillance prudentielle, aux fins de l’évaluation, et n'apparaissant pas dans ce formulaire, doit être ajoutée ici :":
    "If there is any other information the appointee or the supervised entity considers to be relevant to the assessment, it must be included here",
  // —————————————————————————————
  // Diplomes
  // —————————————————————————————
  Diplômes: "Degrees",
  Diplôme: "Degree",
  "Date d’obtention": "Date of graduation",
  "Domaine d’étude": "Field of Study",
  "Type d’établissement": "Educational Organisation",
  // —————————————————————————————
  // Expérience
  // —————————————————————————————
  Expériences: "Experience",
  Expérience: "Experience",
  "Fonction/Mandat": "Position",
  "Responsabilités principales": "Main responsibilities",
  "Organisation, entreprise, etc.": "Organisation, company, etc.",
  "Rang/niveau hiérarchique du poste":
    "Degree of seniority of the position / hierarchical level",
  senior: "Senior level",
  high: "High level",
  managerial: "Other managerial",
  other: "Other",
  Taille: "Size",
  "Nombre d’employés placés sous sa responsabilité": "Number of subordinates",
  "Domaines d’expertise et principales responsabilités": "Areas covered",
  "Motif de la fin du contrat/mandat": "Reason for termination",
  "La personne nommée atteint-elle les seuils ?":
    "Does the appointee meet the presumption of adequate experience?",
  "Veuillez répertorier ci-dessous tout facteur complémentaire (ou compensatoire), comme le prévoit le Guide relatif à l’évaluation de l’honorabilité, des connaissances, des compétences et de l’expérience.":
    "Please list below any potential complementary (or compensating) factors as provided for in the Guide to fit and proper assessments",
  //——————————————————————————————
  "Expériences dans le domaine bancaire/financier :":
    "Practical experience related to banking/financial field",
  "Autres expériences pertinentes à un poste de « cadre dirigeant » hors du secteur financier :":
    "Other relevant experience in senior management positions outside financial sector",
  "Autres expériences pertinentes hors du secteur financier (ex. : milieu universitaire, services juridiques, informatique, ingénierie, ressources humaines, mandat électif, autres fonctions, etc.) :":
    "Other relevant experience outside financial sector (e.g. academic positions, legal services, IT, engineering, HR, political mandates, other non-commercial mandates)",
  "Autres expériences spécifiques":
    "Additional relevant information regarding: level of experience; nature of management position held and its hierarchical level; nature and complexity of the business where the position was held, including its organisational structure; scope of competencies, decision-making powers and responsibilities, and number of subordinates",
  // —————————————————————————————
  // Validation & Refus
  // —————————————————————————————
  "Validation & Refus": "Approvals and non-approvals",
  "Autorité compétente concernée": "Competent Authority involved",
  "Établissement concerné": "Institution involved",
  "Fonction concernée": "Function involved",
  "Date de l'évaluation": "Date of assessment",
  "Conclusion de l'évaluation": "Conclusion of the assessment",
  "Le cas échéant, veuillez indiquer les raisons du ou des refus de validation déclaré(s)":
    "Please explain the reasons for the non-approvals indicated above, if any",
  "Validation / refus de validation antérieure par des autorités compétentes bancaires ou financières en France ou financières en France ou à l’étranger (hors ACPR ou BCE)":
    "Previous regulatory approvals/non-approvals in the financial sector (except ACPR or BCE)",
  // —————————————————————————————
  // DESCRIPTION
  // —————————————————————————————
  "S’agit-il d’un renouvellement de poste ?": "Is the appointment a renewal?",
  "La personne nommée remplace-t-elle quelqu’un d’autre ?":
    "Is the person to be appointed replacing somebody else?",
  "La demande est-elle effectuée dans les délais requis ?":
    "Is the appointment/notification being submitted on time in accordance with national rules?",
  "Pourquoi ?": "Why?",
  "Qui et pourquoi ?": "Who and why?",
  "En vertu de la législation nationale sur la limitation du cumul des mandats, l’entité soumise à la surveillance prudentielle dépasse-t-elle le seuil fixé à l’article R. 511-17 ou R. 533-18 du Code monétaire et financier ?":
    "Is the supervised entity CRD IV significant according to national legislation?",
  "Nature du contrat entre la personne nommée (renouvelée) et l’entité soumise à la surveillance prudentielle :":
    "Nature of the arrangement between the appointee and the applicant",
  Salarié: "Employee",
  "Mandat social": "Directorship",
  "Convention de mise à disposition": "Contract for services",
  "Autre - Veuillez préciser": "Other",
  "Si le dirigeant effectif n’a pas la qualité de mandataire social :":
    "If the executive manager is not an executive director:",
  "Quel est l’organe ou la personne qui vous a désigné à vos (nouvelles) fonctions ?":
    "Which board or person appointed you?",
  "Quels sont les pouvoirs qui vous sont délégués ?":
    "What is the scope of delegation you have been entrusted with?",
  "Domaines placés sous la responsabilité hiérarchique et/ou fonctionnelle du dirigeant effectif :":
    "What are the areas of activity under your responsibility?",
  "À quel(s) comité(s) participez-vous ? (Exemples : comité exécutif, comité des risques…)":
    "What committees, if any, are you involved in related to your executive function (e.g. risk committee, executive committee, etc.)?",
  "Quelles sont les mesures existantes pour assurer la continuité de direction en toute circonstance (notamment en cas d’absence ou d’empêchement temporaire ou définitif du/des autre(s) dirigeant(s)) ?":
    " What are the existing measures to make sure that the continuity of the management is implemented in any event (in particular in case of temporary/definitive absence or impeachment of the other executive manager)?",
  // FONCTIONS
  Fonctions: "Functions",
  "Veuillez fournir une description détaillée des missions, des responsabilités et des liens hiérarchiques associés à la fonction. Veuillez indiquer quelles autres fonctions la personne nommée / personne à nommer exercera éventuellement au sein de l’entité soumise à la surveillance prudentielle.":
    "Provide a detailed description of the duties, responsibilities and reporting lines of the function. Please specify which other functions, if any, the appointee will exercise within the supervised entity",
  "Veuillez donner une description la plus détaillée possible des principales responsabilités et missions de la fonction concernée ainsi que l’effectif placé sous sa responsabilité.":
    "Provide as detailed a description as possible of the main duties, responsibilities and number of subordinates relating to the function in question",
  "Veuillez indiquer quelle(s) autre(s) fonction(s) la personne nommée (renouvelée) exercera éventuellement au sein de l’entité soumise à la surveillance prudentielle :":
    "Specify which other functions, if any, the person involved will exercise within the supervised entity",
  "Le cas échant, précisez également à quel(s) comité(s) de l’organe de direction ou à quel(s) autre(s) comité(s) (de gestion) la personne concernée participera :":
    "Specify on which subcommittees of the management body or other (management) committees the appointee will sit, if applicable",
  "▸ Une fonction non exécutive supplémentaire au sein de l’organe de direction a-t-elle été autorisée par une autorité compétente ?":
    "▸ Has an additional non-executive directorship been authorised?",
  "Liste des fonctions exécutives et non exécutives et des autres activités professionnelles.":
    "List of executive and non-executive directorships and other professional activities. Please list the directorship for which this form is being completed first and then all other directorships and other professional activities held by the appointee",
  // FONCTION OU MANDAT
  "Décompte privilégié": "Privileged counting",
  "Informations complémentaires, commentaires":
    "Additional information and comments",
  Entité: "Entity",
  "Entité côtée": "Listed Entity",
  "Entité Cotée": "Listed Entity",
  "Nom entité": "Name of entity",
  "Forme Juridique": "Legal form",
  "Groupe d’appartenance": "Group or the “consolidating entity”",
  "Structure de gouvernance": "Governance model",
  "L’entité soumise à la surveillance prudentielle est-elle un « établissement important au titre de la CRD » conformément au droit national ?":
    "Is the supervised entity a “CRD significant institution” in accordance with national law?",
  "Sélectionnez l’entité dans laquelle la fonction ou le mandat est exercé.":
    "Pick the entity in of the position",
  "Apposer une *, si la société est côtée": "Mark listed companies with an *",
  "Groupe d’appartenance (mentionner le % de détention du capital et/ou de droits de vote dans l’entité)":
    "Group or the “consolidating entity” (percentage of the capital and voting rights)",
  "Description de l’activité de l’entité":
    "Description of the entity's activity",
  "Taille de l'entité (total de bilan social ou consolidé)": "Size of entity",
  "Taille de l’entité": "Size of entity",
  "Date de nomination ou d’élection": "Date of appointment or election",
  "Début du mandat": "Starting date of mandat",
  "Début (prévu) du mandat": "(Planned) start date of the appointment",
  indéterminée: "unspecified date",
  "Fin du mandat": "End date of the appointment",
  "Le mandat ne doit être échu": "The mandate must not have ended",
  Fonction: "Position",
  "Intitulé de la fonction": "Name of the position",
  "Type de fonction": "Position type",
  "Membre Indépendant": "Independent Member",
  "Nombre de réunions par an": "Number of meetings per year",
  "Nombre de jours par an": "Days per year",
  "jour(s) par an": "day(s) per year",
  "réunion(s) par an": "meetings(s) per year",
  "incluant les responsabilités supplémentaires":
    "including additional responsiblities",
  "Responsabilités Supplémentaires": "Additional responsibilities",
  "Responsabilités Supplementaires (appartenance à des comités, etc.) :":
    "Additional responsibilities such as membership of committees, chair functions, etc.",
  "Indiquez le décompte privilégié, ou son absence, et mentionnez-en la raison":
    "Indicate privileged counting, or its absence, and the reason for it",
  "Temps consacré ": "Time commitment",
  et: "and",
  "jours de formation": "days of training",
  "▸ Concernant l’ensemble des fonctions, incluant la fonction ou le mandat faisant l’objet de la demande :":
    "▸ Regarding all functions, including the function for which the application is submitted",
  "Total des jours consacrés par an :": "Total days committed per year",
  "Total des heures consacrées par semaine :": "Total hours committed per week",
  "▸ Concernant la fonction ou le mandat faisant l’objet de la demande :":
    "▸ Regarding the function for which the application is submitted",
  "▸ Concernant l’ensemble des fonctions ou mandats, excepté la fonction faisant l’objet de la demande :":
    "▸ Regarding all functions except the function for which the application is submitted",
  "▸ Concernant l’ensemble des fonctions de direction d’un niveau spérieur à la fonction faisant l’objet de la demande :":
    "▸ Regarding all functions over and above the function for which this questionnaire is being completed:",
  "Consacrés à l’ensemble des fonctions": "Commited to all functions",
  "Consacrés aux seules fonctions non-executives":
    "Commited only to non-executive functions",
  "▸ Est-ce que l’un des mandats mentionnés ci-dessus se trouve dans un établissement de crédit, une entreprise d’investissement ou une société de financement établi(e) en France, dont le total de bilan, social ou consolidé, est supérieur, depuis 2 exercices consécutifs, à 15 milliards d’euros ?":
    "▸ Is one of the directorships mentioned in the above table in a credit institution, an investment firm or a finance company established in France, whose balance sheet total, social or consolidated, has been higher, for two consecutive years, than 15 billion euros?",
  "Mentionner l’entité (les entités) en question :": "List those entities:",
  "▸ Veuillez indiquer l’existence éventuelle de synergies entre les différents postes occupés dans les entreprises concernées, et d’économies d’échelle en termes de temps consacré à ces différents postes :":
    "▸ If privileged counting applies please provide details of whether any synergies exist between the companies, such that there is a legitimate overlap in terms of time commitment within those companies:",
  "▸ Nombre total de mandats exécutifs après application du décompte privilégié et des exceptions :":
    "▸ Total number of executive directorships if privileged counting and exceptions (no counting) are applied",
  "▸ Nombre total de mandats non exécutifs après application du décompte privilégié et des exceptions :":
    "▸ Total number of non-executive directorships if privileged counting and exceptions (no counting) are applied",
  // EXPERIENCE
  "Pour apprécier le niveau d’expérience de la personne nommée (renouvelée) il sera tenu compte des critères suivants, pour lesquels il convient d’apporter toutes les précisions nécessaires :":
    "In order to appreciate the appointee’s level experience, the following criteria will be taken into account :",
  "la nature du poste à responsabilité et le niveau hiérarchique correspondant;":
    "nature of management position held and its hierarchical level",
  "la nature et la complexité de l’entreprise, y compris en raison de sa structure organisationnelle;":
    "nature and complexity of the business where the position was held",
  "l’étendue des compétences, pouvoirs décisionnaires et responsabilités, et le nombre de subordonnés.":
    "scope of competencies, decision-making powers and responsibilities, and number of subordonates",
  "Vous pouvez ajouter à ces différents égards, des informations complémentaires :":
    "Regarding those, you can add additional relevant information:",
  "Si la personne nommée ne dispose pas de l’expérience nécessaire, veuillez indiquer tout autre facteur à prendre en considération qui serait de nature à compenser le cas échéant le manque d’expérience requise :":
    'If the appointee does not have relevant experience, list any potential compensating factors (e.g. size of entity; other compensating experience; degree/academic experience; proven ability to challenge; overall suitability; specialised knowledge; limited appointment in terms of the role of the appointment; other special cases e.g. "legal requirement to appoint staff representatives within the management body")',
  // -----------------
  "Domaines requis d’expérience générale dans le secteur bancaire et financier.":
    "Assessment of banking experience as enumerated in EBA/GL/2017/12",
  "Évaluation au regard, notamment, de la durée de l’expérience acquise, de la taille, de la complexité de l’entreprise au sein de laquelle cette expérience a été acquise :":
    "",
  "Marchés bancaires ou financiers :": "Financial Markets",
  "Exigences légales et réglementaires applicables à un établissement assujetti :":
    "Regulatory framework and requirements",
  "Lutte contre le blanchiment de capitaux et le financement du terrorisme :":
    "Prevention of money laundering and terrorist financing",
  "Planification stratégique et compréhension de la stratégie commerciale ou du projet d’affaires (business plan) d’un établissement assujetti et de sa mise en œuvre :":
    "Strategic planning and understanding of a credit institution's business strategy or business plan and accomplishment thereof",
  "Gestion des risques (identification, évaluation, suivi, contrôle et atténuation des principaux types de risques d’un établissement assujetti) :":
    "Risk management (identifying, assessing, monitoring, controlling and mitigating the main types of risk of a credit institution, including the responsibilities of the member)",
  "Evaluation de l’efficacité des dispositifs de gouvernance d’un établissement assujetti, mise en place de mécanismes de surveillance et de contrôle efficaces de celle-ci :":
    "Assessing the effectiveness of a credit institution's arrangements, creating effective governance, oversight and controls",
  "Comptabilité et Audit": "Accounting and Auditing",
  "Connaissances et expérience en matière de risques liés au climat et à l’environnement :":
    "Knowledge and experience of climate-related and environmental risks",
  "Information comptable et financière d’un établissement assujetti, identification des problèmes majeurs révélés par cette information et mise en place des mesures et contrôles appropriés :":
    "Interpreting a credit institution's financial information, identifying key issues based on this information and appropriate controls and measures",
  // ——————————————————————————————————————————
  // FORMATION
  // ——————————————————————————————————————————
  Formation: "Training",
  "La personne nommée / personne à nommer a-t-elle suivi une formation pertinente au cours des cinq dernières années ?":
    "Has the appointee undertaken any relevant training in the last five years?",
  "Avant d’entrer en fonctions ou pendant la première année suivant son entrée en fonctions, la personne nommée (renouvelée) a-t-elle suivi/suivra-t-elle une formation particulière ?":
    "Will the appointee follow specific training prior to the assumption of the function or within in the first year?",
  "Contenu de la formation": "Content of training",
  "Formation dispensée par": "Training provided by",
  "Nombre de jours": "Number of days",
  "Aucune Formation": "No Training",
  "Date de début": "Start date",
  "Date de fin": "End date",
  // ——————————————————————————————————————————
  // CONFLITS D INTERET
  // ——————————————————————————————————————————
  "Conflits d’Intérêts": "Conflicts of interests",
  "Avez-vous été au cours des dix dernières années ou êtes-vous un actionnaire significatif, un associé en nom ou un associé commandité d’une autre entreprise ?":
    "Have you been a qualifying shareholder, a partner in name or a general partner of another company for the last ten years?",
  "▸ Un lien personnel existe-t-il entre vous et :":
    "▸ Do you have any personal relationship with:",
  "d’autres membres de l’organe de direction et/ou des titulaires de postes clés au sein de l’entité soumise à la surveillance prudentielle, sa société mère ou ses filiales ?":
    "other members of the management body and/or key function holders of the supervised entity, the parentundertaking or its subsidiaries",
  "une personne détenant une participation qualifiée au sein de l’entité soumise à la surveillance prudentielle, sa société mère ou ses filiales ?":
    "a person that has a qualifying holding in the supervised entity, the parent undertaking or its subsidiaries?",
  "Êtes-vous en relations d’affaires ou comptez-vous l’être (à titre privé ou professionnel ou par l’intermédiaire d’une société) avec l’entité soumise à la surveillance prudentielle, sa société mère ou ses filiales ?":
    "Do you conduct business with the supervised entity, the parent undertaking or its subsidiaries (in private or through a company)? The day-to-day operations with the supervised entity, concluded at arm's length, need not be declared",
  "Êtes-vous actuellement impliqué(e) dans des procédures judiciaires engagées contre l’entité soumise à la surveillance prudentielle, sa société mère ou ses filiales, que ce soit directement ou indirectement ?":
    "Are you currently involved in any legal proceedings against the supervised entity, the parent undertaking or its subsidiaries, either directly or indirectly?",
  "▸ Entretenez-vous ou avez-vous entretenu au cours des deux dernières années un lien professionnel ou commercial avec :":
    "▸ Do you have any professional or commercial relationship or have you had such relationship over the past two years with:",
  "l’entité soumise à la surveillance prudentielle, sa société mère ou ses filiales ?":
    "the supervised entity, the parentundertaking or its subsidiaries",
  "des concurrents de l’entité soumise à la surveillance prudentielle, de sa société mère ou de ses filiales ?":
    "competitors of the supervised entity, the parent undertaking or its subsidiaries?",
  "▸ Avez-vous une participation financière (en tant que propriétaire ou investisseur, par exemple) dans :":
    "▸ Do you personally or as a board member have any business, professional or commercial relationship or have you had such a relationship in the past two years with any of the following:",

  "l’entité soumise à la surveillance prudentielle, la société mère ou leurs filiales ?":
    "the supervised entity, the parent undertaking or their subsidiaries",
  "des clients, des fournisseurs ou des concurrents de l’entité soumise à la surveillance prudentielle, de la société mère ou des filiales de l’entité soumise à la surveillance prudentielle ?":
    "clients, suppliers or competitors of the supervised entity, the parent undertaking or the supervised entity’s subsidiaries",
  "▸ Avez-vous, aussi bien personnellement que par l’intermédiaire d’une entreprise avec laquelle vous êtes étroitement lié(e), un intérêt financier significatif (en tant que propriétaire ou investisseur par exemple) dans l’entité soumise à la surveillance prudentielle, sa société mère ou ses filiales ou dans des concurrents ou clients de l’entité soumise à la surveillance prudentielle, de sa société mère ou de ses filiales ?":
    "▸ Do you, either personally or through a company you are closely connected with, have any substantial financial interest (such as ownership or investment) in the supervised entity, the parent undertaking or its subsidiaries, or in competitors or clients of the supervised entity, the parent undertaking or its subsidiaries?",
  "▸ Représentez-vous, de quelque façon que ce soit, un actionnaire de l’entité soumise à la surveillance prudentielle, de sa société mère ou de ses filiales ?":
    "▸ Do you, in any way, represent a shareholder of the supervised entity, the parent undertaking or its subsidiaries?",
  "▸ Avez-vous actuellement des obligations financières significatives envers l’entité soumise à la surveillance prudentielle, sa société mère ou ses filiales ?":
    "▸ Do you have any substantial financial obligation to the supervised entity, the parent undertaking or its subsidiaries?",
  "NB : par principe, les prêts consentis aux conditions normales du marché d’un montant inférieur à 200 000 euros et les prêts immobiliers garantis consentis aux conditions normales du marché, ne sont pas considérés comme significatifs.":
    "NB: in principle, performing loans negotiated at arm's length under €200,000 and performing private mortgages negotiated at arm's length would not be considered substantial",
  "▸ Occupez-vous ou avez-vous occupé au cours des deux dernières années un poste à forte influence politique (au plan national ou local) ?":
    "▸ Do you have or have you had over the past two years a position with high political influence (either nationally or locally)?",
  "▸ Êtes-vous lié par certains engagements pris au titre de fonctions précédemment exercées en France ou à l’étranger (clause de non concurrence, par exemple) ?":
    "▸ Are you bound by certain commitments taken in relation to your previous functions exercised in France or abroad (non competition clause, for instance)?",
  "▸ Avez-vous d’autres liens, engagements ou occupez-vous d’autres postes que ceux couverts dans les questions ci-dessus et qui pourraient nuire aux intérêts de l’entité soumise à la surveillance prudentielle ?":
    "▸ Do you have any other relationships, positions or involvement that are not addressed in the questions above, which could adversely affect the interests of thesupervised entity?",
  "▸ Si besoin, indiquez comment prévenir et/ou encadrer un éventuel conflit d’intérêts tel qu’il ressort des déclarations ci-dessus de la personne nommée (renouvelée), que celui-ci soit considéré comme significatif ou non. À cet égard, veuillez joindre tout document utile (statuts, règlement intérieur, procédures, etc.).":
    "▸ If any of the above apply, please indicate how a potential conflict of interest resulting from the appointee's statements above, whether or not it is considered material, is proposed tobe mitigated?",
  "Nom de l'entreprise": "Name of the company",
  "Nature de ses activités": "Nature of its activities",
  "Principales activités de l’entreprise": "Main activities",
  "Niveau de participation (en % du capital et des droits de vote) :":
    "Percentage of the capital and voting rights or value of investment",
  "Période concernée": "Relevant period of this relationship",

  "Période d’existence du lien": "Relevant period of this relationship",
  "Liens entre ces entreprises et l’entité soumise à la surveillance prudentielle, sa société mère et ses filiales :":
    "Relationship between the company and the supervised entity, its parent undertaking or its subsidiaries:",
  "Lien entre l’entreprise et l’entité soumise à la surveillance prudentielle :":
    "Relationship between the entity and the supervised entity",
  "Quantification de l’intérêt financier (% du capital et des droits de vote; ou valeur d’investissement)":
    "Percentage of the capital and voting rights or value of investment",
  "une description du type et de la teneur des opérations concernées et des obligations des deux parties;":
    "a description of the type and content of the business and the obligations of both parties;",
  "le cas échéant, le nom de l’entreprise;":
    "if relevant, the name of the company;",
  "la période durant laquelle ce lien a existé":
    "the relevant period of this relationship",
  "Veuillez fournir des informations sur la teneur et l’état d’avancement des procédures judiciaires ainsi que sur l’entité concernée :":
    "Please provide information on the content and status of the legal proceedings and the entity involved:",
  "Ce lien peut être direct ou au travers d’une structure juridique distincte.":
    "This link can be direct or through a company.",
  "Veuillez fournir des détails complémentaires (en cas de lien commercial, indiquez la valeur (financière) que celui-ci représente pour l’activité de la personne nommée (renouvelée) ou de ses relations personnelles ou professionnelles proches) :":
    "Please provide details as to what (financial) value it represents to the business of the member or his/her close personal or business relationships",
  "Veuillez fournir toutes les informations suivantes relatives à ce lien financier :":
    "Please provide the following information about this financial relationship:",
  "Nom de l'actionnaire": "Name of the shareholder",
  "% de participation": "% participation (in % of capital and voting rights)",
  "Nature de la représentation": "Nature of the representation",
  "Type de l’obligation": "Type of obligation",
  "Valeur de l’obligation": "Value of obligation",
  "Période d’existence de l’obligation": "Relevant period of the obligation",
  "Nature du poste": "Nature of the position",
  "Pouvoirs spécifiques ou obligations liés à ce poste":
    "Specific powers related to or the obligations of this position",
  "Lien entre ce poste (ou l’entité où ce poste est occupé) et l’entité soumise à la surveillance prudentielle, sa société mère ou ses filiales":
    "Relationship between the position (or the entity where the position is held) and the supervised entity, the parent undertaking or its subsidiaries",
  "Veuillez fournir toutes les informations nécessaires (notamment relatives à la nature, la teneur, la période et, le cas échéant, au lien avec l’entité soumise à la surveillance prudentielle, sa société mère ou ses filiales) :":
    "Please provide all necessary information (e.g. nature, content, period and, if relevant, the relation/relationship with the supervised entity, the parent undertaking or its subsidiaries):",
  "▸ Êtes-vous soumis(e) à une période de carence (au titre d’un accord ou conformément à la législation) ?":
    "▸ Are you subject to any cooling-off period (either under an agreement or under the law)?",
  "Avez-vous des relations et participations ou occupez-vous des postes qui n’ont pas été couvert(e)s dans les questions ci- dessus et qui pourraient nuire aux intérêts de l’entité soumise à la surveillance prudentielle ?":
    "Do you have any other relationships, positions or involvement that are not covered in the questions above and could affect the interests of the supervised entity?",
  // ————————————————————————————————
  // ORGANE DE DIRECTION
  // ————————————————————————————————
  "Organe de direction": "Collective suitability",
  "▸ L’entité soumise à la surveillance prudentielle doit-elle respecter des exigences nationales en matière de diversité des genres ?":
    "▸ Is the supervised entity subject to national requirements on gender diversity?",
  "L’entité soumise à la surveillance prudentielle respecte-t-elle les exigences nationales en matière de diversité des genres avec cette nomination ?":
    "If “Yes” is selected, in making this appointment, is the supervised entity compliant with the national requirements on gender diversity?",
  "▸ Avec cette nomination, l’entité soumise à la surveillance prudentielle respecte-t-elle ses autres principes internes en matière de diversité ?":
    "▸ In making this appointment, is the supervised entity compliant with its internal principles for other aspects of diversity?",
  "▸ Avec cette nomination, l’entité soumise à la surveillance prudentielle atteint-elle ses objectifs ou respecte-t-elle ses règles internes en matière de diversité des genres au sein de l’organe de direction ?":
    "▸ In making this appointment, is the supervised entity compliant with its internal target or rules for gender diversity in the composition of the management body?",
  "Veuillez expliquer en quoi la personne proposée complète la compétence collective de celle-ci en vous référant, le cas échéant, au résultat de la plus récente auto-évaluation de la compétence collective de l’organe de direction :":
    "▸ Explain why the proposed appointment complements the supervised entity's collective suitability and refer where relevant to the outcome of the most recent self-assessment of the collective suitability of the management body",
  "▸ Expliquez de façon générale les faiblesses éventuellement identifiées dans la composition d’ensemble de l’organe de direction :":
    "▸ Explain in general the weaknesses that have been identified in the overall composition of the management body",
  "▸ Comment la personne nommée contribuera-t-elle à remédier à tout ou partie des éventuelles faiblesses évoquées dans votre réponse à la question précédente ?":
    "▸ How will the appointee help to solve some or all of the weaknesses referred to in your answer to the previous question?",
  "▸ Liste des personnes composant la direction effective de l’établissement (dans le cas où le mandat pour lequel la présente déclaration est réalisée, est de nature exécutive)":
    "▸ List of persons of the management body in its executive functions of the institution (in the case where the mandate for which this declaration is made is of an executive nature)",
  OU: "OR",
  "Liste des personnes composant l’organe social de l’établissement (dans le cas où le mandat pour lequel la présente déclaration est réalisée, est de nature non exécutive)":
    "List of persons of the management body in its supervisory functions (in the case where the mandate for which this declaration is of a non-executive nature",
  "Prénom, Nom": "First name, last name",
  Attributions: "Attributions",
  "Date de première nomination": "Date of appointment",
  "Dates de renouvellement": "Dates of renewal",
  "Durée du mandat en cours": "Current term of office",
  "Formation initiale et continue": "Initial and professional training",
  "Expérience (bancaire/non bancaire en précisant la durée)": "Experience",
  "Compétences / principaux domaines d’expertise":
    "Skills/main area of expertise",
};
