import React, { useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";

import FormTabCustom from "../../components/custom/FormTabCustom";
import TabbedFormCustom from "../../components/custom/TabbedFormCustom";

import Typography from "@material-ui/core/Typography";

import { LangueProvider } from "./../transalations";

import CandidatureConflitsInterets from "./CandidatureConflitsInterets";
import CandidatureDescription from "./CandidatureDescription";
import CandidatureExperience from "./CandidatureExperience";
import CandidatureFonctions from "./CandidatureFonctions/CandidatureFonctions";
import CandidatureFormation from "./CandidatureFormation";
import CandidatureGeneral from "./CandidatureGeneral";
import CandidatureOrganeDirection from "./CandidatureOrganeDirection";
import CandidatureAnnexes from "./CandidatureAnnexes";

const useStyles = makeStyles({
  root: {
    position: "relative",
    maxWidth: "60em",
  },
});

function RecordGetter(props) {
  if (props.recordRef) {
    props.recordRef.current = props.record;
  }

  return null;
}

function CandidatureForm({ isPrint, recordId, ...props }) {
  const classes = useStyles();
  const mounted = useRef(false);
  const langue = props.langue || props.record.langue;

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      // Do onMount things
    }
  }, []);

  return (
    <LangueProvider langue={langue}>
      {isPrint && (
        <Typography variant="h4" gutterBottom>
          Candidature
        </Typography>
      )}

      <TabbedFormCustom {...props} recordId={recordId} submitOnEnter={false}>
        <FormTabCustom
          label="Général"
          contentClassName={classes.root}
          isPrint={isPrint}
        >
          <RecordGetter {...props} />
          <CandidatureGeneral tabName="general" key="general-tab" />
        </FormTabCustom>

        <FormTabCustom
          label="Description"
          contentClassName={classes.root}
          isPrint={isPrint}
        >
          <CandidatureDescription tabName="description" key="description-tab" />
        </FormTabCustom>

        <FormTabCustom
          label="Fonctions"
          contentClassName={classes.root}
          isPrint={isPrint}
        >
          <CandidatureFonctions
            tabName="fonctions"
            candidatureId={props.record.id}
            key="fonctions-tab"
          />
        </FormTabCustom>

        <FormTabCustom
          label="Expérience"
          contentClassName={classes.root}
          isPrint={isPrint}
        >
          <CandidatureExperience tabName="experience" />
        </FormTabCustom>

        <FormTabCustom
          label="Formation"
          contentClassName={classes.root}
          isPrint={isPrint}
        >
          <CandidatureFormation tabName="formation" />
        </FormTabCustom>

        <FormTabCustom
          label="Conflits d’Intérêts"
          contentClassName={classes.root}
          isPrint={isPrint}
        >
          <CandidatureConflitsInterets tabName="conflitsinterets" />
        </FormTabCustom>

        <FormTabCustom
          label="Organe de direction"
          contentClassName={classes.root}
          isPrint={isPrint}
        >
          <CandidatureOrganeDirection tabName="organedirection" />
        </FormTabCustom>

        <FormTabCustom
          label="Annexes"
          contentClassName={classes.root}
          isPrint={isPrint}
        >
          <CandidatureAnnexes tabName="candidatureannexes" />
        </FormTabCustom>
      </TabbedFormCustom>
    </LangueProvider>
  );
}

export default CandidatureForm;
