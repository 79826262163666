import React from "react";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { Alert, AlertTitle } from "@material-ui/lab";

const Statuses = ["Transmis", "Abandonne", "Finalise"];

export default function StatusAlert({ version, status }) {
  return (
    <>
      {version !== "v2" && !Statuses.includes(status) && (
        <Box mt="1.75em">
          <Alert severity="warning" variant="filled">
            <AlertTitle>
              <strong>Le temps consacré aux fonctions doit être révisé</strong>
            </AlertTitle>
            <Typography variant="body1">
              Suite à une évolution des règles de calcul automatique du temps
              consacré aux fonctions et mandats des candidats, veuillez réviser
              les valeurs renseignées dans l’onglet fonctions.
              <br />
              <br />
              En enregistrant cette candidature, vous confirmez l’exactitude des
              valeurs renseignées.
            </Typography>
          </Alert>
        </Box>
      )}
    </>
  );
}
