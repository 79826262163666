import React from "react";
import Edit from "../components/Edit";
import ConfigForm from "./form/ConfigForm";

import PostEditActions from "../components/custom/PostEditActions";
import { ErrorBoundary } from "../lib/error-report";

const EditTitle = ({ record }) => (
  <span>Configuration {record ? record.nom : ""}</span>
);

export default (props) => (
  <ErrorBoundary>
    <Edit title={<EditTitle />} actions={<PostEditActions />} {...props}>
      <ConfigForm />
    </Edit>
  </ErrorBoundary>
);
