import React, { useState } from "react";
import TextInputCustom from "../../components/input/TextInputCustom";
import TranslatableTextInputCustom from "../../components/input/TranslatableTextInputCustom";
import DateInputCustom from "../../components/input/DateInputCustom";
import { Typography, Box, Button } from "@material-ui/core";
import { useForm } from "react-final-form";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import CloseIcon from "@material-ui/icons/RemoveCircleOutline";
import { buttonStyles } from "../../style/formStyles";

export default ({ tabName, disabled }) => {
  const buttonClasses = buttonStyles();

  const form = useForm();
  const formData = form.getState().values;

  const [showAdresse, setShowAdresse] = useState(
    !!formData?.candidatAdressePrincipale
  );

  const handleClick = (e) => {
    e.preventDefault();
    if (showAdresse) {
      formData.candidatAdressePrincipale = null;
    }
    setShowAdresse(!showAdresse);
  };

  return (
    <Box>
      {showAdresse && (
        <Box>
          <Typography variant="h6" gutterBottom>
            Lieu de résidence principal
          </Typography>
          <Box display="flex">
            <Box>
              <TextInputCustom
                disabled={disabled}
                label="Adresse"
                source="candidatAdressePrincipale.adresse"
                id={`${tabName}#candidatAdressePrincipale.adresse`}
              />
            </Box>
            <Box ml="1em">
              <TextInputCustom
                disabled={disabled}
                label="Ville"
                source="candidatAdressePrincipale.ville"
                id={`${tabName}#candidatAdressePrincipale.ville`}
              />
            </Box>
            <Box ml="1em">
              <TranslatableTextInputCustom
                disabled={disabled}
                label="Pays"
                source="candidatAdressePrincipale.pays"
                id={`${tabName}#candidatAdressePrincipale.pays`}
              />
            </Box>
          </Box>
          <Box>
            <Box>
              <DateInputCustom
                disabled={disabled}
                label="Date d'arrivée à cette adresse"
                source="candidatAdressePrincipale.dateArrivee"
                id={`${tabName}#candidatAdressePrincipale.dateArrivee`}
              />
            </Box>
          </Box>
          <Box>
            <Button
              disabled={disabled}
              className="button-add"
              size="small"
              onClick={handleClick}
            >
              <CloseIcon className={buttonClasses.leftIcon} />
              Supprimer
            </Button>
          </Box>
        </Box>
      )}

      {!showAdresse && (
        <Button
          className="button-add"
          size="small"
          disabled={disabled}
          onClick={handleClick}
        >
          <AddIcon className={buttonClasses.leftIcon} />
          Ajouter un lieu de résidence principal (si différent du lieu résidence
          actuel)
        </Button>
      )}
    </Box>
  );
};
