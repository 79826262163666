import React, { useState, useEffect } from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  SaveButton,
  Toolbar,
  showNotification,
} from "react-admin";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import Typography from "@material-ui/core/Typography";

import { ErrorBoundary } from "../lib/error-report";

import TextInputCustom from "../components/input/TextInputCustom";

const useStyles = makeStyles((/* theme */) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export function CreateReportModal({ open, onClose, formState, ...props }) {
  const classes = useStyles();
  const [formValues, setFormValues] = useState(formState.current);
  const c = formState.current;

  useEffect(() => {
    setFormValues(c);
  }, [c, open]);

  return (
    <Modal open={open} onClose={onClose} className={classes.modal}>
      <CreateBugReport {...props} formState={formValues} onCancel={onClose} />
    </Modal>
  );
}

function TheForm(props) {
  return (
    <>
      <Box width="100% !important">
        <Typography variant="h5" gutterBottom>
          Signaler un incident
        </Typography>
        <Typography variant="body1" gutterBottom>
          Avant de signaler tout incident, merci de vérifier qu’aucun champ, et
          qu’aucun onglet n’est rouge.
        </Typography>
        <Typography variant="body1">
          Veuillez saisir ci-desous toutes les étapes, et informations
          nécessaires pour reproduire le problème&nbsp;:
        </Typography>
      </Box>
      <TextInput source="formState" style={{ display: "none" }} validate={[]} />
      <TextInputCustom
        source="comment"
        multiline
        fullWidth
        label="Étapes pour reproduire le dysfonctionnement"
      />
    </>
  );
}

const useToolbarStyles = makeStyles({
  toolbar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

const TagCreateToolbar = ({ onCancel, translate, ...props }) => {
  const classes = useToolbarStyles();

  return (
    <Toolbar {...props} className={classes.toolbar}>
      <SaveButton />
      <Button onClick={onCancel}>Annuler</Button>
    </Toolbar>
  );
};

class CreateBugReport extends React.Component {
  constructor(props) {
    super(props);

    this.onSuccess = this.onSuccess.bind(this);
  }

  onSuccess() {
    const { onCancel } = this.props;
    onCancel();

    showNotification(
      "Nous prenons en compte vorte problème, et mettons tout en œuvre pour le résoudre"
    );
  }

  render() {
    const { formState, onCancel, ...props } = this.props;

    return (
      <ErrorBoundary>
        <Box width="34em">
          <Create
            {...props}
            basePath="/report"
            resource="bugreport"
            record={{ comment: "", formState: JSON.stringify(formState) }}
            onSuccess={this.onSuccess}
          >
            <SimpleForm
              toolbar={<TagCreateToolbar onCancel={onCancel} />}
              redirect={null}
            >
              <TheForm />
            </SimpleForm>
          </Create>
        </Box>
      </ErrorBoundary>
    );
  }
}

export default CreateBugReport;
