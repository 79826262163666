import decodeJwt from "jwt-decode";
import { fetchUtils } from "react-admin";

const { REACT_APP_API_URL } = process.env;

// https://marmelab.com/react-admin/doc/3.7/Authentication.html

const AUTH_LOGIN_URL = `${REACT_APP_API_URL}/login`;
const AUTH_PASSWORD_RECOVER = `${REACT_APP_API_URL}/forgot-password`;
const AUTH_PASSWORD_VALIDITY = `${REACT_APP_API_URL}/check-password-validity`;
const AUTH_TOKEN_KEY = "fp-token";
const AUTH_USER_ROLE = "fp-urole";
const AUTH_EXPIRE_TIME = "fp-token-expire";

export async function login(params) {
  const { email, password } = params;

  const request = new Request(AUTH_LOGIN_URL, {
    method: "POST",
    body: JSON.stringify({ email, password }),
    headers: new Headers({ "Content-Type": "application/json" }),
  });

  const response = await fetch(request);

  if (response.status < 200 || response.status >= 300) {
    let message;
    try {
      const responseBody = await response.json();

      message = responseBody.message;
    } catch (error) {
      // json parsing failed, ignore that
    }

    throw new Error(message);
  }

  const { token } = await response.json();
  const decodedToken = decodeJwt(token);

  sessionStorage.setItem(AUTH_TOKEN_KEY, token);
  sessionStorage.setItem(AUTH_USER_ROLE, decodedToken.user?.role);
  const expireTime = new Date();
  expireTime.setHours(expireTime.getHours() + 24);
  setExpireTime(expireTime);
}

export async function logout() {
  localStorage.clear();
  sessionStorage.clear();
}

export async function forgotPassword(email) {
  const request = new Request(AUTH_PASSWORD_RECOVER, {
    method: "POST",
    body: JSON.stringify({ email }),
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
    }),
  });

  const response = await fetch(request);

  if (response.status < 200 || response.status >= 300) {
    throw new Error();
  }

  // We don't need to use the body, but we
  // want to make sure the response is received before
  // we informe the used all went fine
  await response.text();
}

export async function checkAuth() {
  if (!sessionStorage.getItem(AUTH_TOKEN_KEY)) {
    throw new Error();
  }
}

export async function checkError(error) {
  if (error.status === 401 || error.status === 403) {
    sessionStorage.removeItem(AUTH_TOKEN_KEY);
    throw new Error();
  }
}

export function getPermissionsSync() {
  return sessionStorage.getItem(AUTH_USER_ROLE);
}

export async function getPermissions() {
  return getPermissionsSync();
}

/**
 * @returns {Date}
 */
export function getExpireTime() {
  const time = sessionStorage.getItem(AUTH_EXPIRE_TIME);

  return new Date(time);
}

/**
 * @param {Date}
 */
export function setExpireTime(time) {
  sessionStorage.setItem(AUTH_EXPIRE_TIME, time.toISOString());
}

export function httpClient(url, options = {}) {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }

  const token = sessionStorage.getItem(AUTH_TOKEN_KEY);

  options.headers.set("Authorization", `Bearer ${token}`);

  return fetchUtils.fetchJson(url, options);
}

export function authedFetch(url, options = {}) {
  const token = sessionStorage.getItem(AUTH_TOKEN_KEY);

  if (!options.headers) options.headers = {};

  options.headers["Authorization"] = `Bearer ${token}`;

  return fetch(url, options);
}

export function isPasswordValid() {
  return authedFetch(AUTH_PASSWORD_VALIDITY);
}

export default {
  login,
  isPasswordValid,
  forgotPassword,
  logout,
  checkAuth,
  checkError,
  getPermissions,
  getPermissionsSync,
};
