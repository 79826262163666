import React from "react";
import {
  Datagrid,
  DateField,
  List,
  TextField,
  TopToolbar,
  CreateButton,
  useListContext,
} from "react-admin";

import Box from "@material-ui/core/Box";
import InboxIcon from "@material-ui/icons/Inbox";
import Typography from "@material-ui/core/Typography";

import ImportButton from "../components/ImportButton";
import PlanchersFilter from "./PlanchersFilter";
import { ErrorBoundary } from "../lib/error-report";

const { REACT_APP_API_URL } = process.env;

const PlancherActions = (_props) => (
  <TopToolbar>
    <ImportButton
      title="Importer les planchers"
      message="Un seul fichier. Au format Excel (.xlsx)"
      name="planchers"
      method="POST"
      url={`${REACT_APP_API_URL}/planchers/import`}
      accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    />
  </TopToolbar>
);

function Empty() {
  const { basePath } = useListContext();
  return (
    <Box textAlign="center" m={1}>
      <Box display="inline-box" style={{ opacity: 0.5 }}>
        <InboxIcon style={{ fontSize: "12em" }} />
        <Typography variant="h4" paragraph>
          Pas encore de planchers
        </Typography>
        <Typography variant="body1">
          Souhaitez-vous en créer ou en importer&#x202F;?
        </Typography>
      </Box>

      <Box display="flex" width="100%" justifyContent="center" mt="4em">
        <ImportButton
          title="Importer les planchers"
          message="Un seul fichier. Au format Excel (.xlsx)"
          name="planchers"
          method="POST"
          url={`${REACT_APP_API_URL}/planchers/import`}
          accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        />
        <Box ml="2em">
          <CreateButton basePath={basePath} variant="contained" />
        </Box>
      </Box>
    </Box>
  );
}

export default (props) => (
  <ErrorBoundary>
    <List
      bulkActionButtons={false}
      actions={<PlancherActions />}
      {...props}
      title="Planchers"
      sort={{ field: "nom", order: "ASC" }}
      filters={<PlanchersFilter />}
      empty={<Empty />}
      exporter={false}
    >
      <Datagrid rowClick="edit">
        <TextField label="Nom" source="nom" />
        <DateField
          label="Mis à jour le"
          source="updatedAt"
          showTime
          locales="fr-FR"
        />
      </Datagrid>
    </List>
  </ErrorBoundary>
);
