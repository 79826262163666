import { Langue } from "../lib/langue";
import { Enum } from "../utils";
/*

Directeur général                               , fonction exécutive
Président du directoire                         , fonction exécutive
Directeur général délégué                       , fonction exécutive
Directeur général adjoint                       , fonction exécutive
Membre du directoire                            , fonction exécutive
Président-directeur général                     , fonction exécutive
Gérant                                          , fonction exécutive
Président délégué                               , fonction exécutive
Président de SAS                                , fonction exécutive
Administrateur délégué                          , fonction exécutive
Président du conseil d’administration           , fonction non-exécutive
Président du conseil de surveillance            , fonction non-exécutive
Président de l’organe collégial de surveillance , fonction non-exécutive
Membre du conseil d’administration              , fonction non-exécutive
Membre du conseil de surveillance               , fonction non-exécutive
Membre de l’organe collégial de surveillance    , fonction non-exécutive
Président du comité d’audit                     , fonction non-exécutive
Président du comité des rémunérations           , fonction non-exécutive
Président du comité des risques                 , fonction non-exécutive
Président du comité des nominations             , fonction non-exécutive
Autre (préciser)

*/

/** @typedef {"FR"|"EN"} Langue */
/** @typedef {Object.<Langue, string>} Translatable */

/** @typedef {"mandat-executif"|"mandat-non-executif"|"mandat-autre"} FonctionType */

const FonctionTypes = Enum({
  /** @type {FonctionType} */
  MandatExecutif: "mandat-executif",
  /** @type {FonctionType} */
  MandatNonExecutif: "mandat-non-executif",
  /** @type {FonctionType} */
  MandatFonctionCle: "mandat-function-holder",
  /** @type {FonctionType} */
  MandatBranchManager: "mandat-branch-manager",
  /** @type {FonctionType} */
  MandatAutre: "mandat-autre",
});

/**
 * @typedef {Object} Fonction
 * @property {string} id
 * @property {boolean} isMandat
 * @property {boolean} isDirigeant
 * @property {boolean} isResponsabiliteSupplementaire
 * @property {FonctionType} type
 * @property {Translatable} name
 */

/**
 * @type {Array<Fonction>}
 */
const fonctions = [
  {
    id: "president-directeur-general",
    isMandat: true,
    isDirigeant: false,
    isResponsabiliteSupplementaire: false,
    type: FonctionTypes.MandatExecutif,
    name: {
      [Langue.Fr]: "Président-directeur général (CEO)",
      [Langue.En]: "CEO",
    },
  },
  {
    id: "directeur-general-adjoint",
    isMandat: true,
    isDirigeant: false,
    isResponsabiliteSupplementaire: false,
    type: FonctionTypes.MandatExecutif,
    name: {
      [Langue.Fr]: "Directeur(trice) général(e) adjoint(e) (Deputy CEO)",
      [Langue.En]: "Deputy CEO",
    },
  },
  {
    id: "gerant",
    isMandat: false,
    isDirigeant: false,
    isResponsabiliteSupplementaire: false,
    type: FonctionTypes.MandatExecutif,
    name: {
      [Langue.Fr]: "Gérant(e)",
      [Langue.En]: "General Manager",
    },
  },
  {
    id: "dirigeant-executif",
    isMandat: true,
    isDirigeant: true,
    isResponsabiliteSupplementaire: false,
    type: FonctionTypes.MandatExecutif,
    name: {
      [Langue.Fr]: "Cadre dirigeant(e)",
      [Langue.En]: "Executive Manager",
    },
  },
  {
    id: "directeur-general",
    isMandat: true,
    isDirigeant: true,
    isResponsabiliteSupplementaire: false,
    type: FonctionTypes.MandatExecutif,
    name: {
      [Langue.Fr]: "Directeur(trice) général(e)",
      [Langue.En]: "Director General",
    },
  },
  {
    id: "directeur-financier",
    isMandat: true,
    isDirigeant: true,
    isResponsabiliteSupplementaire: false,
    type: FonctionTypes.MandatExecutif,
    name: {
      [Langue.Fr]: "Directeur(trice) financière(e)",
      [Langue.En]: "CFO",
    },
  },
  {
    id: "directeur-des-risques",
    isMandat: true,
    isDirigeant: true,
    isResponsabiliteSupplementaire: false,
    type: FonctionTypes.MandatExecutif,
    name: {
      [Langue.Fr]: "Directeur(trice) des risques",
      [Langue.En]: "CRO",
    },
  },
  {
    id: "directeur-des-risques-financiers",
    isMandat: true,
    isDirigeant: true,
    isResponsabiliteSupplementaire: false,
    type: FonctionTypes.MandatExecutif,
    name: {
      [Langue.Fr]: "Directeur(trice) des risques financiers",
      [Langue.En]: "CFRO",
    },
  },
  {
    id: "directeur-des-systemes-d-information",
    isMandat: true,
    isDirigeant: true,
    isResponsabiliteSupplementaire: false,
    type: FonctionTypes.MandatExecutif,
    name: {
      [Langue.Fr]: "Directeur(trice) des systèmes d’information",
      [Langue.En]: "CIO",
    },
  },
  {
    id: "directeur-general-delegue",
    isMandat: true,
    isDirigeant: false,
    isResponsabiliteSupplementaire: false,
    type: FonctionTypes.MandatExecutif,
    name: {
      [Langue.Fr]: "Directeur(trice) général(e) délégué(e) (COO)",
      [Langue.En]: "Chief Operating Officer (COO)",
    },
  },
  // Organe de direction -- executive
  {
    id: "president-du-directoire",
    isMandat: true,
    isDirigeant: true,
    isResponsabiliteSupplementaire: false,
    type: FonctionTypes.MandatExecutif,
    name: {
      [Langue.Fr]: "Président(e) du directoire",
      [Langue.En]: "Chair of the management body in its executive function",
    },
  },
  {
    id: "president-delegue",
    isMandat: false,
    isDirigeant: false,
    isResponsabiliteSupplementaire: false,
    type: FonctionTypes.MandatExecutif,
    name: {
      [Langue.Fr]: "Président(e) délégué(e)",
      [Langue.En]:
        "Deputy Chair of the management body in its executive function",
    },
  },
  {
    id: "membre-du-directoire",
    isMandat: true,
    isDirigeant: false,
    isResponsabiliteSupplementaire: false,
    type: FonctionTypes.MandatExecutif,
    name: {
      [Langue.Fr]: "Membre du directoire",
      [Langue.En]: "Member of the management body in its executive function",
    },
  },
  //
  {
    id: "president-de-sas",
    isMandat: false,
    isDirigeant: false,
    isResponsabiliteSupplementaire: false,
    type: FonctionTypes.MandatNonExecutif,
    name: {
      [Langue.Fr]: "Président de SAS",
      [Langue.En]: "Président de SAS",
    },
  },
  {
    id: "administrateur-delegue",
    isMandat: true,
    isDirigeant: false,
    isResponsabiliteSupplementaire: false,
    type: FonctionTypes.MandatNonExecutif,
    name: {
      [Langue.Fr]: "Administrateur délégué",
      [Langue.En]: "Administrateur délégué",
    },
  },
  // Fonction non exe
  // Conseil d’administration
  {
    id: "president-du-conseil-d-administration",
    isMandat: true,
    isDirigeant: true,
    isResponsabiliteSupplementaire: false,
    type: FonctionTypes.MandatNonExecutif,
    name: {
      [Langue.Fr]: "Président(e) du conseil d’administration",
      [Langue.En]: "Chair of the Board of Directors",
    },
  },
  {
    id: "vice-president-du-conseil-d-administration",
    isMandat: true,
    isDirigeant: true,
    isResponsabiliteSupplementaire: false,
    type: FonctionTypes.MandatNonExecutif,
    name: {
      [Langue.Fr]: "Vice-président(e) du conseil d’administration",
      [Langue.En]: "Deputy chair of the Board of Directors",
    },
  },
  {
    id: "membre-du-conseil-d-administration",
    isMandat: true,
    isDirigeant: false,
    isResponsabiliteSupplementaire: false,
    type: FonctionTypes.MandatNonExecutif,
    name: {
      [Langue.Fr]: "Membre du conseil d’administration",
      [Langue.En]: "Member of the Board of Directors",
    },
  },
  // Conseil de surveillance
  {
    id: "president-du-conseil-de-surveillance",
    isMandat: true,
    isDirigeant: false,
    isResponsabiliteSupplementaire: false,
    type: FonctionTypes.MandatNonExecutif,
    name: {
      [Langue.Fr]: "Président(e) du conseil de surveillance",
      [Langue.En]: "Chair of the Supervisory Board",
    },
  },
  {
    id: "vice-president-du-conseil-de-surveillance",
    isMandat: true,
    isDirigeant: false,
    isResponsabiliteSupplementaire: false,
    type: FonctionTypes.MandatNonExecutif,
    name: {
      [Langue.Fr]: "Vice-président(e) du conseil de surveillance",
      [Langue.En]: "Deputy chair of the Supervisory Board",
    },
  },
  {
    id: "membre-du-conseil-de-surveillance",
    isMandat: true,
    isDirigeant: false,
    isResponsabiliteSupplementaire: false,
    type: FonctionTypes.MandatNonExecutif,
    name: {
      [Langue.Fr]: "Membre du conseil de surveillance",
      [Langue.En]: "Member of the Supervisory Board",
    },
  },
  // organe de direction -- surveillance
  {
    id: "president-de-l-organe-collegial-de-surveillance",
    isMandat: true,
    isDirigeant: false,
    isResponsabiliteSupplementaire: false,
    type: FonctionTypes.MandatNonExecutif,
    name: {
      [Langue.Fr]: "Président(e) de l’organe collégial de surveillance",
      [Langue.En]: "Chair of the management body in its supervisory function",
    },
  },
  {
    id: "vice-president-de-l-organe-collegial-de-surveillance",
    isMandat: true,
    isDirigeant: false,
    isResponsabiliteSupplementaire: false,
    type: FonctionTypes.MandatNonExecutif,
    name: {
      [Langue.Fr]: "Vice-président(e) de l’organe collégial de surveillance",
      [Langue.En]:
        "Deputy chair of the management body in its supervisory function",
    },
  },
  {
    id: "membre-de-l-organe-collegial-de-surveillance",
    isMandat: true,
    isDirigeant: false,
    isResponsabiliteSupplementaire: false,
    type: FonctionTypes.MandatNonExecutif,
    name: {
      [Langue.Fr]: "Membre de l’organe collégial de surveillance",
      [Langue.En]: "Member of the management body in its supervisory function",
    },
  },
  // Comités
  {
    id: "president-du-comite-d-audit",
    isMandat: false,
    isDirigeant: false,
    isResponsabiliteSupplementaire: true,
    type: FonctionTypes.MandatNonExecutif,
    name: {
      [Langue.Fr]: "Président(e) du comité d’audit",
      [Langue.En]: "Chair of the Audit Committee",
    },
  },
  {
    id: "member-du-comite-d-audit",
    isMandat: false,
    isDirigeant: false,
    isResponsabiliteSupplementaire: true,
    type: FonctionTypes.MandatNonExecutif,
    name: {
      [Langue.Fr]: "Membre du comité d’audit ",
      [Langue.En]: "Member of the Audit Committee",
    },
  },
  {
    id: "president-du-comite-des-remunerations",
    isMandat: false,
    isDirigeant: false,
    isResponsabiliteSupplementaire: true,
    type: FonctionTypes.MandatNonExecutif,
    name: {
      [Langue.Fr]: "Président(e) du comité des rémunérations",
      [Langue.En]: "Chair of the Remuneration Committee",
    },
  },
  {
    id: "membre-du-comite-des-remunerations",
    isMandat: false,
    isDirigeant: false,
    isResponsabiliteSupplementaire: true,
    type: FonctionTypes.MandatNonExecutif,
    name: {
      [Langue.Fr]: "Membre du comité des rémunérations",
      [Langue.En]: "Member of the Remuneration Committee",
    },
  },
  {
    id: "president-du-comite-des-risques",
    isMandat: false,
    isDirigeant: false,
    isResponsabiliteSupplementaire: true,
    type: FonctionTypes.MandatNonExecutif,
    name: {
      [Langue.Fr]: "Président(e) du comité des risques",
      [Langue.En]: "Chair of the Risk Committee",
    },
  },
  {
    id: "membre-du-comite-des-risques",
    isMandat: false,
    isDirigeant: false,
    isResponsabiliteSupplementaire: true,
    type: FonctionTypes.MandatNonExecutif,
    name: {
      [Langue.Fr]: "Membre du comité des risques",
      [Langue.En]: "Member of the Risk Committee",
    },
  },
  {
    id: "president-du-comite-des-nominations",
    isMandat: false,
    isDirigeant: false,
    isResponsabiliteSupplementaire: true,
    type: FonctionTypes.MandatNonExecutif,
    name: {
      [Langue.Fr]: "Président(e) du comité des nominations",
      [Langue.En]: "Chair of the Nomination Committee",
    },
  },
  {
    id: "membre-du-comite-des-nominations",
    isMandat: false,
    isDirigeant: false,
    isResponsabiliteSupplementaire: true,
    type: FonctionTypes.MandatNonExecutif,
    name: {
      [Langue.Fr]: "Membre du comité des nominations",
      [Langue.En]: "Member of the Nomination Committee",
    },
  },
  // Chefs
  {
    id: "chef-de-la-fonction-de-gestion-des-risques",
    isMandat: false,
    isDirigeant: false,
    isResponsabiliteSupplementaire: true,
    type: FonctionTypes.MandatonFonctionCle,
    name: {
      [Langue.Fr]: "Chef(fe) de la fonction de gestion des risques",
      [Langue.En]: "Head of risks",
    },
  },
  {
    id: "chef-de-la-fonction-de-conformite",
    isMandat: false,
    isDirigeant: false,
    isResponsabiliteSupplementaire: true,
    type: FonctionTypes.MandatFonctionCle,
    name: {
      [Langue.Fr]: "Chef(fe) de la fonction de conformité",
      [Langue.En]: "Head of compliance",
    },
  },
  {
    id: "chef-de-la-fonction-d-audit-interne",
    isMandat: false,
    isDirigeant: false,
    isResponsabiliteSupplementaire: true,
    type: FonctionTypes.MandatFonctionCle,
    name: {
      [Langue.Fr]: "Chef(fe) de la fonction d’audit interne",
      [Langue.En]: "Head of audit",
    },
  },
  // Autres
  {
    id: "mandataire",
    isMandat: true,
    isDirigeant: false,
    isResponsabiliteSupplementaire: false,
    type: FonctionTypes.MandatExecutif,
    name: {
      [Langue.Fr]: "Mandataire",
      [Langue.En]: "Other",
    },
  },
  {
    id: "autre",
    isMandat: true,
    isDirigeant: false,
    isResponsabiliteSupplementaire: true,
    type: FonctionTypes.MandatNonExecutif,
    name: {
      [Langue.Fr]: "Autre",
      [Langue.En]: "Other",
    },
  },
];

/** Turns the fonctions into a map of <id, fonction>
 * @template T
 * @param {Array<T & {id: string}>} objectsWithId
 * @param {(T) => boolean} [filter=()=>true]
 * @returns {Object<string,Fonction>}
 */
function asMap(objectsWithId, filter = () => true) {
  return objectsWithId.filter(filter).reduce((acc, o) => {
    acc[o.id] = o;

    return acc;
  }, {});
}

/**
 * Builds select choices from a collection with translatable string
 * The returned values is an object, mapping a Langue to an array
 * of choice options
 * @template T
 * @param {[string, T][]} idElementPairs
 * @param {(T) => Translatable} [getTranslatable=(T) => T]
 * @returns {Object<string, Array<{id: string, name: string}>}
 */
function buildSelectChoices(idElementPairs, getTranslatable = (x) => x) {
  return idElementPairs.reduce(
    (acc, [id, element]) => {
      const t = getTranslatable(element);
      for (let l of Langue) {
        acc[l].push({ id, name: t[l] });
      }

      return acc;
    },
    {
      [Langue.Fr]: [],
      [Langue.En]: [],
    }
  );
}

/** Fonctions available for the main fonction of candidature */
export const FONCTIONS = asMap(fonctions);
/** Fonctions available for other fonctions */
export const MANDATS = asMap(fonctions, (f) => f.isMandat);
/** Fonctions that are only available as secondary */
export const RESPONSABILITES = asMap(
  fonctions,
  (f) => f.isResponsabiliteSupplementaire
);

// Select Choices

export const FONCTIONS_LABELS = Object.keys(FONCTIONS);
export const FONCTIONS_CHOICES = buildSelectChoices(
  Object.entries(FONCTIONS),
  (e) => e.name
);

export const MANDATS_LABELS = Object.keys(MANDATS);
export const MANDATS_CHOICES = buildSelectChoices(
  Object.entries(MANDATS),
  (e) => e.name
);

export const RESPONSABILITES_LABELS = Object.keys(RESPONSABILITES);
export const RESPONSABILITES_CHOICES = buildSelectChoices(
  Object.entries(RESPONSABILITES),
  (e) => e.name
);

// ====================
// Fonction types
// ====================
/**
 * @type {Object.<string, Object.<Langue, string>>}
 */
export const TYPES = {
  "mandat-executif": {
    [Langue.Fr]: "Fonction exécutive",
    [Langue.En]: "Executive",
  },
  "mandat-non-executif": {
    [Langue.Fr]: "Fonction non-exécutive",
    [Langue.En]: "Non executive",
  },
  "mandat-function-holder": {
    [Langue.Fr]: "Titulaire d’une fonction clé",
    [Langue.En]: "Key Function Holder",
  },
  "mandat-branch-manager": {
    [Langue.Fr]: "Poste de Directeur(trice) d’une succursale",
    [Langue.En]: "Branch Manager",
  },
  "mandat-autre": {
    [Langue.Fr]: "Fonction non constitutive d’un mandat social",
    [Langue.En]: "Other function",
  },
};

/** Select Choices for fonction types */
export const TYPES_CHOICES = buildSelectChoices(Object.entries(TYPES));

/** Décompte privilégié */
export const DECOMPTE_PRIVILEGIE = {
  "part-of-group": {
    [Langue.Fr]: "Oui, au sein d’un groupe",
    [Langue.En]: "Yes, part of a group",
  },
  "part-of-institutional-scheme": {
    [Langue.Fr]: "Oui, au sein d’un système de protection institutionnel",
    [Langue.En]: "Yes, as part of an institutional protection scheme",
  },
  "qualifying-holding": {
    [Langue.Fr]: "Oui, paricipation qualifiée",
    [Langue.En]: "Yes, qualified holding",
  },
  "no-not-part-of-anything": {
    [Langue.Fr]:
      "Non, ne fait pas partie d’un groupe, d’un système de protection institutionnel ou d’un établissement détenant une participation qualifiée",
    [Langue.En]:
      "No, not part of any group, institutional protection scheme or qualifying holding",
  },
  "no-counting": {
    [Langue.Fr]: "Non, pas de décompte privilégié",
    [Langue.En]: "No, no counting",
  },
};

export const DECOMPTE_PRIVILEGIE_CHOICES = buildSelectChoices(
  Object.entries(DECOMPTE_PRIVILEGIE)
);

export const STRUCTURE_GOUVERNANCE = {
  "one-tier": {
    [Langue.Fr]: "Structure monoiste",
    [Langue.En]: "One tier",
  },
  "two-tier": {
    [Langue.Fr]: "Structure dualiste",
    [Langue.En]: "Two tier",
  },
  other: {
    [Langue.Fr]: "Autre structure",
    [Langue.En]: "Other",
  },
};

export const STRUCTURE_GOUVERNANCE_CHOICES = buildSelectChoices(
  Object.entries(STRUCTURE_GOUVERNANCE)
);

export const ETABLISSEMENT_IMPORTANT = {
  oui: {
    [Langue.Fr]: "Oui",
    [Langue.En]: "Yes",
  },
  non: {
    [Langue.Fr]: "Non",
    [Langue.En]: "No",
  },
  "not applicable": {
    [Langue.Fr]: "Sans objet",
    [Langue.En]: "Not applicable",
  },
};

export const ETABLISSEMENT_IMPORTANT_CHOICES = buildSelectChoices(
  Object.entries(ETABLISSEMENT_IMPORTANT)
);
