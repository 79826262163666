import React from "react";
import Alert from "@material-ui/lab/Alert";
import { useFormState } from "react-final-form";

export default function TranslationInformation() {
  const { values: record } = useFormState({ subscription: { values: true } });
  if (!record.multilingue) return null;

  return (
    <Alert severity="warning">
      La traduction est à la main des correspondants Fit & Proper.
    </Alert>
  );
}
