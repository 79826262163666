import * as React from "react";
import PropTypes from "prop-types";
import { shallowEqual, useSelector } from "react-redux";
import lodashGet from "lodash/get";
// @ts-ignore
import { useMediaQuery, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DefaultIcon from "@material-ui/icons/ViewList";
import classnames from "classnames";
import { getResources } from "ra-core";

import { DashboardMenuItem, MenuItemLink } from "react-admin";

import HelpIcon from "@material-ui/icons/Help";
import DescriptionIcon from "@material-ui/icons/Description";
import { authedFetch } from "./authProvider";

export const MENU_WIDTH = 240;
export const CLOSED_MENU_WIDTH = 55;

const { REACT_APP_API_URL } = process.env;

const useStyles = makeStyles(
  (theme) => ({
    main: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      // marginTop: "0.5em",
      // [theme.breakpoints.only("xs")]: {
      //   marginTop: 0,
      // },
      // [theme.breakpoints.up("md")]: {
      //   marginTop: "1.5em",
      // },
    },
    open: {
      width: lodashGet(theme, "menu.width", MENU_WIDTH),
    },
    closed: {
      width: lodashGet(theme, "menu.closedWidth", CLOSED_MENU_WIDTH),
    },
  }),
  { name: "RaMenu" }
);

const Menu = (props) => {
  const {
    classes: classesOverride,
    className,
    dense,
    hasDashboard,
    onMenuClick,
    logout,
    ...rest
  } = props;
  const classes = useStyles(props);
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources, shallowEqual);

  return (
    <div
      className={classnames(
        classes.main,
        {
          [classes.open]: open,
          [classes.closed]: !open,
        },
        className
      )}
      {...rest}
    >
      {hasDashboard && (
        <DashboardMenuItem
          onClick={onMenuClick}
          dense={dense}
          sidebarIsOpen={open}
        />
      )}
      {resources
        .filter((r) => r.hasList)
        .map((resource) => (
          <MenuItemLink
            key={resource.name}
            to={{
              pathname: `/${resource.name}`,
              state: { _scrollToTop: true },
            }}
            primaryText={
              (resource.options && resource.options.label) || resource.name
            }
            leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
            onClick={onMenuClick}
            dense={dense}
            sidebarIsOpen={open}
          />
        ))}
      <Box mt="2em" />
      <MenuItemLink
        to=""
        primaryText="Guide l’utilisateur"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();

          authedFetch(REACT_APP_API_URL + "/private/guide-utilisateur.docx")
            .then((response) => response.blob())
            .then((blob) => URL.createObjectURL(blob))
            .then((objectURL) => window.open(objectURL))
            .catch(console.error);
        }}
        leftIcon={<HelpIcon />}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        to="/mentions-legales"
        primaryText="Mentions Légales"
        onClick={onMenuClick}
        leftIcon={<DescriptionIcon />}
        sidebarIsOpen={open}
      />

      {isXSmall && logout}
    </div>
  );
};

Menu.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  dense: PropTypes.bool,
  hasDashboard: PropTypes.bool,
  logout: PropTypes.element,
  onMenuClick: PropTypes.func,
};

Menu.defaultProps = {
  onMenuClick: () => null,
};

export default Menu;
