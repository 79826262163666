import React from "react";
import { Typography, Box } from "@material-ui/core";
import { ArrayInput } from "react-admin";
import TextInputCustom from "../../components/input/TextInputCustom";
import SelectInputCustom from "../../components/input/SelectInputCustom";
import NullableBooleanInputCustom from "../../components/input/NullableBooleanInputCustom";
import ConditionalInput from "../../components/input/ConditionalInput";
import FormIteratorCustom from "../../components/custom/FormIteratorCustom";
import DateInputCustom from "../../components/input/DateInputCustom";
import InfoBulle from "../../components/InfoBulle";
import { useGlobalDisabled } from "./helper";
import { useLangue } from "../transalations";
import { Langue } from "../../lib/langue";

const EXIGENCES_DIVERSTE = {
  yes: {
    [Langue.Fr]: "Oui",
    [Langue.En]: "Yes",
  },
  no: {
    [Langue.Fr]: "Non",
    [Langue.En]: "No",
  },
  "absence-d-objectifs": {
    [Langue.Fr]: "Absence d’objectifs ou de règles internes",
    [Langue.En]: "No internal target exist",
  },
};

const EXIGENCES_DIVERSITE_CHOICES = Langue.reduce((acc, langue) => {
  if (!acc[langue]) acc[langue] = [];

  Object.entries(EXIGENCES_DIVERSTE).forEach(([id, variants]) => {
    const name = variants[langue] || variants[Langue.Fr];
    acc[langue].push({ id, name });
  });

  return acc;
}, {});

const DateRenouvellement = ({ name }) => {
  const disabled = useGlobalDisabled();

  return (
    <Box>
      <DateInputCustom
        label="Date renouvellement"
        disabled={disabled}
        source={name}
      />
    </Box>
  );
};

const Dirigeant = ({ name, tabName }) => {
  const disabled = useGlobalDisabled();

  const { t } = useLangue();

  return (
    <Box>
      <TextInputCustom
        id={`${tabName}#${name}.nom`}
        disabled={disabled}
        label={t("Prénom, Nom")}
        source={`${name}.nom`}
      />
      <TextInputCustom
        disabled={disabled}
        multiline
        label={t("Fonctions")}
        source={`${name}.fonctions`}
        id={`${tabName}#${name}.fonctions`}
        fullWidth
      />
      <TextInputCustom
        disabled={disabled}
        multiline
        portalTargets="acpr"
        label={t("Attributions")}
        source={`${name}.attributions`}
        id={`${tabName}#${name}.attributions`}
        fullWidth
      />
      <DateInputCustom
        disabled={disabled}
        portalTargets="acpr"
        label={t("Date de première nomination")}
        source={`${name}.datePremiereNomination`}
      />

      <Typography variant="subtitle2">
        {t("Dates de renouvellement")}
      </Typography>

      <ArrayInput source={`${name}.dateRenouvellements`} label="">
        <FormIteratorCustom
          disabled={disabled}
          component={<DateRenouvellement />}
        />
      </ArrayInput>
      <TextInputCustom
        disabled={disabled}
        portalTargets="acpr"
        label={t("Durée du mandat en cours")}
        source={`${name}.dureeMandatEnCours`}
        id={`${tabName}#${name}.dureeMandatEnCours`}
      />
      <TextInputCustom
        disabled={disabled}
        multiline
        label={t("Formation initiale et continue")}
        portalTargets="acpr"
        source={`${name}.formation`}
        id={`${tabName}#${name}.formation`}
        fullWidth
      />
      <TextInputCustom
        disabled={disabled}
        multiline
        label={t("Expérience (bancaire/non bancaire en précisant la durée)")}
        source={`${name}.experience`}
        id={`${tabName}#${name}.experience`}
        portalTargets="acpr"
        fullWidth
      />
      <Box display="flex">
        <TextInputCustom
          disabled={disabled}
          multiline
          label={t("Compétences / principaux domaines d’expertise")}
          source={`${name}.competence`}
          id={`${tabName}#${name}.competence`}
          fullWidth
        />
        <InfoBulle position="right" ml="0.5em" mt="0.5em">
          Notamment au regard :<br />
          - Des marchés bancaires et des marchés financiers
          <br />
          - Des exigences légales et réglementaires applicables à
          l’établissement assujetti
          <br />
          - Du système de gouvernance de l’établissement
          <br />
          - Du contrôle interne de l’établissement
          <br />
          - De la planification stratégique et de sa mise en œuvre
          <br />
          - De la gestion des risques
          <br />
          - De l’information comptable et financière
          <br />
          - De la lutte contre le blanchiment de capitaux et le financement du
          terrorisme
          <br />- Des risques liés au climat et à l'environnement
        </InfoBulle>
      </Box>
    </Box>
  );
};

export default (props) => {
  const { tabName } = props;
  const disabled = useGlobalDisabled();
  const { t, langue } = useLangue();

  return (
    <Box maxWidth="72em">
      <Typography variant="subtitle1">
        {t(
          "▸ L’entité soumise à la surveillance prudentielle doit-elle respecter des exigences nationales en matière de diversité des genres ?"
        )}
      </Typography>
      <ConditionalInput
        disabled={disabled}
        portalTargets="imas"
        property="exigenceDiversiteRequise"
        id={`${tabName}#exigenceDiversiteRequise`}
      >
        {({ disabled, ...rest }) => (
          <>
            <Typography variant="subtitle1">
              {t(
                "L’entité soumise à la surveillance prudentielle respecte-t-elle les exigences nationales en matière de diversité des genres avec cette nomination ?"
              )}
            </Typography>
            <NullableBooleanInputCustom
              disabled={disabled}
              portalTargets="imas"
              source="exigenceDiversiteRespectee"
              id={`${tabName}#exigenceDiversiteRespectee`}
              {...rest}
            />
          </>
        )}
      </ConditionalInput>
      <Typography variant="subtitle1">
        {t(
          "▸ Avec cette nomination, l’entité soumise à la surveillance prudentielle atteint-elle ses objectifs ou respecte-t-elle ses règles internes en matière de diversité des genres au sein de l’organe de direction ?"
        )}
      </Typography>
      <SelectInputCustom
        disabled={disabled}
        portalTargets="imas"
        source="exigenceDiversiteNominationAtteintObjectifs"
        id={`${tabName}#exigenceDiversiteNominationAtteintObjectifs`}
        choices={EXIGENCES_DIVERSITE_CHOICES[langue]}
      />
      <Typography variant="subtitle1">
        {t(
          "▸ Avec cette nomination, l’entité soumise à la surveillance prudentielle respecte-t-elle ses autres principes internes en matière de diversité ?"
        )}
      </Typography>
      <SelectInputCustom
        disabled={disabled}
        portalTargets="imas"
        source="exigenceDiversiteNominationAtteintObjectifsInternes"
        id={`${tabName}#exigenceDiversiteNominationAtteintObjectifsInternes`}
        choices={EXIGENCES_DIVERSITE_CHOICES[langue]}
      />
      <Typography variant="subtitle1">
        {langue === Langue.Fr && (
          // There doesn't seem to be a translation for this one
          <>
            ▸ Comment se situe la personne nommée (renouvelée) au regard de la
            compétence collective des autres membres de l’organe de direction de
            l’entité soumise à la surveillance prudentielle&#x202F;?
            <br />
          </>
        )}
        {t(
          "Veuillez expliquer en quoi la personne proposée complète la compétence collective de celle-ci en vous référant, le cas échéant, au résultat de la plus récente auto-évaluation de la compétence collective de l’organe de direction :"
        )}
      </Typography>
      <TextInputCustom
        disabled={disabled}
        multiline
        source="competenceCollective"
        id={`${tabName}#competenceCollective`}
        fullWidth
      />
      <Typography variant="subtitle1">
        {t(
          "▸ Expliquez de façon générale les faiblesses éventuellement identifiées dans la composition d’ensemble de l’organe de direction :"
        )}
      </Typography>
      <TextInputCustom
        disabled={disabled}
        multiline
        source="faiblesses"
        id={`${tabName}#faiblesses`}
        fullWidth
      />
      <Typography variant="subtitle1">
        {t(
          "▸ Comment la personne nommée contribuera-t-elle à remédier à tout ou partie des éventuelles faiblesses évoquées dans votre réponse à la question précédente ?"
        )}
      </Typography>
      <TextInputCustom
        disabled={disabled}
        multiline
        source="solutionsFaiblesses"
        id={`${tabName}#solutionsFaiblesses`}
        fullWidth
      />
      <Typography variant="subtitle1">
        {t(
          "▸ Liste des personnes composant la direction effective de l’établissement (dans le cas où le mandat pour lequel la présente déclaration est réalisée, est de nature exécutive)"
        )}
        <br />
        {t("OU")}
        <br />
        {t(
          "Liste des personnes composant l’organe social de l’établissement (dans le cas où le mandat pour lequel la présente déclaration est réalisée, est de nature non exécutive)"
        )}
      </Typography>
      <ArrayInput source="dirigeants" label="">
        <FormIteratorCustom
          disabled={disabled}
          component={<Dirigeant tabName={tabName} />}
        />
      </ArrayInput>
    </Box>
  );
};
