import React, { useRef, useState, useCallback } from "react";
import { Button } from "react-admin";
import { useLocation } from "react-router-dom";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import { CreateReportModal } from "../bugreport/Create";
import { Create } from "react-admin";
import CandidatureForm from "./form/CandidatureForm";
import { ErrorBoundary } from "../lib/error-report";
import PostCreateActions from "../components/custom/PostCreateActions";
import { DisconnectionAlert } from "../components/DisconnectionAlert";

import { Langue } from "../lib/langue";

export default (props) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const langue = query.get("langue") || Langue.Fr;

  const CANDIDATURES_DEFAULTS = {
    langue,
    mandatFonctions: [{}],
  };

  const [showModal, setShowModal] = useState(false);
  const form = useRef({});

  const onFormChange = useCallback((f) => {
    form.current = f;
  }, []);
  return (
    <ErrorBoundary>
      <DisconnectionAlert />
      <Create
        {...props}
        actions={
          <PostCreateActions>
            <Button
              label="Signaler un incident"
              onClick={() => setShowModal(true)}
            />
          </PostCreateActions>
        }
      >
        <CandidatureForm
          redirect="edit"
          langue={langue}
          onFormChange={onFormChange}
          defaultValue={CANDIDATURES_DEFAULTS}
        />
      </Create>
      <CreateReportModal
        {...props}
        open={showModal}
        onClose={() => setShowModal(false)}
        formState={form}
      />
      <Dialog>
        <DialogTitle></DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </ErrorBoundary>
  );
};
