import React, { useRef, useState, useCallback } from "react";
import Button from "@material-ui/core/Button";

import { CreateReportModal } from "../bugreport/Create";
import { fetchStart, fetchEnd, useNotify } from "react-admin";

import { useDispatch } from "react-redux";

import EditAside from "../components/EditAside";
import Edit from "../components/Edit";
import ExportAsWordButton from "../components/ExportWordButton";

import CandidatureForm from "./form/CandidatureForm";
import { DisconnectionAlert } from "../components/DisconnectionAlert";
import StatusAlert from "./StatusAlert";
import VersionAlert from "./VersionAlert";
import ConflitAlert from "./ConflitAlert";
import PostEditActions from "../components/custom/PostEditActions";

import { ErrorBoundary } from "../lib/error-report";

import GetAppIcon from "@material-ui/icons/GetApp";

const { REACT_APP_API_URL } = process.env;

const EditTitle = ({ record }) => (
  <span>Candidature {record ? `#${record.id || ""}` : ""}</span>
);

function printCandidature(props, dispatch, notify) {
  dispatch(fetchStart());
  const body = document.body;

  const iframe = document.createElement("iframe");
  iframe.src = `${window.location.origin}/#/candidatures/${props.id}/show`;

  window.alert(
    "Dans le dialogue d’impression suivant, choisissez « Enregistrer comme PDF »"
  );

  notify("Veuillez patienter quelques instants…");

  setTimeout(() => notify("C’est bientôt prêt"), 5000);

  function onLoad() {
    setTimeout(() => {
      iframe.removeEventListener("load", onLoad);
      iframe.focus();

      iframe.contentWindow.addEventListener(
        "afterprint",
        () => {
          body.removeChild(iframe);
        },
        { once: true }
      );

      dispatch(fetchEnd());
      iframe.contentWindow.print();
    }, 10000);
  }

  iframe.addEventListener("load", onLoad, { once: true });

  body.appendChild(iframe);
}

export default (props) => {
  const dispatch = useDispatch();
  const notify = useNotify();
  const [showModal, setShowModal] = useState(false);
  const form = useRef({});
  const [block, setBlock] = useState(false);
  const [version, setVersion] = useState("v2");

  const onFormChange = useCallback((f) => {
    form.current = f;
    if (record.current?.statut === "SoumisCandidat") {
      setBlock(true);
    } else {
      setBlock(false);
    }

    setVersion(record.current?.version || "v2");
  }, []);

  const record = useRef({});

  return (
    <ErrorBoundary>
      <DisconnectionAlert />
      <StatusAlert record={record} block={block} />
      <VersionAlert version={version} />
      <ConflitAlert createdAt={record.current.createdAt} />

      <Edit
        title={<EditTitle />}
        actions={
          <PostEditActions>
            <Button
              startIcon={<GetAppIcon />}
              color="primary"
              size="small"
              variant="contained"
              title="Exporter au format PDF"
              onClick={() => printCandidature(props, dispatch, notify)}
              disableElevation
              style={{ marginRight: "0.5em" }}
            >
              Formulaire intégral (PDF)
            </Button>
            <ExportAsWordButton
              url={`${REACT_APP_API_URL}/candidatures/${props.id}/export-word`}
              name={`Export-${props.id}`}
            >
              Revue Périodique (Word)
            </ExportAsWordButton>
            <Button
              color="primary"
              style={{ marginLeft: "0.5em" }}
              onClick={() => setShowModal(true)}
            >
              Signaler un incident
            </Button>
          </PostEditActions>
        }
        aside={<EditAside />}
        {...props}
      >
        <CandidatureForm
          redirect={null}
          onFormChange={onFormChange}
          recordRef={record}
        />
      </Edit>
      <CreateReportModal
        {...props}
        open={showModal}
        onClose={() => setShowModal(false)}
        formState={form}
      />
    </ErrorBoundary>
  );
};
