import React, { memo, useEffect, useRef } from "react";
import { useField } from "react-final-form";
import { ArrayInput, required } from "react-admin";
import { Typography, Box } from "@material-ui/core";
import TextInputCustom from "../../components/input/TextInputCustom";
import TranslatableTextInputCustom from "../../components/input/TranslatableTextInputCustom";
import DateInputCustom from "../../components/input/DateInputCustom";
import FormIteratorCustom from "../../components/custom/FormIteratorCustom";
import File from "../../components/File";

const RefusEtValidation = ({ name, tabName, disabled }) => {
  const { input: dateFin } = useField(`${name}.dateFin`);

  const mounted = useRef(false);

  useEffect(() => {
    if (mounted.current) return;

    if (dateFin) {
      dateFin.onBlur();
    }

    mounted.current = true;
  }, [dateFin]);

  return (
    <Box>
      <Box display="flex">
        <Box>
          <TextInputCustom
            disabled={disabled}
            label="Autorité compétente concernée"
            source={`${name}.autoriteCompetente`}
            id={`${tabName}#${name}.autoriteCompetente`}
            validate={required()}
          />
        </Box>
        <Box ml="1em">
          <TextInputCustom
            disabled={disabled}
            label="Établissement concerné"
            source={`${name}.etablissement`}
            id={`${tabName}#${name}.etablissement`}
          />
        </Box>
        <Box ml="1em">
          <TranslatableTextInputCustom
            disabled={disabled}
            label="Fonction concernée"
            source={`${name}.fonction`}
            id={`${tabName}#${name}.fonction`}
          />
        </Box>
      </Box>

      <Box display="flex">
        <Box>
          <DateInputCustom
            disabled={disabled}
            label="Date de début"
            source={`${name}.dateDebut`}
          />
        </Box>
        <Box ml="1em">
          <DateInputCustom
            disabled={disabled}
            label="Date de fin"
            source={`${name}.dateFin`}
            validate={(value) => {
              try {
                /* eslint-disable-next-line no-eval */
                const dateDebut = eval(`allValues?.${name}?.dateDebut`);

                return (
                  dateDebut > value &&
                  "La date de fin doit être postérieure à la date de début"
                );
              } catch (_) {
                return;
              }
            }}
          />
        </Box>
        <Box ml="1em">
          <DateInputCustom
            disabled={disabled}
            label="Date de l'évaluation"
            source={`${name}.dateEvaluation`}
          />
        </Box>
      </Box>

      <TranslatableTextInputCustom
        disabled={disabled}
        multiline
        label="Conclusion de l'évaluation"
        source={`${name}.conclusion`}
        id={`${tabName}#${name}.conclusion`}
        fullWidth
        helperText="En cas de validation, fournir le justificatif, si l’autorité compétente en délivre un."
      />
      <File
        label="Justificatifs"
        source={`${name}.justificatif`}
        entity={name}
      />

      <TranslatableTextInputCustom
        disabled={disabled}
        multiline
        label="Le cas échéant, veuillez indiquer les raisons du ou des refus de validation déclaré(s)"
        source={`${name}.commentaires`}
        id={`${name}.commentaires`}
        fullWidth
      />
    </Box>
  );
};

export default memo((props) => {
  const { tabName, disabled } = props;
  return (
    <Box mt="1em">
      <Typography variant="h6">
        Validation / refus de validation antérieure par des autorités
        compétentes bancaires ou financières en France ou à l’étranger (hors
        ACPR ou BCE) :
      </Typography>
      <ArrayInput source="refusEtValidations" label="">
        <FormIteratorCustom
          disabled={disabled}
          component={
            <RefusEtValidation tabName={tabName} disabled={disabled} />
          }
        />
      </ArrayInput>
    </Box>
  );
});
