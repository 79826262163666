import React from "react";

import { Typography, Box } from "@material-ui/core";

import TranslatableTextInputCustom from "../../../components/input/TranslatableTextInputCustom";
import SelectInputCustom from "../../../components/input/SelectInputCustom";

import { Enum } from "../../../utils";

const Triggers = Enum({
  Appointee: "appointee",
  Entity: "entity",
});

const TRIGGER_CHOICES = [
  { id: Triggers.Appointee, name: "Le candidat" },
  { id: Triggers.Entity, name: "L’entité" },
];

function Subtitle(props) {
  return (
    <Box maxWidth="72em">
      <Typography {...props} />
    </Box>
  );
}

export default function Faillite({ disabled, tabName, ...rest }) {
  return (
    <Box ml="2em" mr="2em">
      <TranslatableTextInputCustom
        label="Veuillez préciser"
        disabled={disabled}
        portalTargets="acpr"
        source="infraction.procedureFailliteText"
        id={`${tabName}#infraction.procedureFailliteText`}
      />
      <TranslatableTextInputCustom
        label="Temps écoulé depuis la date de la décision"
        disabled={disabled}
        portalTargets="imas"
        source={"infraction.failliteDepuis"}
        id={`${tabName}#infraction.failliteDepuis`}
        {...rest}
      />
      <Subtitle variant="subtitle1">
        Statut et conclusion (le cas échéant) de la procédure (si elle a été
        menée à terme, veuillez préciser si elle a conclu à une faute
        intentionnelle ou à une négligence)
      </Subtitle>
      <TranslatableTextInputCustom
        disabled={disabled}
        multiline
        fullWidth
        portalTargets="imas"
        source={"infraction.failliteStatut"}
        id={`${tabName}#infraction.failliteStatut`}
        {...rest}
      />
      <TranslatableTextInputCustom
        label="Mesures de précaution ou de saisie"
        disabled={disabled}
        multiline
        fullWidth
        portalTargets="imas"
        source={"infraction.failliteMesuresPrecaution"}
        id={`${tabName}#infraction.failliteMesuresPrecaution`}
        {...rest}
      />
      <Subtitle variant="subtitle1">
        Qui a déclanché la procédure (vous-même ou l’entité concernée)&nbsp;?
      </Subtitle>
      <SelectInputCustom
        disabled={disabled}
        portalTargets="imas"
        source={"infraction.failliteDeclencheur"}
        id={`${tabName}#infraction.failliteDeclencheur`}
        choices={TRIGGER_CHOICES}
        {...rest}
      />
      <Subtitle variant="subtitle1">
        Le cas échéant, veuillez fournir des informations sur l’entité impliquée
        dans la procédure
      </Subtitle>
      <TranslatableTextInputCustom
        disabled={disabled}
        multiline
        fullWidth
        portalTargets="imas"
        source={"infraction.failliteDetailsEntite"}
        id={`${tabName}#infraction.failliteDetailsEntite`}
        helperText="if applicable"
        {...rest}
      />
      <Subtitle variant="subtitle1">
        Détails concernant le rôle que vous avez joué, en particulier si vous
        avez été déclaré(e) responsable de l’insolvabilité de l’entité:w
      </Subtitle>
      <TranslatableTextInputCustom
        disabled={disabled}
        multiline
        fullWidth
        source={"infraction.failliteImplicationPersonnelle"}
        id={`${tabName}#infraction.failliteImplicationPersonnelle`}
        {...rest}
      />
      <Subtitle variant="subtitle1">
        Facteurs atténuants ou aggravants éventuels
      </Subtitle>
      <TranslatableTextInputCustom
        disabled={disabled}
        multiline
        fullWidth
        source={"infraction.failliteCirconstancesAggravantes"}
        id={`${tabName}#infraction.failliteCirconstancesAggravantes`}
        {...rest}
      />
    </Box>
  );
}

Faillite.clear = function (formData) {
  formData.infraction.failliteDepuis = null;
  formData.infraction.failliteStatut = null;
  formData.infraction.failliteMesuresPrecaution = null;
  formData.infraction.failliteDeclencheur = null;
  formData.infraction.failliteImplicationPersonnelle = null;
  formData.infraction.failliteCirconstancesAggravantes = null;
};
