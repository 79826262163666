import React from "react";
import { Typography } from "@material-ui/core";

export default ({ dayTotal }) => {
  return (
    <>
      {dayTotal >= 260 && (
        <Typography variant="h6" color="error">
          Le nombre total de jours par an (actuellement {dayTotal}) ne peut pas
          dépasser les 260 jours.
        </Typography>
      )}

      {dayTotal >= 250 && dayTotal < 260 && (
        <Typography variant="h6" color="error">
          Attention&nbsp;: le nombre total de jours par an est supérieur à 250.
          La personne devra vérifier que le temps consacré aux fonctions n’est
          pas trop élevé.
        </Typography>
      )}
    </>
  );
};

