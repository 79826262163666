const NAMES = {
  AF: "Afghanistan",
  ZA: "Afrique du Sud",
  AL: "Albanie",
  DZ: "Algérie",
  DE: "Allemagne",
  AD: "Andorre",
  AO: "Angola",
  AI: "Anguilla",
  AQ: "Antarctique",
  AG: "Antigua-et-Barbuda",
  AN: "Antilles néerlandaises",
  SA: "Arabie saoudite",
  AR: "Argentine",
  AM: "Arménie",
  AW: "Aruba",
  AU: "Australie",
  AT: "Autriche",
  AZ: "Azerbaïdjan",
  BS: "Bahamas",
  BH: "Bahreïn",
  BD: "Bangladesh",
  BB: "Barbade",
  BY: "Bélarus",
  BE: "Belgique",
  BZ: "Belize",
  BJ: "Bénin",
  BM: "Bermudes",
  BT: "Bhoutan",
  BO: "Bolivie",
  BA: "Bosnie-Herzégovine",
  BW: "Botswana",
  BR: "Brésil",
  BN: "Brunéi Darussalam",
  BG: "Bulgarie",
  BF: "Burkina Faso",
  BI: "Burundi",
  KH: "Cambodge",
  CM: "Cameroun",
  CA: "Canada",
  CV: "Cap-Vert",
  EA: "Ceuta et Melilla",
  CL: "Chili",
  CN: "Chine",
  CY: "Chypre",
  CO: "Colombie",
  KM: "Comores",
  CG: "Congo-Brazzaville",
  KP: "Corée du Nord",
  KR: "Corée du Sud",
  CR: "Costa Rica",
  CI: "Côte d’Ivoire",
  HR: "Croatie",
  CU: "Cuba",
  DK: "Danemark",
  DG: "Diego Garcia",
  DJ: "Djibouti",
  DM: "Dominique",
  EG: "Égypte",
  SV: "El Salvador",
  AE: "Émirats arabes unis",
  EC: "Équateur",
  ER: "Érythrée",
  ES: "Espagne",
  EE: "Estonie",
  VA: "État de la Cité du Vatican",
  FM: "États fédérés de Micronésie",
  US: "États-Unis",
  ET: "Éthiopie",
  FJ: "Fidji",
  FI: "Finlande",
  FR: "France",
  GA: "Gabon",
  GM: "Gambie",
  GE: "Géorgie",
  GS: "Géorgie du Sud et les îles Sandwich du Sud",
  GH: "Ghana",
  GI: "Gibraltar",
  GR: "Grèce",
  GD: "Grenade",
  GL: "Groenland",
  GP: "Guadeloupe",
  GU: "Guam",
  GT: "Guatemala",
  GG: "Guernesey",
  GN: "Guinée",
  GQ: "Guinée équatoriale",
  GW: "Guinée-Bissau",
  GY: "Guyana",
  GF: "Guyane française",
  HT: "Haïti",
  HN: "Honduras",
  HU: "Hongrie",
  BV: "Île Bouvet",
  CX: "Île Christmas",
  CP: "Île Clipperton",
  AC: "Île de l'Ascension",
  IM: "Île de Man",
  NF: "Île Norfolk",
  AX: "Îles Åland",
  KY: "Îles Caïmans",
  IC: "Îles Canaries",
  CC: "Îles Cocos - Keeling",
  CK: "Îles Cook",
  FO: "Îles Féroé",
  HM: "Îles Heard et MacDonald",
  FK: "Îles Malouines",
  MP: "Îles Mariannes du Nord",
  MH: "Îles Marshall",
  UM: "Îles Mineures Éloignées des États-Unis",
  SB: "Îles Salomon",
  TC: "Îles Turks et Caïques",
  VG: "Îles Vierges britanniques",
  VI: "Îles Vierges des États-Unis",
  IN: "Inde",
  ID: "Indonésie",
  IQ: "Irak",
  IR: "Iran",
  IE: "Irlande",
  IS: "Islande",
  IL: "Israël",
  IT: "Italie",
  JM: "Jamaïque",
  JP: "Japon",
  JE: "Jersey",
  JO: "Jordanie",
  KZ: "Kazakhstan",
  KE: "Kenya",
  KG: "Kirghizistan",
  KI: "Kiribati",
  KW: "Koweït",
  LA: "Laos",
  LS: "Lesotho",
  LV: "Lettonie",
  LB: "Liban",
  LR: "Libéria",
  LY: "Libye",
  LI: "Liechtenstein",
  LT: "Lituanie",
  LU: "Luxembourg",
  MK: "Macédoine",
  MG: "Madagascar",
  MY: "Malaisie",
  MW: "Malawi",
  MV: "Maldives",
  ML: "Mali",
  MT: "Malte",
  MA: "Maroc",
  MQ: "Martinique",
  MU: "Maurice",
  MR: "Mauritanie",
  YT: "Mayotte",
  MX: "Mexique",
  MD: "Moldavie",
  MC: "Monaco",
  MN: "Mongolie",
  ME: "Monténégro",
  MS: "Montserrat",
  MZ: "Mozambique",
  MM: "Myanmar",
  NA: "Namibie",
  NR: "Nauru",
  NP: "Népal",
  NI: "Nicaragua",
  NE: "Niger",
  NG: "Nigéria",
  NU: "Niue",
  NO: "Norvège",
  NC: "Nouvelle-Calédonie",
  NZ: "Nouvelle-Zélande",
  OM: "Oman",
  UG: "Ouganda",
  UZ: "Ouzbékistan",
  PK: "Pakistan",
  PW: "Palaos",
  PA: "Panama",
  PG: "Papouasie-Nouvelle-Guinée",
  PY: "Paraguay",
  NL: "Pays-Bas",
  PE: "Pérou",
  PH: "Philippines",
  PN: "Pitcairn",
  PL: "Pologne",
  PF: "Polynésie française",
  PR: "Porto Rico",
  PT: "Portugal",
  QA: "Qatar",
  HK: "R.A.S. chinoise de Hong Kong",
  MO: "R.A.S. chinoise de Macao",
  QO: "régions éloignées de l’Océanie",
  CF: "République centrafricaine",
  CD: "République démocratique du Congo",
  DO: "République dominicaine",
  CZ: "République tchèque",
  RE: "Réunion",
  RO: "Roumanie",
  GB: "Royaume-Uni",
  RU: "Russie",
  RW: "Rwanda",
  EH: "Sahara occidental",
  BL: "Saint-Barthélémy",
  KN: "Saint-Kitts-et-Nevis",
  SM: "Saint-Marin",
  MF: "Saint-Martin",
  PM: "Saint-Pierre-et-Miquelon",
  VC: "Saint-Vincent-et-les Grenadines",
  SH: "Sainte-Hélène",
  LC: "Sainte-Lucie",
  WS: "Samoa",
  AS: "Samoa américaines",
  ST: "Sao Tomé-et-Principe",
  SN: "Sénégal",
  RS: "Serbie",
  CS: "Serbie-et-Monténégro",
  SC: "Seychelles",
  SL: "Sierra Leone",
  SG: "Singapour",
  SK: "Slovaquie",
  SI: "Slovénie",
  SO: "Somalie",
  SD: "Soudan",
  LK: "Sri Lanka",
  SE: "Suède",
  CH: "Suisse",
  SR: "Suriname",
  SJ: "Svalbard et Île Jan Mayen",
  SZ: "Swaziland",
  SY: "Syrie",
  TJ: "Tadjikistan",
  TW: "Taïwan",
  TZ: "Tanzanie",
  TD: "Tchad",
  TF: "Terres australes françaises",
  IO: "Territoire britannique de l'océan Indien",
  PS: "Territoire palestinien",
  TH: "Thaïlande",
  TL: "Timor oriental",
  TG: "Togo",
  TK: "Tokelau",
  TO: "Tonga",
  TT: "Trinité-et-Tobago",
  TA: "Tristan da Cunha",
  TN: "Tunisie",
  TM: "Turkménistan",
  TR: "Turquie",
  TV: "Tuvalu",
  UA: "Ukraine",
  EU: "Union européenne",
  UY: "Uruguay",
  VU: "Vanuatu",
  VE: "Venezuela",
  VN: "Viêt Nam",
  WF: "Wallis-et-Futuna",
  YE: "Yémen",
  ZM: "Zambie",
  ZW: "Zimbabwe",
};

export default NAMES;

export function getCountryCode(countryName) {
  return Object.keys(NAMES).find(
    (k) => NAMES[k].toLowerCase() === countryName.toLowerCase()
  );
}

export function getCountryName(countryCode) {
  const name = NAMES[countryCode.toUpperCase()];

  return name || null;
}

export const NATIONALITIES = [
  { id: "AFG", name: "Afghane" },
  { id: "ALB", name: "Albanaise" },
  { id: "DZA", name: "Algérienne" },
  { id: "DEU", name: "Allemande" },
  { id: "USA", name: "Americaine" },
  { id: "AND", name: "Andorrane" },
  { id: "AGO", name: "Angolaise" },
  { id: "ATG", name: "Antiguaise-et-Barbudienne" },
  { id: "ARG", name: "Argentine" },
  { id: "ARM", name: "Armenienne" },
  { id: "AUS", name: "Australienne" },
  { id: "AUT", name: "Autrichienne" },
  { id: "AZE", name: "Azerbaïdjanaise" },
  { id: "BHS", name: "Bahamienne" },
  { id: "BHR", name: "Bahreinienne" },
  { id: "BGD", name: "Bangladaise" },
  { id: "BRB", name: "Barbadienne" },
  { id: "BEL", name: "Belge" },
  { id: "BLZ", name: "Belizienne" },
  { id: "BEN", name: "Béninoise" },
  { id: "BTN", name: "Bhoutanaise" },
  { id: "BLR", name: "Biélorusse" },
  { id: "MMR", name: "Birmane" },
  { id: "GNB", name: "Bissau-Guinéenne" },
  { id: "BOL", name: "Bolivienne" },
  { id: "BIH", name: "Bosnienne" },
  { id: "BWA", name: "Botswanaise" },
  { id: "BRA", name: "Brésilienne" },
  { id: "GBR", name: "Britannique" },
  { id: "BRN", name: "Brunéienne" },
  { id: "BGR", name: "Bulgare" },
  { id: "BFA", name: "Burkinabée" },
  { id: "BDI", name: "Burundaise" },
  { id: "KHM", name: "Cambodgienne" },
  { id: "CMR", name: "Camerounaise" },
  { id: "CAN", name: "Canadienne" },
  { id: "CPV", name: "Cap-verdienne" },
  { id: "CAF", name: "Centrafricaine" },
  { id: "CHL", name: "Chilienne" },
  { id: "CHN", name: "Chinoise" },
  { id: "CYP", name: "Chypriote" },
  { id: "COL", name: "Colombienne" },
  { id: "COM", name: "Comorienne" },
  { id: "COG", name: "Congolaise" },
  { id: "COD", name: "Congolaise" },
  { id: "COK", name: "Cookienne" },
  { id: "CRI", name: "Costaricaine" },
  { id: "HRV", name: "Croate" },
  { id: "CUB", name: "Cubaine" },
  { id: "DNK", name: "Danoise" },
  { id: "DJI", name: "Djiboutienne" },
  { id: "DOM", name: "Dominicaine" },
  { id: "DMA", name: "Dominiquaise" },
  { id: "EGY", name: "Égyptienne" },
  { id: "ARE", name: "Émirienne" },
  { id: "GNQ", name: "Équato-guineenne" },
  { id: "ECU", name: "Équatorienne" },
  { id: "ERI", name: "Érythréenne" },
  { id: "ESP", name: "Espagnole" },
  { id: "TLS", name: "Est-timoraise" },
  { id: "EST", name: "Estonienne" },
  { id: "ETH", name: "Éthiopienne" },
  { id: "FJI", name: "Fidjienne" },
  { id: "FIN", name: "Finlandaise" },
  { id: "FRA", name: "Française" },
  { id: "GAB", name: "Gabonaise" },
  { id: "GMB", name: "Gambienne" },
  { id: "GEO", name: "Georgienne" },
  { id: "GHA", name: "Ghanéenne" },
  { id: "GRD", name: "Grenadienne" },
  { id: "GTM", name: "Guatémaltèque" },
  { id: "GIN", name: "Guinéenne" },
  { id: "GUY", name: "Guyanienne" },
  { id: "HTI", name: "Haïtienne" },
  { id: "GRC", name: "Hellénique" },
  { id: "HND", name: "Hondurienne" },
  { id: "HUN", name: "Hongroise" },
  { id: "IND", name: "Indienne" },
  { id: "IDN", name: "Indonésienne" },
  { id: "IRQ", name: "Irakienne" },
  { id: "IRN", name: "Iranienne" },
  { id: "IRL", name: "Irlandaise" },
  { id: "ISL", name: "Islandaise" },
  { id: "ISR", name: "Israélienne" },
  { id: "ITA", name: "Italienne" },
  { id: "CIV", name: "Ivoirienne" },
  { id: "JAM", name: "Jamaïcaine" },
  { id: "JPN", name: "Japonaise" },
  { id: "JOR", name: "Jordanienne" },
  { id: "KAZ", name: "Kazakhstanaise" },
  { id: "KEN", name: "Kenyane" },
  { id: "KGZ", name: "Kirghize" },
  { id: "KIR", name: "Kiribatienne" },
  { id: "KNA", name: "Kittitienne et Névicienne" },
  { id: "KWT", name: "Koweïtienne" },
  { id: "LAO", name: "Laotienne" },
  { id: "LSO", name: "Lesothane" },
  { id: "LVA", name: "Lettone" },
  { id: "LBN", name: "Libanaise" },
  { id: "LBR", name: "Libérienne" },
  { id: "LBY", name: "Libyenne" },
  { id: "LIE", name: "Liechtensteinoise" },
  { id: "LTU", name: "Lituanienne" },
  { id: "LUX", name: "Luxembourgeoise" },
  { id: "MKD", name: "Macédonienne" },
  { id: "MYS", name: "Malaisienne" },
  { id: "MWI", name: "Malawienne" },
  { id: "MDV", name: "Maldivienne" },
  { id: "MDG", name: "Malgache" },
  { id: "MLI", name: "Maliennes" },
  { id: "MLT", name: "Maltaise" },
  { id: "MAR", name: "Marocaine" },
  { id: "MHL", name: "Marshallaise" },
  { id: "MUS", name: "Mauricienne" },
  { id: "MRT", name: "Mauritanienne" },
  { id: "MEX", name: "Mexicaine" },
  { id: "FSM", name: "Micronésienne" },
  { id: "MDA", name: "Moldave" },
  { id: "MCO", name: "Monegasque" },
  { id: "MNG", name: "Mongole" },
  { id: "MNE", name: "Monténégrine" },
  { id: "MOZ", name: "Mozambicaine" },
  { id: "NAM", name: "Namibienne" },
  { id: "NRU", name: "Nauruane" },
  { id: "NLD", name: "Néerlandaise" },
  { id: "NZL", name: "Néo-Zélandaise" },
  { id: "NPL", name: "Népalaise" },
  { id: "NIC", name: "Nicaraguayenne" },
  { id: "NGA", name: "Nigériane" },
  { id: "NER", name: "Nigérienne" },
  { id: "NIU", name: "Niuéenne" },
  { id: "PRK", name: "Nord-coréenne" },
  { id: "NOR", name: "Norvégienne" },
  { id: "OMN", name: "Omanaise" },
  { id: "UGA", name: "Ougandaise" },
  { id: "UZB", name: "Ouzbéke" },
  { id: "PAK", name: "Pakistanaise" },
  { id: "PLW", name: "Palaosienne" },
  { id: "PSE", name: "Palestinienne" },
  { id: "PAN", name: "Panaméenne" },
  { id: "PNG", name: "Papouane-Néo-Guinéenne" },
  { id: "PRY", name: "Paraguayenne" },
  { id: "PER", name: "Péruvienne" },
  { id: "PHL", name: "Philippine" },
  { id: "POL", name: "Polonaise" },
  { id: "PRT", name: "Portugaise" },
  { id: "QAT", name: "Qatarienne" },
  { id: "ROU", name: "Roumaine" },
  { id: "RUS", name: "Russe" },
  { id: "RWA", name: "Rwandaise" },
  { id: "LCA", name: "Saint-Lucienne" },
  { id: "SMR", name: "Saint-Marinaise" },
  { id: "VCT", name: "Saint-Vincentaise et Grenadine" },
  { id: "SLB", name: "Salomonaise" },
  { id: "SLV", name: "Salvadorienne" },
  { id: "WSM", name: "Samoane" },
  { id: "STP", name: "Santoméenne" },
  { id: "SAU", name: "Saoudienne" },
  { id: "SEN", name: "Sénégalaise" },
  { id: "SRB", name: "Serbe" },
  { id: "SYC", name: "Seychelloise" },
  { id: "SLE", name: "Sierra-Léonaise" },
  { id: "SGP", name: "Singapourienne" },
  { id: "SVK", name: "Slovaque" },
  { id: "SVN", name: "Slovène" },
  { id: "SOM", name: "Somalienne" },
  { id: "SDN", name: "Soudanaise" },
  { id: "LKA", name: "Sri-Lankaise" },
  { id: "ZAF", name: "Sud-Africaine" },
  { id: "KOR", name: "Sud-Coréenne" },
  { id: "SSD", name: "Sud-Soudanaise" },
  { id: "SWE", name: "Suédoise" },
  { id: "CHE", name: "Suisse" },
  { id: "SUR", name: "Surinamaise" },
  { id: "SWZ", name: "Swazie" },
  { id: "SYR", name: "Syrienne" },
  { id: "TJK", name: "Tadjike" },
  { id: "TZA", name: "Tanzanienne" },
  { id: "TCD", name: "Tchadienne" },
  { id: "CZE", name: "Tchèque" },
  { id: "THA", name: "Thaïlandaise" },
  { id: "TGO", name: "Togolaise" },
  { id: "TON", name: "Tonguienne" },
  { id: "TTO", name: "Trinidadienne" },
  { id: "TUN", name: "Tunisienne" },
  { id: "TKM", name: "Turkmène" },
  { id: "TUR", name: "Turque" },
  { id: "TUV", name: "Tuvaluane" },
  { id: "UKR", name: "Ukrainienne" },
  { id: "URY", name: "Uruguayenne" },
  { id: "VUT", name: "Vanuatuane" },
  { id: "VAT", name: "Vaticane" },
  { id: "VEN", name: "Vénézuélienne" },
  { id: "VNM", name: "Vietnamienne" },
  { id: "YEM", name: "Yéménite" },
  { id: "ZMB", name: "Zambienne" },
  { id: "ZWE", name: "Zimbabwéenne" },
];
