import React from "react";
import { Filter, TextInput } from "react-admin";

export default (props) => (
  <Filter {...props}>
    <TextInput label="Dénomination sociale" source="denominationSociale" />
    <TextInput label="Code interbancaire" source="codeInterbancaire" />
    <TextInput label="Code LEI" source="codeLEI" />
  </Filter>
);

