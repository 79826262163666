import { Box } from "@material-ui/core";
import React from "react";
import { FormWithRedirect, required, Toolbar } from "react-admin";
import TextInputCustom from "../../components/input/TextInputCustom";

export default (props) => (
  <FormWithRedirect
    {...props}
    render={(formProps) => {
      return (
        <form>
          <Box p="1em" maxWidth="72em">
            <Box>
              <TextInputCustom
                label="Nom de domaine"
                source="domain"
                validate={required()}
              />
            </Box>
          </Box>
          <Toolbar
            record={formProps.record}
            basePath={formProps.basePath}
            undoable={false}
            invalid={formProps.invalid}
            handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
            saving={formProps.saving}
            pristine={formProps.pristine}
            submitOnEnter={false}
            resource="domain_names"
            redirect="list"
          />
        </form>
      );
    }}
  />
);
