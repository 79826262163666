import React, { useCallback, useRef, useState } from "react";
import { Create, Button } from "react-admin";
import { ErrorBoundary } from "../lib/error-report";
import CandidatForm from "./form/CandidatForm";
import PostCreateActions from "../components/custom/PostCreateActions";
import { CreateReportModal } from "../bugreport/Create";
import { DisconnectionAlert } from "../components/DisconnectionAlert";

export default (props) => {
  const [showModal, setShowModal] = useState(false);
  const form = useRef({});

  const onFormChange = useCallback((f) => {
    form.current = f;
  }, []);
  return (
    <ErrorBoundary>
      <DisconnectionAlert />
      <Create
        {...props}
        actions={
          <PostCreateActions>
            <Button
              label="Signaler un incident"
              onClick={() => setShowModal(true)}
            />
          </PostCreateActions>
        }
      >
        <CandidatForm redirect="edit" onFormChange={onFormChange} />
      </Create>
      <CreateReportModal
        {...props}
        open={showModal}
        onClose={() => setShowModal(false)}
        formState={form}
      />
    </ErrorBoundary>
  );
};
