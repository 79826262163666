export default {
  Interne: "Interne",
  Externe: "Externe",
  Groupe: "Groupe",
  senior: "Poste de très haut niveau",
  high: "Poste de haut niveau",
  managerial: "Autre poste de direction",
  other: "Autre",
  "La personne nommée atteint-elle les seuils ?":
    "La personne nommée / personne à nommer atteint-elle les seuils permettant de présumer une expérience suffisante ?",
};
