import React from "react";
import {
  Datagrid,
  DateField,
  List,
  TextField,
  TopToolbar,
  CreateButton,
  useListContext,
} from "react-admin";

import Box from "@material-ui/core/Box";
import InboxIcon from "@material-ui/icons/Inbox";
import Typography from "@material-ui/core/Typography";

import BulkActionButtons from "../components/BulkActionButtons";
import ImportButton from "../components/ImportButton";
import ExportButton from "../components/ExportButton";

import EntiteFilter from "./EntiteFilter";
import { ErrorBoundary } from "../lib/error-report";

const { REACT_APP_API_URL } = process.env;

const EntiteActions = ({
  bulkActions,
  basePath,
  displayedFilters,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
}) => (
  <TopToolbar>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button",
      })}
    <CreateButton basePath={basePath} />
    {/* Add your custom actions */}
    <ImportButton
      title="Importer les entités"
      message="Un seul fichier. Au format Excel (.xlsx)"
      name="entites"
      method="POST"
      url={`${REACT_APP_API_URL}/entites/import`}
      accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    />
    <ExportButton
      method="GET"
      url={`${REACT_APP_API_URL}/entites/export`}
      accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    />
  </TopToolbar>
);

function Empty() {
  const { basePath } = useListContext();
  return (
    <Box textAlign="center" m={1}>
      <Box display="inline-box" style={{ opacity: 0.5 }}>
        <InboxIcon style={{ fontSize: "12em" }} />
        <Typography variant="h4" paragraph>
          Pas encore d’entités
        </Typography>
        <Typography variant="body1">
          Souhaitez-vous en créer ou en importer&#x202F;?
        </Typography>
      </Box>

      <Box display="flex" width="100%" justifyContent="center" mt="4em">
        <ImportButton
          title="Importer les entités"
          message="Un seul fichier. Au format Excel (.xlsx)"
          name="entites"
          method="POST"
          url={`${REACT_APP_API_URL}/entites/import`}
          accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        />
        <Box ml="2em">
          <CreateButton basePath={basePath} variant="contained" />
        </Box>
      </Box>
    </Box>
  );
}

export default (props) => (
  <ErrorBoundary>
    <List
      actions={<EntiteActions />}
      bulkActionButtons={<BulkActionButtons />}
      {...props}
      title="Entités"
      empty={<Empty />}
      sort={{ field: "denominationSociale", order: "ASC" }}
      filters={<EntiteFilter />}
      exporter={false}
    >
      <Datagrid rowClick="edit">
        <TextField label="Dénomination sociale" source="denominationSociale" />
        <TextField label="Code interbancaire" source="codeInterbancaire" />
        <TextField label="Code LEI" source="codeLEI" />
        <DateField
          label="Mis à jour le"
          source="updatedAt"
          showTime
          locales="fr-FR"
        />
      </Datagrid>
    </List>
  </ErrorBoundary>
);
