import React, { memo, useEffect, useRef } from "react";
import { useField } from "react-final-form";
import { ArrayInput, required } from "react-admin";
import { Box, Typography, Divider } from "@material-ui/core";
import NumberInputCustom from "../../components/input/NumberInputCustom";
import TranslatableTextInputCustom from "../../components/input/TranslatableTextInputCustom";
import DateInputCustom from "../../components/input/DateInputCustom";
import SelectInputCustom from "../../components/input/SelectInputCustom";
import FormIteratorCustom from "../../components/custom/FormIteratorCustom";
import InfoBulle from "../../components/InfoBulle";
import TranslationInformation from "../../components/TranslationInformation";

import { Enum } from "../../utils";

const HierarchicalLevel = Enum({
  Senior: "senior",
  High: "high",
  Mangerial: "manegerial",
  Other: "other",
});

const HIERARCHICAL_LEVEL_CHOICES = [
  { id: HierarchicalLevel.Senior, name: "Poste de tres haut niveau" },
  { id: HierarchicalLevel.High, name: "Poste de haut niveau" },
  { id: HierarchicalLevel.Mangerial, name: "Autre poste de direction" },
  { id: HierarchicalLevel.Other, name: "Autre" },
];

const Experience = memo(({ name, tabName, disabled, isBanking }) => {
  const { input: dateFin } = useField(`${name}.dateFin`);

  const mounted = useRef(false);

  useEffect(() => {
    if (mounted.current) return;

    if (dateFin) {
      dateFin.onBlur();
    }

    mounted.current = true;
  }, [dateFin]);

  return (
    <Box style={{ maxWidth: "49em" }}>
      <TranslatableTextInputCustom
        disabled={disabled}
        label="Fonction/Mandat"
        source={`${name}.fonction`}
        id={`${tabName}#${name}.fonction`}
        validate={required()}
      />
      <TranslatableTextInputCustom
        disabled={disabled}
        multiline
        label="Responsabilités principales"
        source={`${name}.responsabilitesPrincipales`}
        id={`${tabName}#${name}.responsabilitesPrincipales`}
        fullWidth
      />
      <Box display="flex">
        <Box>
          {isBanking ? (
            <SelectInputCustom
              disabled={disabled}
              label="Rang/Niveau hiérarchique du poste"
              source={`${name}.niveauHierarchique`}
              id={`${tabName}#${name}.niveauHierarchique`}
              choices={HIERARCHICAL_LEVEL_CHOICES}
            />
          ) : (
            <TranslatableTextInputCustom
              disabled={disabled}
              label="Rang/Niveau hiérarchique du poste"
              source={`${name}.niveauHierarchiqueText`}
              id={`${tabName}#${name}.niveauHierarchiqueText`}
            />
          )}
        </Box>
        <Box ml="1em">
          <TranslatableTextInputCustom
            disabled={disabled}
            label="Organisation, entreprise, etc..."
            source={`${name}.organisation`}
            id={`${tabName}#${name}.organisation`}
            helperText="Dans laquelle le mandat est exercé"
          />
        </Box>
      </Box>
      <Box display="flex">
        <Box display="flex">
          <TranslatableTextInputCustom
            disabled={disabled}
            label="Taille"
            helperText="en millions d’euros"
            source={`${name}.taille`}
            id={`${tabName}#${name}.taille`}
          />
          <InfoBulle position="right" mt="0.5em" ml="0.5em">
            Est entendu par taille: total de bilan (social ou, s’il existe,
            consolidé), chiffre d’affaires, résultat net, nombre d’employés,
            présence géographique…
          </InfoBulle>
        </Box>
        <Box ml="1em">
          <NumberInputCustom
            disabled={disabled}
            label="Nombre d’employés"
            source={`${name}.nombreEmployes`}
            id={`${tabName}#${name}.nombreEmployes`}
            helperText="placés sous sa responsabilité"
          />
        </Box>
      </Box>

      <TranslatableTextInputCustom
        disabled={disabled}
        multiline
        label="Domaines d’expertise et principales responsabilités"
        source={`${name}.domainesExpertise`}
        id={`${tabName}#${name}.domainesExpertise`}
        fullWidth
      />
      <Box display="flex">
        <Box>
          <DateInputCustom
            disabled={disabled}
            label="Date de début"
            source={`${name}.dateDebut`}
          />
        </Box>
        <Box ml="1em">
          <DateInputCustom
            disabled={disabled}
            id={`${tabName}#${name}.dateFin`}
            label="Date de fin"
            source={`${name}.dateFin`}
            validate={(value) => {
              try {
                /* eslint-disable-next-line no-eval */
                const dateDebut = eval(`allValues?.${name}?.dateDebut`);

                return (
                  dateDebut > value &&
                  "La date de fin doit être postérieure à la date de début"
                );
              } catch (_) {
                return;
              }
            }}
          />
        </Box>
        <InfoBulle position="right" mt="0.5em" ml="0.5em">
          Si le jour et le mois ne sont pas connus, il est convenu d’écrire
          01/01/AAAA
        </InfoBulle>
      </Box>

      <Box display="flex">
        <TranslatableTextInputCustom
          disabled={disabled}
          multiline
          label="Motif de la fin du contrat/mandat"
          source={`${name}.motifFin`}
          id={`${tabName}#${name}.motifFin`}
          fullWidth
        />
        <InfoBulle position="right" mt="0.5em" ml="0.5em">
          Dans le cas où le contrat/mandat est toujours en cours, il convient
          d'indiquer «&#x202F;en cours&#x202F;» dans le champs motif de fin de
          contrat/mandat.
        </InfoBulle>
      </Box>
    </Box>
  );
});

export default memo(({ tabName, disabled }) => {
  return (
    <Box>
      <TranslationInformation />
      <Box mt="1em" />

      <Typography variant="h6">
        Expériences dans le domaine bancaire/financier au cours des dix
        dernières années&nbsp;:
      </Typography>
      <ArrayInput source="candidatExperienceBancaire" label="">
        <FormIteratorCustom
          disabled={disabled}
          component={<Experience tabName={tabName} isBanking />}
        />
      </ArrayInput>
      <Divider />

      <Box mt="1em" />

      <Box display="flex">
        <Typography variant="h6">
          Autres expériences pertinentes à un poste de « cadre dirigeant » hors
          du secteur financier au cours des dix dernières années&nbsp;:
        </Typography>
        <InfoBulle position="right" mt="0.5em" ml="0.5em">
          Le poste de «&#x202F;cadre dirigeant&#x202F;» s’entend d’une fonction
          de membre d’un organe de direction (exécutif ou non exécutif) ou d’un
          cadre dirigeant situé un à deux niveaux en dessous de ladite fonction.
          <br />
          –
          <br />
          Pour les expériences les plus anciennes, l’information sur la taille
          n'est pas nécessaire (car jugée non pertinente). Dans ce cas, indiquer
          «&#x202F;information non disponible&#x202F;» ou Non Spécifié.
        </InfoBulle>
      </Box>
      <ArrayInput source="candidatExperienceDirigeant" label="">
        <FormIteratorCustom
          disabled={disabled}
          component={<Experience tabName={tabName} />}
        />
      </ArrayInput>
      <Divider />

      <Box mt="1em" />

      <Box display="flex">
        <Typography variant="h6">
          Autres expériences pertinentes hors du secteur financier au cours des
          dix dernières années (ex.&nbsp;: milieu universitaire, services
          juridiques, informatique, ingénierie, ressources humaines, mandat
          électif, autres fonctions, etc…)&nbsp;:
        </Typography>
      </Box>
      <ArrayInput source="candidatExperienceAutre" label="">
        <FormIteratorCustom
          disabled={disabled}
          component={<Experience tabName={tabName} />}
        />
      </ArrayInput>
      <Divider />

      <Box mt="1em" />
      <Box display="flex">
        <TranslatableTextInputCustom
          disabled={disabled}
          multiline
          label="Autres experiences specifiques"
          source="experiencesAutre"
          id={`${tabName}#experiencesAutre`}
          fullWidth
        />
        <InfoBulle position="right" mt="0.5em" ml="0.5em">
          Il s’agit de renseigner les compétences transversales découlant de
          l’ensemble des expériences professionnelles du candidat
        </InfoBulle>
      </Box>
    </Box>
  );
});
