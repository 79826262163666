import React from "react";
import { SelectInput } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { useOnlyForTargets } from "../../candidatures/PortalTarget";

const useStyles = makeStyles({
  selectCustom: (props) => ({
    minWidth: props.width || "18em",
  }),
});

export default React.forwardRef(({ portalTargets, ...props }, ref) => {
  const classes = useStyles(props);

  const disabled = useOnlyForTargets(portalTargets, props.disabled);

  return (
    <SelectInput
      ref={ref}
      {...props}
      disabled={disabled}
      label={props.label || " "}
      className={`${classes.selectCustom} ${props.className}`}
    />
  );
});
