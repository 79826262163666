import React from "react";
import { useNotify } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

import mime from "mime-types";

import { authedFetch } from "../authProvider";

const { REACT_APP_API_URL } = process.env;

const ALLOW_TYPES = [
  mime.lookup("jpeg"),
  mime.lookup("jpg"),
  mime.lookup("png"),
  mime.lookup("docx"),
  mime.lookup("doc"),
  mime.lookup("xls"),
  mime.lookup("xlsx"),
  mime.lookup("ppt"),
  mime.lookup("pptx"),
  mime.lookup("pdf"),
];

const useStyles = makeStyles({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  item: {
    marginTop: "2px",
    // width: "100%",
    backgroundColor: "#eee",
    boderRadius: "4px",
    padding: "0.1em",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  dir: {
    marginTop: "0.2em",
    marginLeft: "0.4em",
  },
  text: {
    margin: "0 0.4em",
  },
  del: {
    marginLeft: "0.6em",
    marginRight: "0.4em",
    color: "red",
    fontSize: "1.3em",
  },
});

export default ({ value, source, onChange, disabled }) => {
  const notify = useNotify();
  const downloadFile = async (id, name) => {
    const response = await authedFetch(
      `${REACT_APP_API_URL}/candidats/download-file/${id}`
    );
    if (response) {
      const fileType = ALLOW_TYPES.find((type) => {
        const contentType =
          response.headers.get("Content-Type").split(";")[0] || "";

        return contentType === type;
      });
      if (fileType) {
        const blob = await response.blob();
        const fileUrl =
          typeof window !== "undefined" && window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = fileUrl;
        // the filename you want
        a.download = `${name}`;
        document.body.appendChild(a);
        a.click();

        typeof window !== "undefined" && window.URL.revokeObjectURL(fileUrl);
      }
    }
  };

  if (!Array.isArray(value) && value.id) {
    value = [value];
  }

  const filesRemoveOne = async (file) => {
    if (window.confirm("Êtes vous sûr de vouloir supprimer le fichier ?")) {
      const body = JSON.stringify({ fileId: file.id });

      const results = await authedFetch(
        `${REACT_APP_API_URL}/candidatures/remove-file`,
        {
          method: "POST",
          body,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      if (results) {
        notify("Fichier supprimé");
      } else {
        notify("Le fichier n’a pas pu être supprimé");
      }
      onChange(value.filter((f) => f.id !== file.id));
    }
  };

  const classes = useStyles();

  return (
    <>
      {value &&
        value.map((v, i) => (
          <div
            key={`${v.name}-${i}`}
            className={classes.container}
            style={{ display: "flex", flexDirection: "row" }}
          >
            <div
              className={classes.item}
              style={{ fontSize: 16, cursor: "pointer" }}
              onClick={() => downloadFile(v.id, v.name)}
            >
              <span className={classes.dir} role="img" aria-label="">
                📁
              </span>
              <span className={classes.text}>{v.name}</span>
              {!disabled && (
                <span
                  id={value.id}
                  className={classes.del}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    filesRemoveOne(v);
                  }}
                >
                  x
                </span>
              )}
            </div>
          </div>
        ))}
    </>
  );
};
