const FORBIDDEN_CHARS = ["&", "/", "€", "%", "«", "»", '"'];

export function noSpecialCharacters(
  message = 'Les caractères &, /, €, «, », " et % ne sont pas permis'
) {
  return function (value, allValues) {
    if (
      value &&
      typeof value === "string" &&
      FORBIDDEN_CHARS.some((f) => value.includes(f))
    ) {
      return message;
    }

    return;
  };
}
