import React, { useMemo } from "react";

import { NumberInput } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { useOnlyForTargets } from "../../candidatures/PortalTarget";

const useStyles = makeStyles({
  numberCustom: (props) => ({
    minWidth: props.width || "18em",
  }),
});

const defaultValidate = (value /* , allValues */) => {
  if (isNaN(value)) {
    return "Une valeur numérique est attendue";
  }
};

export default React.forwardRef(({ portalTargets, ...props }, ref) => {
  const { validate: parentValidate } = props;
  const classes = useStyles(props);

  const disabled = useOnlyForTargets(portalTargets, props.disabled);

  const validate = useMemo(() => {
    if (!parentValidate) return [defaultValidate];

    if (!Array.isArray(parentValidate)) {
      return [defaultValidate, parentValidate];
    }

    return [defaultValidate, ...parentValidate];
  }, [parentValidate]);

  return (
    <NumberInput
      ref={ref}
      {...props}
      disabled={disabled}
      min={0}
      label={props.label || " "}
      className={`${classes.numberCustom} ${props.className}`}
      validate={validate}
      format={(value) => parseFloat(value, 10)}
    />
  );
});
