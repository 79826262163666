import React from "react";

import { useLocation } from "react-router-dom";
import { TopToolbar, ListButton, Link } from "react-admin";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ChevronLeft from "@material-ui/icons/ChevronLeft";

function listWrap(candidate) {
  if (!candidate) return [];
  if (Array.isArray(candidate)) return candidate;

  return [candidate];
}

/**
 * The bachTo business is to allow (for Demandes d’accès),
 * to redirect to something else than the list.
 *
 * A backto search query param allows the override
 */
const PostEditActions = ({ basePath, data, children }) => {
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const backTo = search.get("backto");

  const justifyContent =
    listWrap(children).length === 0 ? "flex-start" : "space-between";

  return (
    <TopToolbar style={{ justifyContent }}>
      {backTo ? (
        <Button
          startIcon={<ChevronLeft />}
          component={Link}
          to={backTo}
          color="primary"
        >
          Retour
        </Button>
      ) : (
        <ListButton basePath={basePath} label="Retour" icon={<ChevronLeft />} />
      )}
      <Box display="flex">{children}</Box>
    </TopToolbar>
  );
};

export default PostEditActions;
