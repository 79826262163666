import React from "react";
import { Datagrid, DateField, EmailField, List, TextField } from "react-admin";
import BulkActionButtons from "../components/BulkActionButtons";
import UserFilter from "./UserFilter";

export default (props) => (
  <List
    bulkActionButtons={<BulkActionButtons />}
    {...props}
    title="Utilisateurs"
    sort={{ field: "lastName", order: "ASC" }}
    filters={<UserFilter />}
    exporter={false}
  >
    <Datagrid rowClick="edit">
      <TextField label="Nom" source="lastName" />
      <TextField label="Prénom" source="firstName" />
      <EmailField source="email" />
      <DateField
        label="Mis à jour le"
        source="updatedAt"
        showTime
        locales="fr-FR"
      />
    </Datagrid>
  </List>
);
