import React from "react";
import {
  Create,
  email,
  ReferenceArrayInput,
  required,
  AutocompleteArrayInput,
  SimpleForm,
  TextInput,
} from "react-admin";

const validateEmail = [required(), email()];

export function transform(data) {
  delete data["entites"];

  if (data.entiteIds) {
    data.userEntites = data.entiteIds.map((entiteId) => ({
      entiteId,
      userId: data.id,
    }));
    delete data["entiteIds"];
  }

  return data;
}

export default (props) => (
  <Create {...props} title="Créer Utilisateur" transform={transform}>
    <SimpleForm redirect="list" submitOnEnter={false}>
      <TextInput label="Prénom" source="firstName" validate={required()} />
      <TextInput label="Nom" source="lastName" validate={required()} />
      <TextInput source="email" validate={validateEmail} />
      <ReferenceArrayInput
        label="Entités"
        source="entiteIds"
        reference="entites"
        perPage={120}
      >
        <AutocompleteArrayInput optionText="denominationSociale" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Create>
);
