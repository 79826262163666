import React from "react";
import { Typography, Box } from "@material-ui/core";
import { FormDataConsumer } from "react-admin";
import TextInputCustom from "../../components/input/TextInputCustom";
import TranslatableTextInputCustom from "../../components/input/TextInputCustom";
import NullableBooleanInputCustom from "../../components/input/NullableBooleanInputCustom";
import SelectInputCustom from "../../components/input/SelectInputCustom";
import InfoBulle from "../../components/InfoBulle";
import { useGlobalDisabled } from "./helper";
import { useLangue } from "../transalations";
import { Langue } from "../../lib/langue";

const EVALUATION_CHOICES = {
  [Langue.Fr]: [
    { id: "significative", name: "Significative" },
    { id: "moyennementSignificative", name: "Moyennement significative" },
    { id: "peuSignificative", name: "Peu significative" },
  ],
  [Langue.En]: [
    { id: "significative", name: "High" },
    { id: "moyennementSignificative", name: "Medium" },
    { id: "peuSignificative", name: "Low" },
  ],
};

/**
 * @todo translate
 */
export default (props) => {
  const { tabName } = props;

  const disabled = useGlobalDisabled();

  const { t, langue } = useLangue();
  const evaluationChoices = EVALUATION_CHOICES[langue];

  return (
    <Box maxWidth="72em">
      {/* START ACPR-ONLY */}
      <Typography variant="subtitle1">
        {t(`Pour apprécier le niveau d’expérience de la personne nommée (renouvelée)
        il sera tenu compte des critères suivants, pour lesquels il convient
        d’apporter toutes les précisions nécessaires :`)}
        <ul>
          <li>
            {t(
              "la nature du poste à responsabilité et le niveau hiérarchique correspondant;"
            )}
          </li>
          <li>
            {t(
              "la nature et la complexité de l’entreprise, y compris en raison de sa structure organisationnelle;"
            )}
          </li>
          <li>
            {t(
              "l’étendue des compétences, pouvoirs décisionnaires et responsabilités, et le nombre de subordonnés."
            )}
          </li>
        </ul>
        {t(
          "Vous pouvez ajouter à ces différents égards, des informations complémentaires :"
        )}
      </Typography>
      <TextInputCustom
        id={`${tabName}#infoComplementairesExperience`}
        portalTargets="acpr"
        disabled={disabled}
        multiline
        source="infoComplementairesExperience"
        fullWidth
      />

      <Typography variant="subtitle1">
        {t(`La personne nommée atteint-elle les seuils ?`)}
      </Typography>
      <NullableBooleanInputCustom
        id={`${tabName}#exigenceExperiencesAdequates`}
        portalTargets="imas"
        disabled={disabled}
        source="exigenceExperiencesAdequates"
      />
      <FormDataConsumer subscription={{ values: true }}>
        {({ formData, ...rest }) => {
          if (false === formData.exigenceExperiencesAdequates) {
            return (
              <>
                <Typography variant="subtitle1">
                  <Box display="flex">
                    <span>
                      {t(
                        "Veuillez répertorier ci-dessous tout facteur complémentaire (ou compensatoire), comme le prévoit le Guide relatif à l’évaluation de l’honorabilité, des connaissances, des compétences et de l’expérience."
                      )}
                    </span>
                    <InfoBulle position="right">
                      Par exemple&nbsp;:
                      <br />
                      la participation à des missions spécifiques&#x202F;;
                      <br />
                      taille de l’entité&#x202F;;
                      <br />
                      autre expérience alternative&#x202F;;
                      <br />
                      diplôme/formation universitaire&#x202F;;
                      <br />
                      capacité d’analyse et de jugement confirmée&#x202F;;{" "}
                      <br />
                      compétence collective des membres de l’organe de direction
                      déjà présents&#x202F;; <br />
                      connaissances spécialisées dont a besoin
                      l’établissement&#x202F;; <br />
                      périmètre de tâches limité de la personne nommée
                      (renouvelée)&#x202F;; <br />
                      autres cas particuliers (ex&nbsp;: exigence légale de
                      nommer des représentants du personnel au sein de l’organe
                      de direction)
                    </InfoBulle>
                  </Box>
                </Typography>
                <TranslatableTextInputCustom
                  id={`${tabName}#exigenceExperiencesAdequatesText`}
                  disabled={disabled}
                  multiline
                  source="exigenceExperiencesAdequatesText"
                  fullWidth
                  portalTargets="imas"
                  {...rest}
                />
              </>
            );
          } else if (formData.exigenceExperiencesAdequates) {
            formData.exigenceExperiencesAdequatesText = null;
          }
        }}
      </FormDataConsumer>
      {/* END ACPR-ONLY */}
      <Typography variant="h6" gutterBottom>
        {t(
          "Domaines requis d’expérience générale dans le secteur bancaire et financier."
        )}
        <br />
        {t(
          "Évaluation au regard, notamment, de la durée de l’expérience acquise, de la taille, de la complexité de l’entreprise au sein de laquelle cette expérience a été acquise :"
        )}
      </Typography>
      {/**
       *
       */}
      <Typography variant="subtitle1">
        {t("Marchés bancaires ou financiers :")}
      </Typography>
      <SelectInputCustom
        disabled={disabled}
        source="evalMarcheBancaire"
        choices={evaluationChoices}
      />
      <TextInputCustom
        disabled={disabled}
        source="evalMarcheBancaireText"
        multiline
        fullWidth
        label="Justification"
        placeholder="Justification"
        portalTargets="imas"
      />
      {/**
       *
       */}
      <Typography variant="subtitle1">
        {t(
          "Exigences légales et réglementaires applicables à un établissement assujetti :"
        )}
      </Typography>
      <SelectInputCustom
        disabled={disabled}
        source="evalExigencesLegales"
        choices={evaluationChoices}
      />
      <TextInputCustom
        disabled={disabled}
        source="evalExigencesLegalesText"
        multiline
        fullWidth
        label="Justification"
        placeholder="Justification"
        portalTargets="imas"
      />
      {/**
       *
       */}
      <Typography variant="subtitle1">
        {t(
          "Lutte contre le blanchiment de capitaux et le financement du terrorisme :"
        )}
      </Typography>
      <SelectInputCustom
        disabled={disabled}
        source="evalBlanchiment"
        choices={evaluationChoices}
        portalTargets="imas"
      />
      <TextInputCustom
        disabled={disabled}
        source="evalBlanchimentText"
        multiline
        fullWidth
        label="Justification"
        placeholder="Justification"
        portalTargets="imas"
      />
      {/**
       *
       */}
      <Typography variant="subtitle1">
        {t(
          "Planification stratégique et compréhension de la stratégie commerciale ou du projet d’affaires (business plan) d’un établissement assujetti et de sa mise en œuvre :"
        )}
      </Typography>
      <SelectInputCustom
        disabled={disabled}
        source="evalPlanification"
        choices={evaluationChoices}
      />
      <TextInputCustom
        disabled={disabled}
        source="evalPlanificationText"
        multiline
        fullWidth
        label="Justification"
        placeholder="Justification"
        portalTargets="imas"
      />
      {/**
       *
       */}
      <Typography variant="subtitle1">
        {t(
          "Gestion des risques (identification, évaluation, suivi, contrôle et atténuation des principaux types de risques d’un établissement assujetti) :"
        )}
      </Typography>
      <SelectInputCustom
        disabled={disabled}
        source="evalGestionRisques"
        choices={evaluationChoices}
      />
      <TextInputCustom
        disabled={disabled}
        source="evalGestionRisquesText"
        multiline
        fullWidth
        label="Justification"
        placeholder="Justification"
        portalTargets="imas"
      />
      {/**
       *
       */}
      <Typography variant="subtitle1">
        {t(
          "Connaissances et expérience en matière de risques liés au climat et à l’environnement :"
        )}
      </Typography>
      <SelectInputCustom
        disabled={disabled}
        source="evalRisquesEnvironementaux"
        choices={evaluationChoices}
      />
      <TextInputCustom
        disabled={disabled}
        source="evalRisquesEnvironementauxText"
        multiline
        fullWidth
        label="Justification"
        placeholder="Justification"
        portalTargets="imas"
      />
      <Typography variant="subtitle1">{t("Comptabilité et Audit")}</Typography>
      <SelectInputCustom
        disabled={disabled}
        source="evalComptabiliteEtAudit"
        choices={evaluationChoices}
      />
      <TextInputCustom
        disabled={disabled}
        source="evalComptabiliteEtAuditText"
        multiline
        fullWidth
        label="Justification"
        placeholder="Justification"
        portalTargets="imas"
      />
      {/**
       *
       */}
      <Typography variant="subtitle1">
        {t(
          "Evaluation de l’efficacité des dispositifs de gouvernance d’un établissement assujetti, mise en place de mécanismes de surveillance et de contrôle efficaces de celle-ci :"
        )}
      </Typography>
      <SelectInputCustom
        disabled={disabled}
        source="evalEfficaciteDispositifs"
        choices={evaluationChoices}
      />
      <TextInputCustom
        disabled={disabled}
        source="evalEfficaciteDispositifsText"
        multiline
        fullWidth
        label="Justification"
        placeholder="Justification"
        portalTargets="imas"
      />
      {/**
       *
       */}
      <Typography variant="subtitle1">
        {t(
          "Information comptable et financière d’un établissement assujetti, identification des problèmes majeurs révélés par cette information et mise en place des mesures et contrôles appropriés :"
        )}
      </Typography>
      <SelectInputCustom
        disabled={disabled}
        source="evalInfoComptableFinanciere"
        choices={evaluationChoices}
      />
      <TextInputCustom
        disabled={disabled}
        source="evalInfoComptableFinanciereText"
        multiline
        fullWidth
        label="Justification"
        placeholder="Justification"
        portalTargets="imas"
      />
    </Box>
  );
};
