import React from "react";
import { Show } from "react-admin";
import Box from "@material-ui/core/Box";

// import Candidat from "../Candidat";
import Candidat from "../../../candidats/form/CandidatForm";
import Candidature from "../../../candidatures/form/CandidatureForm";

import { ErrorBoundary } from "../../../lib/error-report";
import { LangueProvider } from "./../../transalations";

function CandidatureView(props) {
  const langue = props.langue || props.record.langue;
  console.log("LS -> src/candidatures/print/Candidature/index.js:13 -> langue: ", langue)

  return (
    <ErrorBoundary>
      <Box p="2em" py="3em">
        <LangueProvider langue={langue}>
          <Candidat record={props.record.candidat} basePath="/candidats" resource="candidats" recordId={props.record.candidat.id} isPrint />
    <Candidature record={props.record} basePath="/candidatures" resource="candidatures" recordId={props.record.id} isPrint />
        </LangueProvider>
      </Box>
    </ErrorBoundary>
  );
}

export default function CandidatureShow(props) {
  return (
    <Show {...props}>
      <CandidatureView />
    </Show>
  );
}
