import React, {
  useCallback,
  useState,
  useEffect,
  useMemo,
  useRef,
  memo,
} from "react";
import {
  ArrayInput,
  AutocompleteInput,
  ReferenceInput,
  email,
  required,
  minLength,
  Link,
  useNotify,
  useQuery,
} from "react-admin";
import { useHistory } from "react-router-dom";
import { useFormState } from "react-final-form";

import { authedFetch } from "../../authProvider";

import SelectInputCustom from "../../components/input/SelectInputCustom";
import TextInputCustom from "../../components/input/TextInputCustom";
import TranslatableTextInputCustom from "../../components/input/TranslatableTextInputCustom";
import BooleanInputCustom from "../../components/input/BooleanInputCustom";
import FormIteratorCustom from "../../components/custom/FormIteratorCustom";
import DateInputCustom from "../../components/input/DateInputCustom";
import { Typography, Box, withStyles } from "@material-ui/core";
import { arrayStyles } from "../../style/formStyles";
import CandidatAdressePrincipale from "./CandidatAdressePrincipale";
import InfoBulle from "../../components/InfoBulle";
import TranslationInformation from "../../components/TranslationInformation";

import { NATIONALITIES } from "../../lib/countryNames";
import { useDebounce } from "react-use";
import { usePreferences } from "../../lib/preferences";
import Alert from "@material-ui/lab/Alert";

import Button from "@material-ui/core/Button";
import AutocompleteInputCustom from "../../components/input/AutocompleteInputCutom";
import { useFieldState } from "../../candidatures/form/CandidatureFonctions/hooks";

const Nationalite = ({ name, tabName, disabled }) => {
  return (
    <Box display="flex">
      <Box>
        <AutocompleteInput
          disabled={disabled}
          source={name}
          choices={NATIONALITIES}
          id={`${tabName}#${name}`}
          label="Nationalité"
          validate={[required(), minLength(1)]}
        />
      </Box>
    </Box>
  );
};

const EtablissementAuteur = ({ tabName, disabled, isEditing }) => {
  const { data: entites } = useQuery({
    resource: "entites",
    type: "getList",
    payload: {
      pagination: { page: 1, perPage: 9999 },
      sort: {},
      filter: {},
    },
  });

  const AutocompleteInput = withStyles({
    container: {
      flexGrow: "unset",
    },
    // textCustom: {
    //   minWidth: "37em",
    // },
  })(AutocompleteInputCustom);

  const display = entites && entites.length > 0 ? "block" : "none";

  const [creatorEntityId] = useFieldState("creatorEntityId");
  const [defaultCreatorEntity, setDefaultCreatorEntity] = usePreferences(
    "default-creator-entity",
    null
  );

  useEffect(() => {
    if (!isEditing) {
      setDefaultCreatorEntity(creatorEntityId);
    }
  }, [creatorEntityId, isEditing, setDefaultCreatorEntity]);

  return (
    <Box pt="2em" display={display}>
      <Typography variant="h6" gutterBottom>
        Établissement auteur
      </Typography>
      <Box display="flex" justifyContent="start">
        <ReferenceInput
          disabled={disabled}
          validate={required()}
          label="Ce champ est requis"
          source="creatorEntityId"
          initialValue={defaultCreatorEntity}
          reference="entites"
          sort={{ field: "denominationSociale", order: "ASC" }}
          perPage={9999}
        >
          <AutocompleteInput
            disabled={disabled}
            allowEmpty={false}
            source="creatorEntite"
            id={`${tabName}#creatorEntite`}
            initialValue={defaultCreatorEntity}
            optionText={(entite) =>
              entite
                ? entite.denominationSociale
                : "Vous n'avez pas accès à cet établissement"
            }
          />
        </ReferenceInput>
        <InfoBulle mt="0.5em" width="15em">
          L’établissement auteur est celui qui crée la candidature. Tous les
          utilisateurs associés à cet établissement auront accès à l’intégralité
          des données de ce candidat.
        </InfoBulle>
      </Box>
    </Box>
  );
};

function ExistingCheck() {
  const { values } = useFormState({ subscription: { values: true } });
  const id = values.id;
  const { email, telephone } = values;
  const [show, setShow] = useState("none");
  const candidatIdRef = useRef(null);
  const history = useHistory();
  const notify = useNotify();

  useDebounce(
    async () => {
      const response = await authedFetch(
        process.env.REACT_APP_API_URL + "/candidats/exists",
        {
          method: "POST",
          body: JSON.stringify({ email, telephone, id }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (response.status === 200) {
        const { candidatId } = await response.json();

        if (id !== candidatId) {
          candidatIdRef.current = candidatId;
          setShow("could_update");
        }
      }

      if (response.status === 403) {
        const { candidatId } = await response.json();
        candidatIdRef.current = candidatId;
        setShow("should_ask");
      }

      if (response.status === 404) {
        setShow("none");
      }
    },
    500,
    [email, telephone]
  );

  const askAccess = useCallback(async () => {
    const response = await authedFetch(
      `${process.env.REACT_APP_API_URL}/candidats/demande-access`,
      {
        method: "POST",
        body: JSON.stringify({
          candidatId: candidatIdRef.current,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );

    const severity = response.status === 201 ? "info" : "error";
    const { message } = await response.json();

    notify(message, severity);

    setTimeout(() => {
      history.replace("/candidats/");
    }, 4000);
  }, [history, notify]);

  const view = useMemo(() => {
    switch (show) {
      case "could_update":
        return (
          <Alert severity="warning">
            Un candidat avec cette adresse email ou ce numéro de téléphone
            existe déjà.
            <br />
            <Button
              variant="outlined"
              component={Link}
              to={`/candidats/${candidatIdRef.current}`}
            >
              Accéder au candidat
            </Button>
          </Alert>
        );

      case "should_ask":
        return (
          <Alert severity="warning">
            Un candidat avec cette adresse email ou ce numéro de téléphone
            existe déjà pour un autre établissement.
            <br />
            <Button variant="outlined" component={Link} onClick={askAccess}>
              Demander l’accès au candidat
            </Button>
          </Alert>
        );
      default:
      case "none":
        return null;
    }
  }, [askAccess, show]);

  return view;
}

/**
 * @param {number} number
 * @returns {boolean} true if the number starts with either
 * 06, 07, +336, +337 and 8 digits follow
 */
function isFrenchMobile(number) {
  return number.match(/^((\+33|0)[67])\d{8}$/g);
}

/**
 * @param {number} number
 * @returns {boolean} true if the number starts with an
 * international prefix, but not +33 because that is a french
 * number
 */
function isInternational(number) {
  return number.match(/^\+(?!33)\d+/g);
}

function CandidatGeneral(props) {
  const { tabName, disabled, isEditing } = props;
  const phone = (input) => {
    if (!isFrenchMobile(input) && !isInternational(input)) {
      return [
        "Le numéro doit commencer par 06, 07 ou un préfixe international sauf +33",
      ];
    }
    return false;
  };

  const arrayClasses = arrayStyles();
  const validateEmail = [required(), email()];
  const validateTelephone = [required(), phone];

  const { values: record } = useFormState({ subscription: { values: true } });
  return (
    <Box>
      <TranslationInformation />
      <Typography variant="h6" gutterBottom>
        Identité
      </Typography>
      <Box display="flex">
        <Box>
          <SelectInputCustom
            disabled={disabled}
            label="Civilité"
            source="civilite"
            id={`${tabName}#civilite`}
            validate={required()}
            choices={[
              { id: "monsieur", name: "Monsieur" },
              { id: "madame", name: "Madame" },
              { id: "autre", name: "Autre" },
            ]}
          />
        </Box>
      </Box>

      <Box display="flex">
        <Box>
          <TextInputCustom
            disabled={disabled}
            label="Prénom"
            source="prenom"
            id={`${tabName}#prenom`}
            validate={required()}
          />
        </Box>
        <Box ml="1em">
          <TextInputCustom
            disabled={disabled}
            label="Nom"
            source="nom"
            id={`${tabName}#nom`}
            validate={required()}
            helperText="Utilisé dans les messages en l’absence de nom d’usage"
          />
        </Box>
      </Box>

      <Box display="flex">
        <Box>
          <TextInputCustom
            disabled={disabled}
            label="Autres prénoms"
            source="prenomAutres"
            id={`${tabName}#prenomAutres`}
          />
        </Box>
        <Box ml="1em">
          <TextInputCustom
            disabled={disabled}
            label="Nom d'usage"
            source="nomUsage"
            id={`${tabName}#nomUsage`}
            helperText="Ce nom sera utilisé dans les messages envoyés au candidat."
          />
        </Box>
      </Box>

      <Box>
        <BooleanInputCustom
          disabled={disabled}
          label="A-t-il/elle eu ou utilisé d’autres nom/prénoms par le passé ?"
          helperText="Changements d’état civil tels que patronyme, prénom ou civilité"
          source="aUtiliseAutresPrenoms"
          id={`${tabName}#aUtiliseAutresPrenoms`}
        />
        {record.aUtiliseAutresPrenoms && (
          <Box display="flex">
            <ArrayInput source="anciensNoms" label="Changements d’état civil">
              <FormIteratorCustom
                disabled={disabled}
                component={
                  <FormerNames disabled={disabled} tabName={tabName} />
                }
              />
            </ArrayInput>
          </Box>
        )}
      </Box>

      <Box display="flex" maxWidth="19.5em">
        <ArrayInput
          source="nationalites"
          label="Nationalités"
          className={arrayClasses.arrayLabel}
          defaultValue={[""]}
          validate={required()}
        >
          <FormIteratorCustom
            disabled={disabled}
            component={<Nationalite tabName={tabName} disabled={disabled} />}
            defaultValue={[""]}
            keepTheFirstOne
          />
        </ArrayInput>
        <InfoBulle mt="0.5em" width="15em">
          En cas de double nationalité des justificatifs complémentaires sont
          demandés (CNI ou passeport 2)
        </InfoBulle>
      </Box>

      <Box mt="1em" />

      <EtablissementAuteur
        tabName={tabName}
        disabled={disabled}
        isEditing={isEditing}
      />

      <Box mt="1em" />

      <Typography variant="h6" gutterBottom>
        Date et Lieu de naissance
      </Typography>
      <Box display="flex">
        <Box>
          <DateInputCustom
            disabled={disabled}
            label="Date de naissance"
            source="dateNaissance"
            id={`${tabName}#dateNaissance`}
            validate={required()}
          />
        </Box>
        <Box ml="1em">
          <TranslatableTextInputCustom
            disabled={disabled}
            label="Pays de naissance"
            source="paysNaissance"
            id={`${tabName}#paysNaissance`}
            validate={required()}
          />
        </Box>
      </Box>
      <Box display="flex">
        <Box>
          <TextInputCustom
            disabled={disabled}
            label="Ville"
            source="villeNaissance"
            id={`${tabName}#villeNaissance`}
            validate={required()}
          />
        </Box>
        <Box ml="1em">
          <TextInputCustom
            disabled={disabled}
            label="Code postal"
            source="codePostalNaissance"
            id={`${tabName}#codePostalNaissance`}
          />
        </Box>
      </Box>

      <Typography variant="h6" gutterBottom>
        Carte d'identité / passeport
      </Typography>
      <Box display="flex">
        <Box>
          <TextInputCustom
            disabled={disabled}
            label="Numéro"
            source="numeroId"
            id={`${tabName}#numeroId`}
          />
        </Box>
        <Box ml="1em">
          <TranslatableTextInputCustom
            disabled={disabled}
            label="Pays d'émission"
            source="paysId"
            id={`${tabName}#paysId`}
          />
        </Box>
        <Box ml="1em">
          <DateInputCustom
            disabled={disabled}
            label="Date d'expiration"
            source="dateExpirationId"
            id={`${tabName}#dateExpirationId`}
          />
        </Box>
      </Box>

      <Typography variant="h6" gutterBottom>
        Autres
      </Typography>
      <Box display="flex">
        <Box display="flex">
          <TextInputCustom
            disabled={disabled}
            label="Téléphone"
            source="telephone"
            id={`${tabName}#telephone`}
            validate={validateTelephone}
          />
          <Box mt="1em">
            <InfoBulle>
              Un SMS de validation sera envoyé à ce numéro.
              <br />
              Les numéros français doivent commencer par 06, 07, +336 ou +337
              <br />
              Les numéros étrangers doivent commencer par l’indicatif du pays
            </InfoBulle>
          </Box>
        </Box>
        <Box ml="1em">
          <TextInputCustom
            disabled={disabled}
            label="Adresse électronique"
            source="email"
            id={`${tabName}#email`}
            validate={validateEmail}
          />
        </Box>
        <Box ml="1em">
          <TextInputCustom
            disabled={disabled}
            label="Niveau de connaissance français"
            source="niveauFrancais"
            id={`${tabName}#niveauFrancais`}
          />
        </Box>
      </Box>
      {<ExistingCheck />}

      <Typography variant="h6" gutterBottom>
        Lieu de résidence actuel
      </Typography>
      <Box display="flex">
        <Box>
          <TextInputCustom
            disabled={disabled}
            label="Adresse"
            source="candidatAdresseActuelle.adresse"
            id={`${tabName}#candidatAdresseActuelle.adresse`}
          />
        </Box>
        <Box ml="1em">
          <TextInputCustom
            disabled={disabled}
            label="Code postal et ville"
            source="candidatAdresseActuelle.ville"
            id={`${tabName}#candidatAdresseActuelle.ville`}
          />
        </Box>
        <Box ml="1em">
          <TranslatableTextInputCustom
            disabled={disabled}
            label="Pays"
            source="candidatAdresseActuelle.pays"
            id={`${tabName}#candidatAdresseActuelle.pays`}
          />
        </Box>
      </Box>
      <Box>
        <Box>
          <DateInputCustom
            disabled={disabled}
            label="Date d'arrivée à cette adresse"
            source="candidatAdresseActuelle.dateArrivee"
            id={`${tabName}#candidatAdresseActuelle.dateArrivee`}
          />
        </Box>
      </Box>

      <Box>
        <BooleanInputCustom
          disabled={disabled}
          label="A vécu dans un autre pays au cours de cinq dernières années"
          source="vecuAutresPays"
          id={`${tabName}#vecuAutresPays`}
        />
        {record.vecuAutresPays && (
          <TextInputCustom
            fullWidth
            disabled={disabled}
            id={`${tabName}#vecuAutresPaysText`}
            source="vecuAutresPaysText"
            label="Pays de résidence au cours des cinq dernières années"
            herperText="Tous les pays et les périodes concernées"
          />
        )}
      </Box>

      <CandidatAdressePrincipale tabName={tabName} disabled={disabled} />
    </Box>
  );
}
CandidatGeneral.whyDidYouRender = true;

export default memo(CandidatGeneral);

function FormerNames(props) {
  const { name, disabled, tabName } = props;

  return (
    <Box width="100%">
      <Box display="flex">
        <Box>
          <SelectInputCustom
            disabled={disabled}
            label="Civilité"
            source={`${name}.civilite`}
            id={`${tabName}#civilite`}
            validate={required()}
            choices={[
              { id: "monsieur", name: "Monsieur" },
              { id: "madame", name: "Madame" },
              { id: "autre", name: "Autre" },
            ]}
          />
        </Box>
      </Box>

      <Box display="flex">
        <Box>
          <TextInputCustom
            disabled={disabled}
            label="Prénom"
            source={`${name}.prenom`}
            id={`${tabName}#prenom`}
            validate={required()}
          />
        </Box>
        <Box ml="1em">
          <TextInputCustom
            disabled={disabled}
            label="Nom"
            source={`${name}.nom`}
            id={`${tabName}#nom`}
            validate={required()}
          />
        </Box>
      </Box>

      <Box display="flex">
        <Box>
          <TextInputCustom
            disabled={disabled}
            label="Autres prénoms"
            source={`${name}.prenomsAutres`}
            id={`${tabName}#prenomsAutres`}
          />
        </Box>
        <Box ml="1em">
          <TextInputCustom
            disabled={disabled}
            label="Nom d'usage"
            source={`${name}.nomUsage`}
            id={`${tabName}#nomUsage`}
          />
        </Box>
      </Box>

      <Box display="flex">
        <Box>
          <DateInputCustom
            disabled={disabled}
            label="Date du changement"
            source={`${name}.dateChangement`}
            id={`${tabName}#dateChangement`}
            validate={required()}
          />
        </Box>
      </Box>
    </Box>
  );
}
