import React, { useContext, useEffect, useState } from "react";
import { useFormState } from "react-final-form";

const DEFAULT = ["imas"];

const PortalTargetContext = React.createContext(DEFAULT);

export function PortalTargetProvider({ children }) {
  const [target, setTarget] = useState(DEFAULT);
  const { values: formData } = useFormState({ subscription: { values: true } });
  const portalTarget = formData.portalTarget;

  useEffect(() => {
    setTarget(portalTarget);
  }, [portalTarget]);

  return (
    <PortalTargetContext.Provider value={target}>
      {children}
    </PortalTargetContext.Provider>
  );
}

export function usePortalTarget() {
  const ctx = useContext(PortalTargetContext);
  if (ctx) {
    return ctx;
  }
  return [];
}

export function useOnlyForTargets(targets, disabled) {
  const portalTarget = usePortalTarget();

  if (
    !targets ||
    (Array.isArray(targets) && targets.length === 0) ||
    portalTarget.length === 0
  ) {
    return disabled;
  }

  disabled = !!disabled;
  if (!disabled) {
    const componentTargets = listWrap(targets);

    disabled = !componentTargets.some((target) =>
      portalTarget.includes(target)
    );
  }

  return disabled;
}

function listWrap(c) {
  if (typeof c === "undefined") {
    return [];
  }

  if (!Array.isArray(c)) {
    return [c];
  }

  return c;
}
