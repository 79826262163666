import React, { useCallback } from "react";
import { useNotify, fetchStart, fetchEnd } from "react-admin";
import { useDispatch } from "react-redux";

import { authedFetch } from "../authProvider";

import Button from "@material-ui/core/Button";
import GetAppIcon from "@material-ui/icons/GetApp";
import { reportError } from "../lib/error-report";

const DOCX_MIME =
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document";

/**
 * Open the received file for download
 * @param {Blob} blob
 * @param {string} [name="export"]
 */
function showFile(blob, name = "export") {
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  var newBlob = new Blob([blob], { type: DOCX_MIME });

  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob);
    return;
  }

  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(newBlob);
  var link = document.createElement("a");
  link.href = data;
  link.download = `${name}.docx`;
  link.click();
  setTimeout(function () {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data);
  }, 100);
}

export default function ExportAsWordButton({ url, name, children }) {
  const notify = useNotify();
  const dispatch = useDispatch();

  const handleSubmit = useCallback(async () => {
    dispatch(fetchStart());
    notify("Export en cours…");

    try {
      const response = await authedFetch(url, {
        method: "GET",
        headers: {
          Accept: DOCX_MIME,
        },
      });

      if (response.status < 200 || response.status >= 300) {
        let message = await response.text();

        try {
          message = JSON.parse(message).message;
        } catch (e) {}

        reportError(new Error(message));
        notify(message, "error");
      } else {
        notify("Le document a été exporté avec succès", "success");
        showFile(await response.blob(), name);
      }
    } catch (error) {
      reportError(error);
      notify(error.message, "error");
    }

    dispatch(fetchEnd());
  }, [dispatch, name, notify, url]);

  return (
    <>
      <Button
        color="primary"
        size="small"
        onClick={handleSubmit}
        variant="contained"
        disableElevation
        startIcon={<GetAppIcon fontSize="small" />}
        title="Exporter au format Word"
      >
        {children}
      </Button>
    </>
  );
}
