import { useState, useCallback, useRef, useEffect } from "react";

const PREFERENCES_KEY = "fp_preferences";

/**
 * Read preferences from local storage
 * @returns {Object<string, *>}
 */
function readPreferences() {
  const storedValue = localStorage.getItem(PREFERENCES_KEY);

  if (storedValue === null) {
    return {};
  }

  try {
    return JSON.parse(storedValue);
  } catch (error) {
    console.error(error);

    return {};
  }
}

/**
 * Reads a single value from preferenecs
 * @template T
 * @param {string} key
 * @returns {T|null}
 */
function readValue(key) {
  let prefs = readPreferences();
  if (prefs != null && Object.hasOwnProperty.call(prefs, key)) {
    return prefs[key];
  }

  return null;
}

/**
 * Writes preferences to local storage
 * @param {Object<string, *>} preferences
 */
function writePreferences(preferences) {
  if (
    !preferences ||
    typeof preferences !== "object" ||
    Array.isArray(preferences)
  ) {
    throw new Error("invalid preferences type");
  }

  const valueToStore = JSON.stringify(preferences);

  localStorage.setItem(PREFERENCES_KEY, valueToStore);
}

/**
 * Returns `true` if the value is truthy
 * `false` othewise
 * @param {*} value
 * @returns {boolean}
 */
function isValidValue(value) {
  return !!value;
}

/**
 * Hook to read and write preferences to local storage
 * If there is no value associated with `key` in the preferences,
 * uses `defaultValue`.
 * If works similar to `useState` in the sense that it returns an array
 * of which the first element is the value associated with `key`, and
 * the second element is a setter to change the value.
 * @template T
 * @param {string} key
 * @param {T} defaultValue
 *
 * @returns {[T, (T) => undefined]}
 */
export function usePreferences(key, defaultValue) {
  // is component mounted ?
  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;

    return () => (isMounted.current = false);
  }, []);

  // on mount, read the value from the storage
  const initalValue = readValue(key) || defaultValue;
  const [data, set] = useState(initalValue);

  // setter for setting
  const setter = useCallback(
    /** @type {(newValue: T) => void} */
    (newValue) => {
      if (isMounted.current && isValidValue(newValue)) {
        set(newValue);

        let prefs = readPreferences();
        prefs[key] = newValue;

        writePreferences(prefs);
      }
    },
    [key, set]
  );

  return [data, setter];
}
