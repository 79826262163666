import React, { useRef } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Button, Typography, FormHelperText } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/RemoveCircleOutline";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import { useTranslate, ValidationError } from "react-admin";
import classNames from "classnames";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: 0,
      marginBottom: 0,
      "& > li:last-child": {
        borderBottom: "none",
      },
    },
    line: {
      display: "flex",
      listStyleType: "none",
      borderBottom: `solid 1px ${theme.palette.divider}`,
      [theme.breakpoints.down("xs")]: { display: "block" },
      "&.fade-enter": {
        opacity: 0.01,
        transform: "translateX(100vw)",
      },
      "&.fade-enter-active": {
        opacity: 1,
        transform: "translateX(0)",
        transition: "all 500ms ease-in",
      },
      "&.fade-exit": {
        opacity: 1,
        transform: "translateX(0)",
      },
      "&.fade-exit-active": {
        opacity: 0.01,
        transform: "translateX(100vw)",
        transition: "all 500ms ease-in",
      },
    },
    index: {
      width: "3em",
      paddingTop: "1em",
      [theme.breakpoints.down("sm")]: { display: "none" },
    },
    form: { flex: 2 },
    action: {
      paddingTop: "0.5em",
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
  }),
  { name: "RaSimpleFormIterator" }
);

const FormIteratorCustom = (props) => {
  const {
    component,
    render,
    fields,
    meta: { error, submitFailed },
    source,
    TransitionProps,
    defaultValue,
    keepTheFirstOne,
  } = props;

  let Component = render;
  if (typeof component !== "undefined" && typeof Component !== "undefined") {
    throw new Error(
      "FormIteratorCustom Error: Provide a component or a render prop, not both"
    );
  }

  const translate = useTranslate();
  const classes = useStyles(props);

  const nextId = useRef(
    fields && fields.length
      ? fields.length
      : defaultValue
      ? defaultValue.length
      : 0
  );

  const ids = useRef(
    nextId.current > 0 ? Array.from(Array(nextId.current).keys()) : []
  );

  const removeField = (index) => () => {
    ids.current.splice(index, 1);
    fields.remove(index);
  };

  const addField = () => {
    ids.current.push(nextId.current++);
    fields.push(undefined);
  };

  return fields ? (
    <ul className={classes.root}>
      {submitFailed && typeof error !== "object" && error && (
        <FormHelperText error>
          <ValidationError error={error} />
        </FormHelperText>
      )}
      <TransitionGroup component={null}>
        {fields.map((member, index) => (
          <CSSTransition
            key={ids.current[index]}
            timeout={500}
            classNames="fade"
            {...TransitionProps}
          >
            <li className={classes.line}>
              <Typography variant="body1" className={classes.index}>
                {index + 1}
              </Typography>
              <section className={classes.form}>
                {component &&
                  React.cloneElement(component, {
                    name: member,
                    index: index,
                  })}
                {Component && <Component index={index} name={member} />}
              </section>
              {!(keepTheFirstOne && index === 0) && (
                <span className={classes.action}>
                  <Button
                    className={classNames(
                      "button-remove",
                      `button-remove-${source}-${index}`
                    )}
                    size="small"
                    onClick={removeField(index)}
                  >
                    <CloseIcon className={classes.leftIcon} />
                    {translate("ra.action.remove")}
                  </Button>
                </span>
              )}
            </li>
          </CSSTransition>
        ))}
      </TransitionGroup>
      <li className={classes.line}>
        <span className={classes.action}>
          <Button
            className={classNames("button-add", `button-add-${source}`)}
            size="small"
            onClick={addField}
          >
            <AddIcon className={classes.leftIcon} />
            {translate("ra.action.add")}
          </Button>
        </span>
      </li>
    </ul>
  ) : null;
};

export default FormIteratorCustom;
