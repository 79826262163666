import React from "react";
import { Admin, Resource, Layout } from "react-admin";
import polyglotI18nProvider from "ra-i18n-polyglot";
import frenchMessages from "ra-language-french";
import { Route } from "react-router-dom";
import adminAuthProvider from "./authProvider";
import dataProvider from "./dataProvider";
import Menu from "./Menu";

import CandidatsIcon from "@material-ui/icons/PermContactCalendar";
import CandidaturesIcon from "@material-ui/icons/Ballot";
import EntiteIcon from "@material-ui/icons/AccountBalance";
import UsersIcon from "@material-ui/icons/SupervisorAccount";
import TuneIcon from "@material-ui/icons/Tune";

import candidats from "./candidats";
import candidatures from "./candidatures";
import configs from "./configs";
import domainNames from "./domain_names";
import entites from "./entites";
import planchers from "./planchers";
import users from "./users";

import { reportError } from "./lib/error-report";

import LoginPage from "./users/auth-components/Login";
import ForgotPassword from "./users/auth-components/ForgotPassword";
import CheckPasswordValidity from "./components/CheckPasswordValidity";
import MentionsLegales from "./mention-legales";
import DemandeAccesCandidat from "./DemandeAcces";

const authProvider = adminAuthProvider;
const i18nProvider = polyglotI18nProvider(() => frenchMessages, "fr");

/**
 * Handles uncaught exceptions
 */
window.onerror = async function (_event, _filename, _line, _column, error) {
  await reportError(error);
};

/**
 * Handles unhandled rejections
 */
window.addEventListener("unhandledrejection", (event) => {
  let error = event.reason;

  if (!(error instanceof Error)) {
    error = new Error(JSON.stringify(error));
  }

  reportError(error);
});

const FPLayout = (props) => <Layout {...props} menu={Menu} />;

class App extends React.Component {
  async componentDidCatch(error, errorInfo) {
    if (errorInfo?.componentStack) {
      error.message += errorInfo.componentStack;
    }

    console.error();
    await reportError(error);
  }

  render() {
    return (
      <CheckPasswordValidity>
        <>
          <Admin
            loginPage={LoginPage}
            authProvider={authProvider}
            dataProvider={dataProvider}
            i18nProvider={i18nProvider}
            layout={FPLayout}
            customRoutes={[
              <Route
                exact
                path="/forgot-password"
                component={ForgotPassword}
                noLayout
              />,
              <Route
                exact
                path="/mentions-legales"
                component={MentionsLegales}
              />,
              <Route
                path="/candidats/demande-access/:action/:token"
                component={DemandeAccesCandidat}
              />,
            ]}
          >
            {(role) => [
              <Resource
                name="candidats"
                {...candidats}
                options={{ label: "Candidats" }}
                icon={CandidatsIcon}
              />,
              <Resource
                name="candidatures"
                {...candidatures}
                options={{ label: "Candidatures" }}
                icon={CandidaturesIcon}
              />,
              <Resource name="bugreport" />,
              role === "superadmin" ? (
                <Resource
                  name="entites"
                  options={{ label: "Entités" }}
                  {...entites}
                  icon={EntiteIcon}
                />
              ) : (
                <Resource name="entites" />
              ),
              role === "superadmin" ? (
                <Resource
                  name="users"
                  options={{ label: "Utilisateurs" }}
                  {...users}
                  icon={UsersIcon}
                />
              ) : null,
              role === "superadmin" ? (
                <Resource
                  name="domain-names"
                  options={{ label: "Noms de domaines" }}
                  {...domainNames}
                />
              ) : null,
              role === "superadmin" ? (
                <Resource
                  name="configs"
                  options={{ label: "Pondérations" }}
                  {...configs}
                  icon={TuneIcon}
                />
              ) : null,
              role === "superadmin" ? (
                <Resource
                  name="planchers"
                  {...planchers}
                  options={{ label: "Planchers" }}
                  icon={TuneIcon}
                />
              ) : (
                <Resource name="planchers" />
              ),
            ]}
          </Admin>
        </>
      </CheckPasswordValidity>
    );
  }
}

export default App;
