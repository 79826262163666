import React, { useEffect, useState } from "react";
import {
  email,
  ReferenceArrayInput,
  required,
  AutocompleteArrayInput,
  SimpleForm,
  TextInput,
  SelectInput,
} from "react-admin";

import Edit from "../components/Edit";
import EditAside from "../components/EditAside";
import { transform } from "./UserCreate";

import authProvider from "../authProvider";

import PostEditActions from "../components/custom/PostEditActions";

const validateEmail = [required(), email()];

const validateTelephone = [
  required(),
  (value) => {
    if (value && value.match(/0\d{9}/) && !value.startsWith("08")) {
      return undefined;
    } else {
      return "Doit avoir dix chiffres et commencer par un 0. Les 08 sont exclus";
    }
  },
];

const UserTitle = ({ record }) => (
  <span>
    Utilisateur{" "}
    {record
      ? `#${record.id || ""} - ${record.firstName || ""} ${
          record.lastName || ""
        }`
      : ""}
  </span>
);

export default (props) => {
  const [role, setRole] = useState("user");

  useEffect(() => {
    const r = authProvider.getPermissionsSync();
    setRole(r);
  }, []);

  return (
    <Edit
      title={<UserTitle />}
      actions={<PostEditActions />}
      aside={<EditAside />}
      {...props}
      transform={transform}
    >
      <SimpleForm submitOnEnter={false}>
        <TextInput label="Prénom" source="firstName" validate={required()} />
        <TextInput label="Nom" source="lastName" validate={required()} />
        <TextInput source="email" validate={validateEmail} />
        <TextInput source="telephone" validate={validateTelephone} />
        <TextInput label="Fonction" source="fonction" />
        <ReferenceArrayInput
          label="Entités"
          source="entiteIds"
          reference="entites"
          perPage={120}
        >
          <AutocompleteArrayInput optionText="denominationSociale" />
        </ReferenceArrayInput>

        {role === "superadmin" && (
          <SelectInput
            label="Role"
            helperText="Droits d’accès de l’utilisateur"
            source="role"
            choices={[
              { id: "user", name: "Utilisateur" },
              { id: "superadmin", name: "Administrateur" },
            ]}
          />
        )}

        <SelectInput
          label="État de l’accréditation"
          helperText="Force l’état d’une accréditation"
          source="etat"
          choices={[
            { id: "Initial", name: "Initial" },
            { id: "Pending", name: "En attente de traîtement" },
            {
              id: "WaitingPassword",
              name: "Validé (en attente du mot de passe)",
            },
            { id: "Validated", name: "Validé" },
            { id: "Refused", name: "Refusé" },
          ]}
        />
      </SimpleForm>
    </Edit>
  );
};
