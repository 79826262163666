import React, { cloneElement } from "react";

import { TopToolbar, useListContext, sanitizeListRestProps } from "react-admin";

import CandidatureCreateButton from "./CandidatureCreateButton";

export default function CandidatureListToolbar(props) {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { resource, displayedFilters, filterValues, showFilter } =
    useListContext();
  return (
    <TopToolbar {...sanitizeListRestProps(rest)}>
      {props.filters &&
        cloneElement(props.filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      <CandidatureCreateButton />
    </TopToolbar>
  );
}
