import React from "react";
import moment from "moment";
import { Card, List, ListItem, ListItemText } from "@material-ui/core";

const EditAside = ({ record, resource }) => (
  <Card style={{ width: 180, marginLeft: "1em" }}>
    {record && (
      <List>
        <ListItem>
          <ListItemText primary="Identifiant" secondary={record.id} />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Créé le"
            secondary={moment(record.createdAt).format("DD-MM-YYYY à HH:mm:ss")}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Modifié le"
            secondary={moment(record.updatedAt).format("DD-MM-YYYY à HH:mm:ss")}
          />
        </ListItem>
      </List>
    )}
  </Card>
);

export default EditAside;
