import React, { memo } from "react";
import { Typography, Box } from "@material-ui/core";
import TranslatableTextInputCustom from "../../components/input/TranslatableTextInputCustom";
import ConditionalInput from "../../components/input/ConditionalInput";
import TranslationInformation from "../../components/TranslationInformation";

import InfoBulle from "../../components/InfoBulle";

import Condamnation from "./Infraction/Condamnation";
import Procedure from "./Infraction/Procedure";
import Faillite from "./Infraction/Faillite";

function Subtitle(props) {
  return (
    <Box maxWidth="72em">
      <Typography {...props} />
    </Box>
  );
}

export default memo(({ tabName, disabled }) => {
  return (
    <Box>
      <TranslationInformation />
      <Box display="flex" justifyContent="flex-end">
        <InfoBulle position="right" nonHoverable>
          Pour les formulaires IMAS, dans cette section , « vous » s’entend
          comme « la personne nommée / personne à nommer personnellement » et
          inclut également toute personne morale, tout partenariat et toute
          entité sans personnalité juridique avec lequel ou laquelle la personne
          nommée / personne à nommer en tant que personne physique est ou a été
          associée en tant que membre du conseil d’administration, titulaire
          d’un poste clé, cadre supérieur(e), actionnaire, partenaire,
          associé(e), ou actionnaire qualifié(e). Seuls les actes répréhensibles
          qui auraient été commis pendant la période où la personne nommée /
          personne à nommer était associée à l’entité doivent être déclarés. Les
          réponses apportées aux questions ci-dessous doivent inclure les
          affaires traitées dans des pays n’appartenant pas à l’Union
          européenne.
          <br />
          <br />
          Pour les formulaires ACPR : les faits, procédures, ou décisions de
          sanctions de nature administrative, civile ou pénale qui sont de
          nature à remettre en cause la capacité de la personne désignée à
          remplir son rôle sont à déclarer dans le questionnaire quel que soit
          le temps écoulé depuis l’infraction.
        </InfoBulle>
      </Box>

      <ConditionalInput
        disabled={disabled}
        onNo={(formData) => Condamnation.clear(formData)}
        property="infraction.condamnation"
        label={`Avez-vous déjà fait l’objet d’une condamnation, y compris une
        condamnation en appel, dans le cadre d’une procédure pénale, ou bien
        d’une procédure civile ou administrative (ayant eu une incidence sur
        votre honorabilité ou une incidence significative sur votre situation
          financière)&#x202F;?`}
        tabName={tabName}
      >
        <Condamnation tabName={tabName} />
      </ConditionalInput>

      <ConditionalInput
        disabled={disabled}
        onNo={(formData) => Procedure.clear(formData)}
        property="infraction.procedureEnCours"
        label={`Des mesures disciplinaires ont-elles déjà été prises contre vous ou
        sont-elles en instance vous concernant (y compris la révocation en tant
        que membre de l’organe de direction d’une société, le licenciement pour
        faute professionnelle, l’interdiction de gérer...)&#x202F;?`}
        tabName={tabName}
      >
        <Procedure tabName={tabName} />
      </ConditionalInput>

      <ConditionalInput
        property="infraction.procedureFaillite"
        onNo={(formData) => Faillite.clear(formData)}
        disabled={disabled}
        label={`Avez-vous fait ou faites-vous l’objet d’une procédure de faillite ou
        d’insolvabilité (mesure de redressement ou de liquidation judiciaire) ou
        d’une procédure similaire&#x202F;?`}
      >
        <Faillite tabName={tabName} />
      </ConditionalInput>

      <ConditionalInput
        disabled={disabled}
        property="infraction.restructuration"
        label={`Un établissement financier dans lequel vous occupez ou avez
        occupé une fonction de direction, ou dont vous influencez ou
        avez influencé concrètement la gestion, de quelque manière que
        ce soit, a-t-il déjà fait l’objet d’un renflouement ou été soumis à
        une procédure de restructuration, de rétablissement ou de
        résolution ?`}
      />

      <ConditionalInput
        property="infraction.debiteurInsolvable"
        disabled={disabled}
        label={`À votre connaissance, avez-vous déjà personnellement figuré
        sur une liste de débiteurs peu fiables, faites-vous l’objet d’une
        appréciation négative sur une liste établie par une agence de
        crédit reconnue ou avez-vous fait l’objet d’une mesure
        d’exécution pour une dette inscrite sur une liste de ce type ?`}
      />

      <ConditionalInput
        property="infraction.interdictionGestion"
        disabled={disabled}
        label={`Avez-vous déjà fait l’objet d’un refus, d’un retrait, d’une
        révocation, d’une interdiction de gérer ou d’une annulation
        d’enregistrement, d’autorisation, d’adhésion ou de licence concernant
        l’exercice d’une activité commerciale ou professionnelle&#x202F;? Une
        telle procédure est-elle en cours&#x202F;?`}
      />

      {/* START ACPR-ONLY */}
      <ConditionalInput
        disabled={disabled}
        property="infraction.sanctionSuspension"
        portalTargets="acpr"
        label={`Avez-vous jamais été sanctionné(e) par des autorités publiques ou des
        organisations professionnelles, ou des enquêtes ou procédures
        exécutoires sont-elles en cours ou ont-elles déjà été engagées contre
        vous&#x202F;? En particulier, avez-vous fait l’objet d’une mesure de
        suspension ou d’exclusion d’une organisation professionnelle en France
        ou à l’étranger, ou d’une sanction prononcée par un organe
        central&#x202F;?`}
      />

      <ConditionalInput
        property="infraction.debatAspectsHonorabilite"
        portalTargets="acpr"
        disabled={disabled}
        label={`L’organe de direction de l’entité soumise à la surveillance
          prudentielle a-t-il débattu de points particuliers en ce qui concerne
          des aspects de votre honorabilité&#x202F;?`}
        infoBulle={`Le terme «&#x202F;organe de direction&#x202F;» désigne, en fonction de
          la forme juridique de l’établissement et de la structure choisie, le
          conseil d’administration ou le conseil de surveillance ou de tout
          organe ayant des pouvoirs équivalents (comité des nominations).`}
        infoBullePosition="right"
      />
      {/* END ACPR-ONLY */}

      <ConditionalInput
        property="infraction.blanchiment"
        disabled={disabled}
        portalTargets="imas"
        label={`En dehors des cas mentionnés par ailleurs dans vos réponses,
        avez-vous déjà été impliqué(e) directement ou indirectement
        dans une situation ayant suscité des préoccupations ou des
        soupçons quant à d’éventuelles activités de blanchiment de
        capitaux ou de financement du terrorisme ?`}
      />
      <ConditionalInput
        property="infraction.transparence"
        disabled={disabled}
        portalTargets="imas"
        label={`Avez-vous déjà personnellement omis de déclarer toute
        information que l’autorité de surveillance aurait été en droit
        d’attendre ?`}
      />

      <Subtitle variant="subtitle1">
        ▸ À compléter par l’entité soumise à la surveillance prudentielle : s’il
        a été répondu « Oui » à au moins une des questions ci-dessus, évaluez la
        réputation de la personne nommée / personne à nommer en tenant compte
        des faits pertinents et en expliquant précisément pourquoi vous
        considérez qu’ils ne nuisent pas à l’aptitude de la personne nommée /
        personne à nommer.
      </Subtitle>
      <TranslatableTextInputCustom
        disabled={disabled}
        multiline
        fullWidth
        portalTargets="imas"
        source="infraction.reputationAssessement"
        id={`${tabName}#infraction.reputationAssessement`}
      />
    </Box>
  );
});
