import React, { useCallback } from "react";
import { useNotify } from "react-admin";

import { authedFetch } from "../authProvider";

import Button from "@material-ui/core/Button";
import GetAppIcon from "@material-ui/icons/GetApp";
import { reportError } from "../lib/error-report";

function showFile(blob, mime) {
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  var newBlob = new Blob([blob], { type: mime });

  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob);
    return;
  }

  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(newBlob);
  var link = document.createElement("a");
  link.href = data;
  link.download = "export-entite-fit-and-proper.xlsx";
  link.click();
  setTimeout(function () {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data);
  }, 100);
}

export default function ExportButton({ url, method, accept }) {
  const notify = useNotify();

  const handleSubmit = useCallback(async () => {
    notify("Export en cours…");

    try {
      const response = await authedFetch(url, {
        method,
        headers: {
          Accept: accept,
        },
      });

      if (response.status < 200 || response.status >= 300) {
        let message = await response.text();

        try {
          message = JSON.parse(message).message;
        } catch (e) {}

        reportError(new Error(message));
        notify(message, "error");
      }

      showFile(await response.blob(), accept);
    } catch (error) {
      reportError(error);
      notify(error.message, "error");
    }

    notify("Les entités ont été exportées avec succès", "success");
  }, [accept, method, notify, url]);

  return (
    <>
      <Button color="primary" size="small" onClick={handleSubmit}>
        <GetAppIcon fontSize="small" />
        Exporter
      </Button>
    </>
  );
}
