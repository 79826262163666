import React, { useEffect, useState, useCallback } from "react";
import { Typography, Box } from "@material-ui/core";
import { ArrayInput, FormDataConsumer, required } from "react-admin";
import TextInputCustom from "../../components/input/TextInputCustom";
import DateInputCustom from "../../components/input/DateInputCustom";
import SelectInputCustom from "../../components/input/SelectInputCustom";
import NullableBooleanInputCustom from "../../components/input/NullableBooleanInputCustom";
import FormIteratorCustom from "../../components/custom/FormIteratorCustom";
import NumberInputCustom from "../../components/input/NumberInputCustom";
import { useForm } from "react-final-form";
import { getDayTotal, useGlobalDisabled } from "./helper";
import AlertTooManyDays from "./AlertTooManyDays";
import InfoBulle from "../../components/InfoBulle";
import { useLangue } from "../transalations";

import { Enum } from "../../utils";

const Contenus = Enum({
  BankingAndFinancialMarkets: "banking-And-Financial-Markets",
  LegalRequirementsAndRegulatoryFramework:
    "legal-requirements-and-regulatory-framework",
  StrategicPlanning: "strategic-planning",
  RiskManagement: "risk-management",
  RiskManagementOfClimateRelatedAndEnvironmentalRisks:
    "risk-management-of-climate-related-and-environmental-risks",
  AccountingAndAuditing: "accounting-and-auditing",
  Effectiveness: "effectiveness",
  InterpretingACreditInstitutionsFinancialInformation:
    "interpreting-a-credit-institutions-financial-information",
  Other: "other",
});

const CONTENUS_CHOICES = [
  {
    id: Contenus.BankingAndFinancialMarkets,
    name: "banking and financial markets",
  },
  {
    id: Contenus.LegalRequirementsAndRegulatoryFramework,
    name: "legal requirements and regulatory framework, including in the field of prevention of money laundering and terrorist financing",
  },
  {
    id: Contenus.StrategicPlanning,
    name: "strategic planning, the understanding of a credit institution’s business strategy or business plan and accomplishment thereof",
  },
  {
    id: Contenus.RiskManagement,
    name: "risk management (identifying, assessing, monitoring, controlling and mitigating the main types of risk of a credit institution)",
  },
  {
    id: Contenus.RiskManagementOfClimateRelatedAndEnvironmentalRisks,
    name: "risk management of climate-related and environmental risks",
  },
  { id: Contenus.AccountingAndAuditing, name: "accounting and auditing" },
  {
    id: Contenus.Effectiveness,
    name: "assessing the effectiveness of a credit institution’s arrangements, ensuring effective governance, oversight and controls",
  },
  {
    id: Contenus.InterpretingACreditInstitutionsFinancialInformation,
    name: "interpreting a credit institution’s financial information, identifying key issues based on this information and appropriate controls and measures",
  },
  { id: Contenus.Other, name: "other" },
];

const Formation = ({ tabName, name, disabled, calculateDayTotal }) => {
  const { t } = useLangue();

  return (
    <Box maxWidth="72em">
      <SelectInputCustom
        disabled={disabled}
        id={`${tabName}#contenuFormation`}
        multiline
        label={t("Contenu de la formation")}
        source={`${name}.contenuFormation`}
        validate={required()}
        choices={CONTENUS_CHOICES}
        fullWidth
      />
      <Box display="flex">
        <Box display="flex">
          <TextInputCustom
            disabled={disabled}
            id={`${tabName}#formateur`}
            label={t("Formation dispensée par")}
            source={`${name}.formateur`}
            helperText="Formateur interne ou nom de l’organisme externe"
          />
          <InfoBulle disabled={disabled} mt="0.5em">
            Specify whether the provider is internal or external and state the
            names of external organisations.
          </InfoBulle>
        </Box>
        <Box ml="1em">
          <NumberInputCustom
            disabled={disabled}
            width="10em"
            label={t("Nombre de jours")}
            source={`${name}.nombreJours`}
            id={`${tabName}#${name}.nombreJours`}
            onChange={calculateDayTotal}
            validate={required()}
          />
        </Box>
        <Box ml="1em">
          <DateInputCustom
            disabled={disabled}
            width="10em"
            label={t("Date de début")}
            source={`${name}.dateDebut`}
            id={`${tabName}#${name}.dateDebut`}
            validate={required()}
          />
        </Box>
        <Box ml="1em">
          <DateInputCustom
            disabled={disabled}
            width="10em"
            label={t("Date de fin")}
            source={`${name}.dateFin`}
            id={`${tabName}#${name}.dateFin`}
            validate={[
              required(),
              (value) => {
                try {
                  /* eslint-disable-next-line no-eval */
                  const dateDebut = eval(`allValues?.${name}?.dateDebut`);

                  return (
                    dateDebut > value &&
                    "La date de fin doit être potérieure à la date de début"
                  );
                } catch (e) {}
              },
            ]}
          />
        </Box>
      </Box>
    </Box>
  );
};

function FormationCinqAns({ tabName, name, disabled }) {
  return (
    <Box maxWidth="72em">
      <TextInputCustom
        disabled={disabled}
        id={`${tabName}#contenu`}
        multiline
        label="Contenu de la formation"
        source={`${name}.contenu`}
        validate={required()}
        fullWidth
        portalTargets="imas"
      />
      <Box display="flex">
        <Box>
          <NumberInputCustom
            disabled={disabled}
            width="10em"
            label="Nombres de jours"
            source={`${name}.nombreJours`}
            id={`${tabName}#${name}.nombreJours`}
            validate={required()}
            portalTargets="imas"
          />
        </Box>
        <Box ml="1em">
          <NumberInputCustom
            disabled={disabled}
            width="10em"
            label="année d’achèvement"
            source={`${name}.anneeAchevement`}
            id={`${tabName}#${name}.nombreJours`}
            validate={required()}
            portalTargets="imas"
          />
        </Box>
      </Box>
    </Box>
  );
}

export default ({ entites, ponderations, tabName }) => {
  const form = useForm();
  const [dayTotal, setDayTotal] = useState(0); // 6c2

  const disabled = useGlobalDisabled();
  const formData = form.getState().values;

  const calculateDayTotal = useCallback(async () => {
    const d = await getDayTotal(formData, entites, ponderations);
    setDayTotal(d);
  }, [entites, formData, ponderations]);

  useEffect(() => {
    calculateDayTotal();
  });

  const { t } = useLangue();

  return (
    <Box maxWidth="72em">
      <Typography variant="subtitle1" gutterBottom>
        {t("La personne nommée / personne à nommer a-t-elle suivi une formation pertinente au cours des cinq dernières années ?")}
      </Typography>
      <NullableBooleanInputCustom
        disabled={disabled}
        source="suiviFormationCinqAns"
        portalTargets="imas"
      />
      <Box display="flex">
        <FormDataConsumer subscription={{ values: true }}>
          {({ formData, ...rest }) => {
            if (formData.suiviFormationCinqAns) {
              return (
                <ArrayInput
                  source="formationsCinqAns"
                  label="Formations au cours des cinq dernières années"
                  {...rest}
                >
                  <FormIteratorCustom
                    disabled={disabled}
                    component={
                      <FormationCinqAns disabled={disabled} tabName={tabName} />
                    }
                  />
                </ArrayInput>
              );
            } else {
              formData.formationsCinqAns = [];
            }
          }}
        </FormDataConsumer>
      </Box>

      <Box mt="4em" />
      <Typography variant="subtitle1" gutterBottom>
        Avant d’entrer en fonctions ou pendant la première année suivant son
        entrée en fonctions, la personne nommée (renouvelée) a-t-elle
        suivi/suivra-t-elle une formation particulière&#x202F;?
      </Typography>
      <Box mt="1em" />
      <Box display="flex">
        <Typography variant="subtitle1">
          En application de la politique Fit &amp; Proper Groupe, le temps
          consacré à la formation est ajouté au temps consacré à l&apos;exercice
          du mandat (si la date de fin de la formation est postérieure à la date
          du jour)
        </Typography>
        <InfoBulle position="right">
          Concernant les membres représentant des salariés «&nbsp;code de
          commerce&nbsp;», le temps consacré à leur formation est déterminé par
          le CA/COS, sans pouvoir être inférieur à 40 heures par an (soit 5
          jours par an minimum) au cours du mandat. S’agissant des membres
          représentant les salariés sociétaires, la règle ci-avant peut être
          suivie sur une base volontaire (il n’existe aucune obligation légale
          ou réglementaire).
        </InfoBulle>
      </Box>
      <NullableBooleanInputCustom
        disabled={disabled}
        source="suiviFormation"
        infoBulle="Lorsque le programme détaillé des formations dispensées n’est pas disponible au moment de l’établissement du formulaire, une estimation de la période est demandée"
      />
      <Box display="flex">
        <FormDataConsumer subscription={{ values: true }}>
          {({ formData, ...rest }) => {
            if (formData.suiviFormation) {
              return (
                <>
                  <ArrayInput source="formations" label="Formations" {...rest}>
                    <FormIteratorCustom
                      disabled={disabled}
                      component={
                        <Formation
                          disabled={disabled}
                          calculateDayTotal={calculateDayTotal}
                          tabName={tabName}
                        />
                      }
                    />
                  </ArrayInput>
                  <InfoBulle position="right">
                    À enrichir par le nombre de jours consacrés aux formations à
                    venir (en prenant pour référence que 1 jour = 8 heures). Il
                    s’agit principalement de formations internes, propres à
                    l’entité Groupe et/ou aux fédérations. Lorsque le programme
                    détaillé des formations dispensées n’est pas disponible au
                    moment de l’établissement du formulaire, une estimation de
                    la période est demandée. Il peut s’agir de formations
                    externes également.
                  </InfoBulle>
                </>
              );
            } else {
              formData.formations = [];
            }
          }}
        </FormDataConsumer>
      </Box>
      <AlertTooManyDays dayTotal={dayTotal} />
    </Box>
  );
};
