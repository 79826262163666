import React, { useEffect, useState } from "react";

import {
  RadioButtonGroupInput,
  ReferenceInput,
  SelectInput,
  required,
} from "react-admin";
import { useField } from "react-final-form";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import { getPermissionsSync } from "../../authProvider";

import NullableBooleanInputCustom from "../../components/input/NullableBooleanInputCustom";
import TextInputCustom from "../../components/input/TextInputCustom";
import AutocompleteInput from "../../components/input/AutocompleteInputCutom";
import SelectInputCustom from "../../components/input/SelectInputCustom";

import FileInput from "../../components/File";

import { useGlobalDisabled } from "./helper";
import { useLangue } from "../transalations";

const candidatRenderer = (choice) =>
  choice.nom && choice.prenom && `${choice.nom} ${choice.prenom}`;

const StatusesLabels = {
  Brouillon: "Brouillon",
  SoumisCandidat: "Soumis au candidat",
  ValidationEntite: "En attente de validation/correction par l’entité",
  ValidationBpce: "En attente de validation par BPCE",
  ValidePourSuperviseur: "Valide pour transmission au superviseur",
  Transmis: "Transmis au superviseur",
  Finalise: "Finalisé",
  Abandonne: "Abandonné",
};

const AllowedStatuses = {
  Brouillon: [
    "SoumisCandidat",
    "Abandonne",
    "ValidationEntite",
    "ValidationBpce",
  ],
  SoumisCandidat: ["Brouillon", "ValidationEntite", "Abandonne", "Finalise"],
  ValidationEntite: [
    "ValidationBpce",
    "SoumisCandidat",
    "Abandonne",
    "Finalise",
  ],
  ValidationBpce: [
    "ValidePourSuperviseur",
    "ValidationEntite",
    "Abandonne",
    "Finalise",
  ],
  ValidePourSuperviseur: [
    "Transmis",
    "ValidationBpce",
    "Abandonne",
    "Finalise",
  ],
  Transmis: ["ValidePourSuperviseur", "Finalise"],
  Abandonne: ["Brouillon"],
};

function getValidStatutChoices(currentStatutId) {
  let s = currentStatutId;
  if (!Object.hasOwnProperty.call(AllowedStatuses, currentStatutId)) {
    console.warn(
      `Statut: ${currentStatutId} is invalid, defaulting to Brouillon`
    );
    s = "Brouillon";
  }

  let currentChoice = {
    id: currentStatutId,
    name: StatusesLabels[currentStatutId],
  };

  return [
    currentChoice,
    ...AllowedStatuses[s].map((statut) => ({
      id: statut,
      name: StatusesLabels[statut],
    })),
  ];
}

export default function CandidatureGeneral(props) {
  const { tabName } = props;
  const [role, setRole] = useState("user");

  const { t } = useLangue();

  useEffect(() => {
    const r = getPermissionsSync();
    setRole(r);
  }, []);

  const {
    input: { value: statut },
  } = useField("statut");

  const disabled = useGlobalDisabled();

  return (
    <Box maxWidth="72em">
      <ReferenceInput
        label={t("Candidat")}
        source="candidatId"
        reference="candidats"
        allowEmpty
        sort={{
          field: "nom",
          order: "ASC",
        }}
        perPage={9999}
      >
        <AutocompleteInput
          optionText={candidatRenderer}
          width="36em"
          disabled={disabled}
        />
      </ReferenceInput>
      <SelectInputCustom
        disabled={disabled}
        label={t("Type de candidat")}
        source="typeCandidat"
        id={`${tabName}#typeCandidat`}
        validate={required()}
        choices={[
          { id: "interne", name: t("Interne") },
          { id: "externe", name: t("Externe") },
          { id: "groupe", name: t("Groupe") },
        ]}
      />
      <Typography variant="subtitle1">
        {t(
          "En vertu de la législation nationale sur la limitation du cumul des mandats, l’entité soumise à la surveillance prudentielle dépasse-t-elle le seuil fixé à l’article R. 511-17 ou R. 533-18 du Code monétaire et financier ?"
        )}
      </Typography>
      <NullableBooleanInputCustom
        source="entiteDepasseSeuilFixe"
        disabled={disabled}
        infoBulle="
        En vertu des articles cités, les règles de limitation du cumul des
        mandats prévues dans le Code monétaire et financier s’appliquent
        notamment en cas de dépassement par l’établissement soumis à la
        surveillance prudentielle, du seuil de total de bilan, social ou
        consolidé, de 15 milliards d’euros pendant deux exercices consécutifs.
        "
      />
      <Typography variant="subtitle1">
        {t(
          "Toute autre information jugée utile par la personne nommée (renouvelée) ou l’entité soumise à la surveillance prudentielle, aux fins de l’évaluation, et n'apparaissant pas dans ce formulaire, doit être ajoutée ici :"
        )}
      </Typography>
      <TextInputCustom
        multiline
        disabled={disabled}
        id={`${tabName}#informationsComplementaires`}
        source="informationsComplementaires"
        fullWidth
      />
      <Typography variant="subtitle1">
        {t("S’agit-il d’un renouvellement de poste ?")}
      </Typography>
      <NullableBooleanInputCustom
        source="renouvellementPosition"
        disabled={disabled}
        initialValue={false}
      />
      {(statut === "ValidePourSuperviseur" || statut === "Transmis") && (
        <>
          <Divider />
          <Box mt="1em">
            <FileInput
              disabled={disabled && statut !== "Transmis"}
              source="formulaireSuperviseur"
              label="Formulaire téléchargé depuis le portail du superviseur"
            />
          </Box>
        </>
      )}

      <Divider />
      <Box mt="1em">
        <Typography variant="h6">
          Modalités de transmission du formulaire au superviseur&nbsp;:
        </Typography>
        <RadioButtonGroupInput
          source="portalTarget"
          label=""
          choices={[
            { id: "imas", name: "Portail IMAS" },
            { id: "acpr", name: "Portail Autorisations" },
          ]}
        />
      </Box>

      {role === "superadmin" && (
        <>
          <Divider />
          <Box mt="1em">
            <Typography variant="h6">État de la candidature</Typography>
            <SelectInput
              disabled={disabled && statut !== "Transmis"}
              source="statut"
              choices={getValidStatutChoices(statut)}
            />
          </Box>
        </>
      )}
    </Box>
  );
}
