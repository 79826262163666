import React from "react";
import { Typography, Box } from "@material-ui/core";
import Files from "../../components/File";
import { useGlobalDisabled } from "./helper";

export default () => {
  const disabled = useGlobalDisabled();

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Procès-verbaux relatifs à la nomination/renouvellement, lettre de
        désignation du représentant permanent
        <Files isArray source={"procesVerbal"} disabled={disabled} />
      </Typography>

      <Typography variant="h6" gutterBottom>
        Copie certifiée conforme d’un extrait des délibérations de l’organe
        social lui ayant délégué les pouvoirs nécessaires à l’exercice d’une
        direction effective de l’établissement
        <Files
          isArray={false}
          source={"delegationPouvoir"}
          disabled={disabled}
        />
      </Typography>

      <Typography variant="h6" gutterBottom>
        Extrait du casier judiciaire (pour les personnes ne résidant pas en
        France depuis trois ans au moins)
        <Files isArray={false} source={"extraitCasier"} disabled={disabled} />
      </Typography>

      <Typography variant="h6" gutterBottom>
        Une déclaration de non condamnation (article L. 500-1 du Code monétaire
        et financier)
        <Files
          isArray={false}
          source={"declarationCondamnation"}
          disabled={disabled}
        />
      </Typography>

      <Typography variant="h6" gutterBottom>
        Une copie de la notification de toute évaluation faite par une autorité
        bancaire ou financière autre que l’ACPR ou la BCE (il est uniquement
        demandé la notification de la décision finale lorsque celle-ci a été
        produite)
        <Files
          isArray={false}
          source={"notificationEvaluation"}
          disabled={disabled}
        />
      </Typography>

      <Typography variant="h6" gutterBottom>
        La politique de gestion des conflits d’intérêts
        <Files
          isArray={false}
          source={"politiqueConflitsInterets"}
          disabled={disabled}
        />
      </Typography>

      <Typography variant="h6" gutterBottom>
        Organigramme de l’entité soumise à la surveillance prudentielle
        <Files
          isArray={false}
          source={"organigrammeEntite"}
          disabled={disabled}
        />
      </Typography>

      <Typography variant="h6" gutterBottom>
        Organigramme du groupe d’appartenance
        <Files
          isArray={false}
          source={"organigrammeGroupeAppartenance"}
          disabled={disabled}
        />
      </Typography>

      <Typography variant="h6" gutterBottom>
        L’évaluation de l’adéquation de la personne nommée (renouvelée) réalisée
        par l’entité soumise à la surveillance prudentielle
        <Files
          isArray={false}
          source={"evaluationAdequation"}
          disabled={disabled}
        />
      </Typography>

      <Typography variant="h6" gutterBottom>
        Autres
        <Files disabled={disabled} isArray source={"autre"} />
      </Typography>
    </Box>
  );
};
