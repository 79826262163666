import React, { useState, useCallback } from "react";

import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";

import { useHistory } from "react-router-dom";

import LanguagePicker from "./LanguagePicker";

export default function CandidatureCreateButton(props) {
  const [show, setShow] = useState(false);

  const history = useHistory();

  const onClickCreate = useCallback(() => {
    setShow(true);
  }, []);

  const onClose = useCallback(() => {
    setShow(false);
  }, []);

  const onContinue = useCallback(
    (langue) => {
      history.push(`/candidatures/create?langue=${langue}`);
    },
    [history]
  );

  return (
    <>
      <Button color="primary" size="small" onClick={onClickCreate}>
        <AddIcon size="small" />
        Créer
      </Button>
      <LanguagePicker
        title="Création de Candidature"
        show={show}
        onCancel={onClose}
        onPick={onContinue}
      />
    </>
  );
}
