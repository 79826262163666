import React from "react";
import { Datagrid, EmailField, List, TextField } from "react-admin";

import BulkActionButtons from "../components/BulkActionButtons";
import { ErrorBoundary } from "../lib/error-report";

import CandidatFilter from "./CandidatFilter";

export default (props) => (
  <ErrorBoundary>
    <List
      bulkActionButtons={<BulkActionButtons />}
      {...props}
      sort={{ field: "nom", order: "ASC" }}
      exporter={false}
      filters={<CandidatFilter />}
    >
      <Datagrid rowClick="edit">
        <TextField label="Nom" source="nom" />
        <TextField label="Prénom" source="prenom" />
        <EmailField source="email" />
        <TextField label="Téléphone" source="telephone" />
        <TextField label="Fonction" source="nomFonction" />
      </Datagrid>
    </List>
  </ErrorBoundary>
);
