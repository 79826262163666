import React, { useCallback, useMemo, useState } from "react";
import { useRefresh, useNotify } from "react-admin";

import { useDropzone } from "react-dropzone";

import { authedFetch } from "../authProvider";
import { reportError } from "../lib/error-report";

import Button from "@material-ui/core/Button";
import PublishIcon from "@material-ui/icons/Publish";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  cursor: "pointer",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

export default function ImportButton({
  title,
  message,
  name,
  url,
  method,
  accept,
}) {
  const [show, setShow] = useState(false);
  const [files, setFiles] = useState([]);
  const refresh = useRefresh();
  const notify = useNotify();

  const {
    // acceptedFiles,
    // fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept,
    onDrop: (files) => setFiles(files),
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragAccept, isDragActive, isDragReject]
  );

  const handleSubmit = useCallback(async () => {
    const formData = new FormData();

    files.forEach((file) => formData.append(name, file, file.name));

    notify("Import en cours…");

    try {
      const response = await authedFetch(url, {
        method,
        headers: {
          Accept: "application/json",
        },
        body: formData,
      });

      if (response.status < 200 || response.status >= 300) {
        let message = await response.text();

        try {
          message = JSON.parse(message).message;
        } catch (e) {}

        reportError(new Error(message));
        notify(message, "error");
      }
    } catch (error) {
      reportError(error);
      notify(error.message, "error");
    }

    setShow(false);
    refresh();
    notify("Les entités ont été importées avec succès", "success");
    setFiles([]);
  }, [files, method, name, notify, refresh, url]);

  const handleCancel = useCallback(() => {
    setShow(false);
    setFiles([]);
  }, []);

  return (
    <>
      <Button color="primary" size="small" onClick={() => setShow(true)}>
        <PublishIcon fontSize="small" />
        Importer
      </Button>
      <Dialog open={show} onClose={() => setShow(false)}>
        <DialogTitle>{title}</DialogTitle>

        <DialogContent>
          <DialogContentText>
            {files.length === 0 && (
              <>
                <div {...getRootProps({ className: "dropzone", style })}>
                  <input {...getInputProps()} />
                  <p>
                    Glissez déposez ici le fichier à importer, ou cliquez pour
                    le sélectionner.
                  </p>
                </div>
                <div>{message}</div>
              </>
            )}
            <ul>
              {files.map((file) => (
                <li key={file.name}>{file.name}</li>
              ))}
            </ul>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button color="primary" onClick={handleCancel}>
            Annuler
          </Button>
          <Button
            color="primary"
            onClick={handleSubmit}
            disabled={files.length === 0}
          >
            Importer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
