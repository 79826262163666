import React, { useState, useCallback } from "react";
import { useRedirect, fetchEnd, fetchStart, useNotify } from "react-admin";

import { makeStyles } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import { useDispatch } from "react-redux";
import { httpClient } from "../authProvider";

import LanguagePicker from "./LanguagePicker";

const { REACT_APP_API_URL } = process.env;

const useStyles = makeStyles({
  root: {
    position: "relative",
  },
  button: {
    appearance: "none",
    display: "flex",
    alignItems: "center",
    backgroundColor: "white",
    border: "1px solid #ccc",
    borderRadius: "4px",
    padding: "0.3em 0.5em",
    paddingRight: "0em",
    cursor: "pointer",
    "&:disabled": {
      opacity: 0.5,
      cursor: "not-allowed",
    },
  },
  menu: {
    position: "absolute",
    right: 0,
    width: "280px",

    backgroundColor: "white",
    border: "1px solid #ccc",
    borderRadius: "4px",
    zIndex: 19999,
  },
  item: {
    padding: "0.5em 1em",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#eee",
    },
  },
});

export default function DuplicationMenuButton({ record }) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const classes = useStyles();

  return (
    <div className={classes.root} onClick={(e) => e.preventDefault()}>
      <button
        disabled={loading}
        className={classes.button}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setOpen(true && !loading);
        }}
        onBlur={() => setTimeout(() => setOpen(false), 250)}
      >
        Duplication
        <ArrowDropDownIcon />
      </button>
      <div
        className={classes.menu}
        style={{ display: open ? "block" : "none" }}
      >
        <DuplicateOption
          className={classes.item}
          setLoading={setLoading}
          record={record}
        />
        <RenewOption
          className={classes.item}
          setLoading={setLoading}
          record={record}
        />
        <ReuseOption
          className={classes.item}
          setLoading={setLoading}
          record={record}
        />
      </div>
    </div>
  );
}

function DuplicateOption({ className, record, setLoading }) {
  const dispatch = useDispatch();
  const notify = useNotify();
  const redirect = useRedirect();

  const handleChange = useCallback(
    async (e) => {
      e.preventDefault();
      e.stopPropagation();

      setLoading(true);
      dispatch(fetchStart());
      try {
        const res = await httpClient(
          `${REACT_APP_API_URL}/candidatures/duplicate/${record.id}`,
          {}
        );
        notify("Candidature dupliquée");
        redirect(`/candidatures/${res.json.candidature.id}`);
      } catch (error) {
        notify("Erreur: impossible de dupliquer la candidature", "warning");
      }

      setLoading(false);
      dispatch(fetchEnd());
    },
    [dispatch, notify, record.id, redirect, setLoading]
  );

  return (
    <div className={className} onClick={handleChange}>
      Dupliquer pour un autre candidat
    </div>
  );
}

function RenewOption({ className, record, setLoading }) {
  const dispatch = useDispatch();
  const notify = useNotify();
  const redirect = useRedirect();

  const handleChange = useCallback(
    async (e) => {
      e.preventDefault();
      e.stopPropagation();

      setLoading(true);
      dispatch(fetchStart());
      try {
        const res = await httpClient(
          `${REACT_APP_API_URL}/candidatures/renew/${record.id}`,
          {}
        );
        notify("Candidature renouvelée");
        redirect(`/candidatures/${res.json.candidature.id}`);
      } catch (error) {
        notify("Erreur: impossible de renouveler la candidature", "warning");
      }

      setLoading(false);
      dispatch(fetchEnd());
    },
    [dispatch, notify, record.id, redirect, setLoading]
  );
  return (
    <div
      className={className}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        handleChange(e);
      }}
    >
      Renouveler
    </div>
  );
}

function ReuseOption({ className, record, setLoading }) {
  const dispatch = useDispatch();
  const notify = useNotify();
  const redirect = useRedirect();

  const [showModal, setShowModal] = useState();

  const handleChange = useCallback(
    async (langue) => {
      setLoading(true);
      dispatch(fetchStart());
      try {
        const res = await httpClient(
          `${REACT_APP_API_URL}/candidatures/reuse/${record.id}?langue=${langue}`,
          {}
        );
        notify("Candidature créée");
        redirect(`/candidatures/${res.json.candidature.id}`);
      } catch (error) {
        notify("Erreur: impossible de réutiliser la candidature", "warning");
      }

      setLoading(false);
      dispatch(fetchEnd());
      setShowModal(false);
    },
    [dispatch, notify, record.id, redirect, setLoading]
  );

  const onCancel = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();

    setShowModal(false);
  }, []);

  return (
    <>
      <div
        className={className}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setShowModal(true);
        }}
      >
        Utiliser pour une autre candidature
      </div>
      <LanguagePicker
        title="Réutiliser la candidature"
        show={showModal}
        onCancel={onCancel}
        onPick={handleChange}
      />
    </>
  );
}
