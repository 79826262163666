import React from "react";
import { FormDataConsumer } from "react-admin";

import { Typography, Box } from "@material-ui/core";
import TranslatableTextInputCustom from "../../../components/input/TranslatableTextInputCustom";
import DateInputCustom from "../../../components/input/DateInputCustom";
import SelectInputCustom from "../../../components/input/SelectInputCustom";
import NullableBooleanInputCustom from "../../../components/input/NullableBooleanInputCustom";

import { Enum } from "../../../utils";

const CondamnationType = Enum({
  Criminal: "criminal",
  Administrative: "administrative",
  Civil: "civil",
  Other: "other",
});

const CondamnationEtape = Enum({
  Pending: "pending",
  Concluded: "concluded",
  UnderAppeal: "en appel",
});

const CondamnationRelation = Enum({
  RelatedToMe: "related to me",
  RelatedToAssociatedEntity: "related to associated entity",
  NotRelated: "not related",
});

const TYPE_CHOICES = [
  { id: CondamnationType.Criminal, name: "Pénale" },
  { id: CondamnationType.Administrative, name: "Administrative" },
  { id: CondamnationType.Civil, name: "Civile" },
  { id: CondamnationType.Other, name: "Autre" },
];

const ETAPE_CHOICES = [
  { id: CondamnationEtape.Pending, name: "En cours" },
  { id: CondamnationEtape.Concluded, name: "Menée à terme" },
  { id: CondamnationEtape.UnderAppeal, name: "En appel" },
];

const RELATION_CHOICES = [
  { id: CondamnationRelation.RelatedToMe, name: "Oui, la procédure concerne la personne nommée" },
  {
    id: CondamnationRelation.RelatedToAssociatedEntity,
    name: "Oui, la procédre concerne une entité à laquelle la peronne nommée est associée",
  },
  { id: CondamnationRelation.NotRelated, name: "Non" },
];

function Subtitle(props) {
  return (
    <Box maxWidth="72em">
      <Typography {...props} />
    </Box>
  );
}

export default function Condamnation({ disabled, tabName, ...rest }) {
  return (
    <Box ml="2em" mr="2em">
      <Box display="flex">
        <SelectInputCustom
          label="Type de procédure"
          disabled={disabled}
          source="infraction.condamnationType"
          id={`${tabName}#infraction.condamnationType`}
          choices={TYPE_CHOICES}
          {...rest}
        />
        <Box ml="0.5em">
          <SelectInputCustom
            label="Stade où en est la procédure"
            disabled={disabled}
            source="infraction.condamnationEtape"
            id={`${tabName}#infraction.condamnationEtape`}
            choices={ETAPE_CHOICES}
            {...rest}
          />
        </Box>
      </Box>
      <FormDataConsumer subscription={{ values: true }}>
        {({ formData }) => {
          if (formData.infraction.condamnationType === CondamnationType.Other) {
            return (
              <>
                <TranslatableTextInputCustom
                  label="Veuillez préciser le type de procédure"
                  disabled={disabled}
                  multiline
                  source="infraction.condamnationTypeAutre"
                  id={`${tabName}#infraction.condamnationTypeAutre`}
                  fullWidth
                  {...rest}
                />
              </>
            );
          } else {
            formData.condamnationTypeAutre = null;
          }
        }}
      </FormDataConsumer>
      <Subtitle variant="subtitle1">Veuillez décrire brièvement les charges et la nature des actes répréhensibles présumés (par exemple : faute intentionnelle ou
négligence, etc.) et indiquer à quel stade en est la procédure.</Subtitle>
      <TranslatableTextInputCustom
        disabled={disabled}
        multiline
        source="infraction.condamnationText"
        id={`${tabName}#infraction.condamnationText`}
        fullWidth
        {...rest}
      />
      <Subtitle variant="subtitle1">Veuillez indiquer l’autorité chargée de la procédure et, si possible, la référence du dossier.</Subtitle>
      <TranslatableTextInputCustom
        disabled={disabled}
        multiline
        source="infraction.condamnationAutorite"
        id={`${tabName}#infraction.condamnationAutorite`}
        fullWidth
        {...rest}
      />
      <Subtitle variant="subtitle1">
La procédure vous concerne-t-elle personnellement ou
concerne-t-elle une entité à laquelle vous êtes ou avez été
associé(e) ?</Subtitle>
      <SelectInputCustom
        disabled={disabled}
        source="infraction.condamnationRelation"
        id={`${tabName}#infraction.condamnationRelation`}
        choices={RELATION_CHOICES}
        {...rest}
      />
      <FormDataConsumer subscription={{ values: true }}>
        {({ formData, ...rest }) => {
          switch (formData.infraction?.condamnationRelation) {
            case CondamnationRelation.RelatedToMe:
              clearRelationEntite(formData);
              return (
                <RelationDirecte
                  disabled={disabled}
                  tabName={tabName}
                  {...rest}
                />
              );

            case CondamnationRelation.RelatedToAssociatedEntity:
              clearRelationDirecte(formData);
              return (
                <RelationEntite
                  disabled={disabled}
                  tabName={tabName}
                  {...rest}
                />
              );

            case CondamnationRelation.NotRelated:
            default:
              clearRelationDirecte(formData);
              clearRelationEntite(formData);
              return null;
          }
        }}
      </FormDataConsumer>
      <Subtitle variant="subtitle1">Date et/ou période de déroulement des actes répréhensibles
présumés</Subtitle>
      <DateInputCustom
        disabled={disabled}
        source="infraction.condamnationDateMefait"
        id={`${tabName}#infraction.condamnationDateMefait`}
        {...rest}
      />
      <Subtitle variant="subtitle1">Date de la décision, du jugement ou des conclusions (le cas
échéant)</Subtitle>
      <DateInputCustom
        disabled={disabled}
        source="infraction.condamnationDateJugement"
        id={`${tabName}#infraction.condamnationDateJugement`}
        helperText="if applicable"
        {...rest}
      />
      <Subtitle variant="subtitle1">Résumé du raisonnement sous-tendant la décision, le jugement ou les conclusions</Subtitle>
      <TranslatableTextInputCustom
        disabled={disabled}
        multiline
        source="infraction.condamnationResumeJugement"
        id={`${tabName}#infraction.condamnationResumeJugement`}
        fullWidth
        {...rest}
      />
      <Subtitle variant="subtitle1">Sanction ou peine reçue (ou probable en cas de condamnation si les procédures sont en cours)</Subtitle>
      <TranslatableTextInputCustom
        disabled={disabled}
        multiline
        source="infraction.condamnationSanction"
        id={`${tabName}#infraction.condamnationSanction`}
        fullWidth
        {...rest}
      />
      <Subtitle variant="subtitle1">Précisez l’éventail des sanctions (à savoir la sanction la moins sévère et la section la plus sévère) qui pourraient ou auraient pu être
infligées.</Subtitle>
      <TranslatableTextInputCustom
        disabled={disabled}
        multiline
        source="infraction.condamnationFourchetteSanction"
        id={`${tabName}#infraction.condamnationFourchetteSanction`}
        fullWidth
        {...rest}
      />
      <Subtitle variant="subtitle1">La procédure a-t-elle abouti à un règlement (y compris au moyen
d’un règlement extrajudiciaire) ?</Subtitle>
      <NullableBooleanInputCustom
        disabled={disabled}
        source="infraction.condamnationAmiable"
        id={`${tabName}#infraction.condamnationAmiable`}
        {...rest}
      />
      <FormDataConsumer subscriptions={{ values: true }}>
        {({ formData, ...rest }) => {
          if (formData.infraction?.condamnationAmiable) {
            return (
              <>
                <Subtitle variant="subtitle1">Veuillez fournir des détails (notamment les parties au règlement, la date, les montants convenus lors du règlement et
toute autre information pertinente).</Subtitle>
                <TranslatableTextInputCustom
                  disabled={disabled}
                  multiline
                  source="infraction.condamnationAmiableText"
                  id={`${tabName}#infraction.condamnationAmiableText`}
                  fullWidth
                  {...rest}
                />
              </>
            );
          } else {
            formData.condamnationAmiableText = null;
          }

          return null;
        }}
      </FormDataConsumer>
      <Subtitle variant="subtitle1">Veuillez fournir des détails sur votre conduite ultérieure, notamment les enseignements tirés et les mesures correctrices prises.</Subtitle>
      <TranslatableTextInputCustom
        disabled={disabled}
        multiline
        source="infraction.condamnationConduiteSubsequente"
        id={`${tabName}#infraction.condamnationConduiteSubsequente`}
        fullWidth
        {...rest}
      />
      <Subtitle variant="subtitle1">Décrivez tout autre facteur atténuant ou aggravant à l’aide du Guide relatif à l’évaluation de l’honorabilité, des connaissances, des
compétences et de l’expérience</Subtitle>
      <TranslatableTextInputCustom
        disabled={disabled}
        multiline
        source="infraction.condamnationCirconstanceAttenuanteAggravante"
        id={`${tabName}#infraction.condamnationCirconstanceAttenuanteAggravante`}
        fullWidth
        {...rest}
      />

      <Subtitle variant="subtitle1">Les procédures mentionnées ci-dessous ont-elles donné lieu à
des récupérations de rémunération ?</Subtitle>
      <NullableBooleanInputCustom
        disabled={disabled}
        source="infraction.condamnationClauseRecuperation"
        id={`${tabName}#infraction.condamnationClauseRecuperation`}
        {...rest}
      />
      <FormDataConsumer subscriptions={{ values: true }}>
        {({ formData, ...rest }) => {
          if (formData.infraction?.condamnationClauseRecuperation) {
            return (
              <>
                <Subtitle variant="subtitle1">Veuillez fournir des détails</Subtitle>
                <TranslatableTextInputCustom
                  disabled={disabled}
                  multiline
                  source="infraction.condamnationClauseRecuperationText"
                  id={`${tabName}#infraction.condamnationClauseRecuperationText`}
                  fullWidth
                  {...rest}
                />
              </>
            );
          } else {
            formData.infraction.condamnationClauseRecuperationText = null;
          }

          return null;
        }}
      </FormDataConsumer>
    </Box>
  );
}

function clearRelationDirecte(formData) {
  formData.infraction.condamnationRelationDirecteCirconstances = null;
  formData.infraction.condamnationRelationDirecteEvitement = null;
  formData.infraction.condamnationRelationDirecteLesson = null;
}

function clearRelationEntite(formData) {
  formData.infraction.condamnationRelationEntiteNom = null;
  formData.infraction.condamnationRelationEntiteRole = null;
  formData.infraction.condamnationRelationEntiteManagementRole = null;
  formData.infraction.condamnationRelationEntiteEvitement = null;
  formData.infraction.condamnationRelationEntiteLesson = null;
}

function RelationDirecte({ disabled, tabName, ...rest }) {
  return (
    <Box>
      <Subtitle variant="subtitle1">Précisez dans quelles circonstances et pour quelles raisons vous êtes concerné(e) directement</Subtitle>
      <TranslatableTextInputCustom
        disabled={disabled}
        multiline
        source="infraction.condamnationRelationDirecteCirconstances"
        id={`${tabName}#infraction.condamnationRelationDirecteCirconstances`}
        fullWidth
        {...rest}
      />
      <Subtitle variant="subtitle1">Décrivez les mesures que vous avez prises pour prévenir les actes répréhensibles et/ou éviter qu’ils soient commis.</Subtitle>
      <TranslatableTextInputCustom
        disabled={disabled}
        multiline
        source="infraction.condamnationRelationDirecteEvitement"
        id={`${tabName}#infraction.condamnationRelationDirecteEvitement`}
        fullWidth
        {...rest}
      />
      <Subtitle variant="subtitle1">Qu’auriez-vous pu faire de plus pour éviter que ces actes répréhensibles présumés soient commis et avez-vous tiré des
enseignements de ce qu’il s’est passé ?</Subtitle>
      <TranslatableTextInputCustom
        disabled={disabled}
        multiline
        source="infraction.condamnationRelationDirecteLesson"
        id={`${tabName}#infraction.condamnationRelationDirecteLesson`}
        fullWidth
        {...rest}
      />
    </Box>
  );
}

function RelationEntite({ disabled, tabName, ...rest }) {
  return (
    <Box>
      <Subtitle variant="subtitle1">Précisez le nom de l’entité concernée</Subtitle>
      <TranslatableTextInputCustom
        disabled={disabled}
        multiline
        source="infraction.condamnationRelationEntiteNom"
        id={`${tabName}#infraction.condamnationRelationEntiteNom`}
        fullWidth
        {...rest}
      />
      <Subtitle variant="subtitle1">Précisez votre rôle au sein de l’entité et indiquez si vous êtes ou étiez à la tête d’un service ou d’une ligne métier concerné(e) par
la procédure (y compris par les sanctions ou les mesures imposées).</Subtitle>
      <TranslatableTextInputCustom
        disabled={disabled}
        multiline
        source="infraction.condamnationRelationEntiteRole"
        id={`${tabName}#infraction.condamnationRelationEntiteRole`}
        fullWidth
        {...rest}
      />
      <Subtitle variant="subtitle1">Étiez-vous membre de l’organe de direction, titulaire d’une fonction-clé ou cadre supérieur(e) lorsque les actes répréhensibles
présumés ont été commis ?</Subtitle>
      <TranslatableTextInputCustom
        disabled={disabled}
        multiline
        source="infraction.condamnationRelationEntiteManagementRole"
        id={`${tabName}#infraction.condamnationRelationEntiteManagementRole`}
        fullWidth
        {...rest}
      />
      <Subtitle variant="subtitle1">
Dans le cadre de vos fonctions au sein de l’entité, qu’avez-vous fait pour prévenir les actes répréhensibles présumés et/ou pour
éviter qu’ils soient commis ?
      </Subtitle>
      <TranslatableTextInputCustom
        disabled={disabled}
        multiline
        source="infraction.condamnationRelationEntiteEvitement"
        id={`${tabName}#infraction.condamnationRelationEntiteEvitement`}
        fullWidth
        {...rest}
      />
      <Subtitle variant="subtitle1">Qu’auriez-vous pu faire de plus pour éviter que ces actes répréhensibles présumés soient commis et avez-vous tiré des
enseignements de ce qu’il s’est passé ?</Subtitle>
      <TranslatableTextInputCustom
        disabled={disabled}
        multiline
        source="infraction.condamnationRelationEntiteLesson"
        id={`${tabName}#infraction.condamnationRelationEntiteLesson`}
        fullWidth
        {...rest}
      />
    </Box>
  );
}

Condamnation.clear = function (formData) {
  formData.infraction.condamnationType = null;
  formData.infraction.condamnationTypeAutre = null;
  formData.infraction.condamnationText = null;
  formData.infraction.condamnationAutorite = null;
  formData.infraction.condamnationRelation = null;
  clearRelationDirecte(formData);
  clearRelationEntite(formData);
  formData.infraction.condamnationDateMefait = null;
  formData.infraction.condamantionDateJugement = null;
  formData.infraction.condamnationResumeJugement = null;
  formData.infraction.condamnationSanction = null;
  formData.infraction.condamnationFourchetteSanction = null;
  formData.infraction.condamnationAmiable = null;
  formData.infraction.condamnationAmiableText = null;
  formData.infraction.condamnationConduiteSubsequente = null;
  formData.infraction.condamnationCirconstanceAttenuanteAggravante = null;
  formData.infraction.condamnationClauseRecuperation = null;
  formData.infraction.condamnationClauseRecuperationText = null;
};
