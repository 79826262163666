import React, { useMemo } from "react";
import { TextInput } from "react-admin";

import { makeStyles } from "@material-ui/core/styles";

import { noSpecialCharacters } from "../../lib/validation";
import { useOnlyForTargets } from "../../candidatures/PortalTarget";

const useStyles = makeStyles({
  root: (props) => {
    return {
      minWidth: props.width || "18em",
    };
  },
});

function listWrap(o) {
  if (!o) return [];

  if (!Array.isArray(o)) return [o];

  return o;
}

function useValidate(props) {
  let validate = useMemo(
    () =>
      [...listWrap(props.validate), noSpecialCharacters()].filter((f) => !!f),
    [props.validate]
  );

  return validate;
}

const TextInputCustom = React.forwardRef(
  ({ portalTargets, tabName, ...props }, ref) => {
    const classes = useStyles(props);
    const validate = useValidate(props);

    const disabled = useOnlyForTargets(portalTargets, !!props.disabled);

    return (
      <TextInput
        ref={ref}
        {...props}
        disabled={disabled}
        validate={validate}
        label={props.label || " "}
        // minRows={1}
        // This minHeight is required to avoid the inputs to collapse to a 0px
        // height. 1em is a line height, 0.26em is (likely to be) the space
        // in between lines
        inputProps={{ style: { minHeight: "1.26em" } }}
        className={`${classes.root} ${classes.textCustom} ${props.className}`}
      />
    );
  }
);

export default TextInputCustom;
